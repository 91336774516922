import {styled, Typography} from "@mui/material";
import React from "react";

export default function GameEventTimeLineNoEvent(props) {
  return (
    <NoEventLabel align={"center"} className={"bbsa-game-events-timeline-item-no-event"}>aucun évenement</NoEventLabel>
  )
}


const NoEventLabel = styled(Typography)(({theme}) => ({
  fontSize: "0.7rem",
  [theme.breakpoints.down("sm")]: {}
}))
