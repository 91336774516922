import {Navigate, useLocation} from "react-router-dom";
import useSession from "../../contexts/session/useSession";
import {useEffect} from "react";

function AdminRoute(props) {
  const {currentSession, setPrevUrl} = useSession();
  const {children} = props;
  const location = useLocation()

  useEffect(() => {
    if (!currentSession.connected) {
      setPrevUrl(location.pathname)
    } else {
      if (!currentSession.isAdmin) {
        setPrevUrl("/")
      }
    }
  }, [])


  if (currentSession.connected && currentSession.isAdmin) {
    return (
      <>
        {children}
      </>
    );
  } else {
    return (
      <Navigate to="/login"/>
    )
  }

}

export default AdminRoute;
