import _AuthService from "./AuthService"
import _CharacteristicsImprovementTypeService from "./CaracteristicImprovementTypeService"
import _DaysService from "./DaysService";
import _EventsService from "./EventsService";
import _GamesService from "./GamesService";
import _NewsService from "./NewsService";
import _PhaseService from "./PhaseService";
import _PoolsService from "./PoolsService";
import _PositionalsService from "./PositionalsService";
import _RacesService from "./RaceService";
import _SeasonsService from "./SeasonService";
import _SkillsService from "./SkillsService";
import _TeamsService from "./TeamsService";
import _UpgradeCostsService from "./UpgradeCostsService";
import _UsersService from "./UserService";

export const AuthService = _AuthService
export const CharacteristicsImprovementTypeService = _CharacteristicsImprovementTypeService
export const DaysService = _DaysService;
export const EventsService = _EventsService;
export const GamesService = _GamesService
export const NewsService = _NewsService;
export const PhasesService = _PhaseService;
export const PhaseService = _PhaseService;
export const PoolsService = _PoolsService;
export const PositionalsService = _PositionalsService;
export const RacesService = _RacesService;
export const SeasonsService = _SeasonsService;
export const SeasonService = _SeasonsService;
export const SkillsService = _SkillsService;
export const TeamsService = _TeamsService;
export const UpgradeCostsService = _UpgradeCostsService;
export const UsersService = _UsersService;




