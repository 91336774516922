import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App3 from './App3';
import reportWebVitals from './reportWebVitals';
import './fonts/Gutcruncher.otf'
import './fonts/Gutcruncher Italic.otf'
import './fonts/Nuffle.otf'
import './fonts/Nuffle Dice.otf'
import './fonts/Nuffle Italic.otf'
import {SnackbarProvider} from 'notistack';
import {Helmet} from "react-helmet";
import App2 from "./App2";


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Helmet>
      <title>Bloodbowl Season Admin</title>
    </Helmet>
    <SnackbarProvider maxSnack={3} anchorOrigin={{vertical: 'top', horizontal: 'left'}}>
      <App2/>
    </SnackbarProvider>
  </React.StrictMode>
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
