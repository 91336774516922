import {Dialog, DialogContent, DialogContentText, DialogTitle, IconButton, Stack, Typography} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import EventCategoryButton from "../EventSelection/EventCategoryButton";
import React from "react";
import AddPlayerForm from "./AddPlayerForm";
import {number} from "prop-types";


function AddPlayerDialog(props){
  const {
    open = false,
    onClose = () => {},
    onAddSuccess=()=>{},
    onAddError=()=>{},
  } = props;

  const handleAddSuccess = (positional, name, number) => {
    onClose();
    onAddSuccess(positional, name, number)
  }

  const handleAddError = (error) => {
    onAddError(error)
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      scroll={'paper'}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <DialogTitle id="scroll-dialog-title" align={"right"}>
        <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={1}
        >
          <Typography align={"center"}>Acheter un joueur</Typography>
          <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={onClose}
          >
            <CloseIcon/>
          </IconButton>
        </Stack>
      </DialogTitle>
          <AddPlayerForm onAddSuccess={handleAddSuccess} onAddError={handleAddError}/>
    </Dialog>
  )
}

export default AddPlayerDialog;
