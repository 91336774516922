import {Box, styled, Typography} from "@mui/material";
import BBSARotatingBlason from "../BbsaRotatingBlason/BbsaRotatingBlason";
import {Fragment} from "react";


export default function BBSALoadingBox(props){
    return (
        <BBSALoadingBoxRoot className={"bbse-loading-root"}>
            <BBSARotatingBlason size={"small"}/>
        </BBSALoadingBoxRoot>
    )
}

const BBSALoadingBoxRoot = styled(Box)(({props, theme}) => ({
    justifyContent: "center",
    display: "flex",
    alignItems: "center",
    minHeight : 80,
    overflow : 'hidden',
    height:'100%'

}))
