import {styled} from "@mui/material";
import blason from "../../../images/EB-blason.png";


export default function BBSARotatingBlason(props){
    const {size} = props;
    return(
        <Blason src={blason} size={size}/>
    )
}

const Blason = styled('img')(({theme, size}) => ({
    width: (size?(size==='small'?48:size==='large'?128:64):64),
    height: (size?(size==='small'?48:size==='large'?128:64):64),
    animation: "loading-rotation 0.8s infinite ease-in-out"
}))
