import React, {Fragment, useEffect, useState} from "react";
import {
    Box,
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    Grid,
    InputLabel,
    OutlinedInput,
    Stack,
    Typography
} from "@mui/material";
import GamesService from "../../../services/GamesService";
import PhaseService from "../../../services/PhaseService";
import useSession from "../../contexts/session/useSession";
import GameSheet from "../GameSheet/GameSheet";

import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import {GameContext} from "../../contexts/game/GameContext";
import useGame from "../../contexts/game/useGame";


function GameScoreAddContext(props) {
  const {currentSession} = useSession();
  const {game, isInTeam, team1, team2} = useGame();

  const {
    gameId, onGameEnded = (r) => {
    }, onGameEndError = () => {
    }
  } = props;
  const [bonusPointsLoaded, setBonusPointsLoaded] = useState(false);
  const [validatingGame, setValidatingGame] = useState(false);
  const [bonusPoints, setBonusPoints] = useState(null);
  const [scoreTeam1, setScoreTeam1] = useState(0);
  const [scoreTeam2, setScoreTeam2] = useState(0);
  const [fanTeam1, setFanTeam1] = useState(0);
  const [fanTeam2, setFanTeam2] = useState(0);
  const [treasuryTeam1, setTreasuryTeam1] = useState(0);
  const [treasuryTeam2, setTreasuryTeam2] = useState(0);
  const [conditionsTeam1, setConditionsTeam1] = useState([]);
  const [conditionsTeam2, setConditionsTeam2] = useState([]);
  const [conditionsTeamsLoaded, setConditionsTeamsLoaded] = useState(false);
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const [events, setEvents] = useState(null);


  useEffect(() => {
    if (events === null) {
      GamesService.getEventsToValidate(gameId, currentSession.accessToken).then((game) => {
        setEvents(game.events);
      })
    } else {
      if (game !== null && team1 !== null && team2 !== null) {
        let s1 = events.filter(e => e.type.code === "Touchdown" && isInTeam(team1, e.perpetratorPlayerId)).length;
        let s2 = events.filter(e => e.type.code === "Touchdown" && isInTeam(team2, e.perpetratorPlayerId)).length;
        setScoreTeam1(s1);
        setScoreTeam2(s2);
      }
    }
  }, [events, team1, team2])

  useEffect(() => {
    if (events !== null && game !== null && team1 !== null && team2 !== null && !conditionsTeamsLoaded && bonusPointsLoaded) {
      let bp3Eli = bonusPoints.filter(bp => bp.code === '3ELI');
      let s1 = events.filter(e => e.type.eventCategoryId === 2 && isInTeam(team1, e.perpetratorPlayerId)).length;
      let s2 = events.filter(e => e.type.eventCategoryId === 2 && isInTeam(team2, e.perpetratorPlayerId)).length;
      if (bp3Eli.length > 0) {
        let eli = bp3Eli[0];
        if (s1 >= eli.intValue) {
          setConditionsTeam1(["" + eli.id]);
        }
        if (s2 >= eli.intValue) {
          setConditionsTeam2(["" + eli.id]);
        }
      }
      setConditionsTeamsLoaded(true)
    }
  }, [events, team1, team2, conditionsTeamsLoaded, bonusPointsLoaded])

  useEffect(() => {
    if (game && !bonusPointsLoaded) {
      PhaseService.getPhasesBonusPoints(game.pool.phase.id).then((bonusPoints) => {
        setBonusPoints(bonusPoints);
        setBonusPointsLoaded(true)
      })
    }
  }, [bonusPointsLoaded, game])


  if (!game && !conditionsTeamsLoaded) {
    return (
      <Box>
        <div>Chargement</div>
      </Box>
    )
  }

  const handleNext = () => {
    let newSkipped = skipped;
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleEndGameClick = () => {
    setValidatingGame(true);
    let data = {
      id: gameId,
      gameEndParticipations: [
        {
          id: game.teamParticipations.filter(tp => tp.position === 1)[0].id,
          score: scoreTeam1,
          lpConditionsIds: conditionsTeam1,
          addedFans : fanTeam1,
          addedTresury : treasuryTeam1
        },
        {
          id: game.teamParticipations.filter(tp => tp.position === 2)[0].id,
          score: scoreTeam2,
          lpConditionsIds: conditionsTeam2,
          addedFans : fanTeam2,
          addedTresury : treasuryTeam2
        }
      ]
    }

    GamesService.setEndGame(gameId, data, currentSession.accessToken).then((response) => {
      setValidatingGame(false);
      onGameEnded(response);
    }).catch((error) => {
      onGameEndError(error);
      setValidatingGame(false);
    })
  }

  const handleChangeScore1 = (e) => {
    setScoreTeam1(e.target.value)
  }

  const handleChangeScore2 = (e) => {
    setScoreTeam2(e.target.value)
  }

  const handleChangeFan1 = (e) => {
    setFanTeam1(e.target.value)
  }

  const handleChangeFan2 = (e) => {
    setFanTeam2(e.target.value)
  }

  const handleChangeTreasury1 = (e) => {
    setTreasuryTeam1(e.target.value)
  }

  const handleChangeTreasury2 = (e) => {
    setTreasuryTeam2(e.target.value)
  }

  const handleChangeConditionTeam1 = (event) => {
    if (event.target.checked) {
      if (conditionsTeam1.filter(i => i === event.target.value).length === 0) {
        let t = [];
        conditionsTeam1.map(i => t.push(i));
        t.push(event.target.value);
        setConditionsTeam1(t);
      }
    } else {
      if (conditionsTeam1.filter(i => i === event.target.value).length > 0) {
        let t = [];
        conditionsTeam1.filter(i => i !== event.target.value).map((i => t.push(i)));
        setConditionsTeam1(t);
      }
    }
  }

  const handleChangeConditionTeam2 = (event) => {
    if (event.target.checked) {
      if (conditionsTeam2.filter(i => i === event.target.value).length === 0) {
        let t = [];
        conditionsTeam2.map(i => t.push(i));
        t.push(event.target.value);
        setConditionsTeam2(t);
      }
    } else {
      if (conditionsTeam2.filter(i => i === event.target.value).length > 0) {
        let t = [];
        conditionsTeam2.filter(i => i !== event.target.value).map((i => t.push(i)));
        setConditionsTeam2(t);
      }
    }
  }

  if (team1 === null || team2 === null || game === null) {
    return (<Fragment/>)
  }

  return (
    <Box>
      <Stack spacing={2}>
        <Box textAlign={"center"}>
          <h2>Ajout du score</h2>
        </Box>
        <Box sx={{width: '100%'}}>
          <Stepper activeStep={activeStep} sx={{paddingBottom: "30px", borderBottom: "black 1px solid"}}>
            <Step>
              <StepLabel>Statut equipe {team1.name}</StepLabel>
            </Step>
            <Step>
              <StepLabel>Statut equipe {team2.name}</StepLabel>
            </Step>
            <Step>
              <StepLabel>Score</StepLabel>
            </Step>
          </Stepper>
          <Box sx={{display: (activeStep === 0 ? 'block' : 'none')}}>
            {game && <GameSheet events={events} game={game} team={team1}/>}
          </Box>
          <Box sx={{display: (activeStep === 1 ? 'block' : 'none')}}>
            {game && <GameSheet events={events} game={game} team={team2}/>}
          </Box>
          <Box textAlign={"center"} sx={{display: (activeStep === 2 ? 'block' : 'none')}}>
            <Grid container>
              <Grid item xs={5}>
                <Typography variant={"subtitle1"}>
                  {game.teamParticipations.filter(tp => tp.position === 1)[0].team.name}
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography textAlign={"center"}>-</Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography variant={"subtitle1"}>
                  {game.teamParticipations.filter(tp => tp.position === 2)[0].team.name}
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <FormControl>
                  <InputLabel htmlFor="journee-label">Score</InputLabel>
                  <OutlinedInput
                    id="Score1"
                    value={scoreTeam1}
                    label="Score"
                    type="number"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={handleChangeScore1}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={2}>
              </Grid>
              <Grid item xs={5}>
                <FormControl>
                  <InputLabel htmlFor="journee-label">Score</InputLabel>
                  <OutlinedInput
                    id="Score2"
                    value={scoreTeam2}
                    label="Score"
                    type="number"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={handleChangeScore2}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={5} sx={{paddingTop:1}}>
                <FormControl>
                  <InputLabel   htmlFor="journee-label">Fan en +</InputLabel>
                  <OutlinedInput
                    id="Fan1"
                    value={fanTeam1}
                    label="Fan en +"
                    type="number"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={handleChangeFan1}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={2}>
              </Grid>
              <Grid item xs={5} sx={{paddingTop:1}}>
                <FormControl>
                  <InputLabel htmlFor="journee-label">Fan en +</InputLabel>
                  <OutlinedInput
                    id="Fan2"
                    value={fanTeam2}
                    label="Fan en +"
                    type="number"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={handleChangeFan2}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={5} sx={{paddingTop:1}}>
                <FormControl>
                  <InputLabel htmlFor="journee-label">Trésorerie(K) en +</InputLabel>
                  <OutlinedInput
                    id="Tresorerie1"
                    value={treasuryTeam1}
                    label="Trésorerie(K) en +"
                    type="number"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={handleChangeTreasury1}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={2}>
              </Grid>
              <Grid item xs={5} sx={{paddingTop:1}}>
                <FormControl>
                  <InputLabel htmlFor="journee-label">Trésorerie(K) en +</InputLabel>
                  <OutlinedInput
                    id="Tresorerie2"
                    value={treasuryTeam2}
                    label="Trésorerie(K) en +"
                    type="number"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={handleChangeTreasury2}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={5}>
                <FormGroup>
                  {bonusPointsLoaded && bonusPoints.map((bonusPoint) => {
                    return (
                      <FormControlLabel
                        key={"bonusPoint-T1-" + bonusPoint.id}
                        className={'bbsa-gamescore-bonus-point-label'}
                        sx={{paddingBottom: 0, paddingTop: 0}}
                        control={
                          <Checkbox onChange={handleChangeConditionTeam1}
                                    value={bonusPoint.id}
                                    checked={(conditionsTeam1.filter(i => i === "" + bonusPoint.id).length > 0)}
                                    value={bonusPoint.id}/>}
                        label={bonusPoint.name}
                      />
                    )
                  })}
                </FormGroup>
              </Grid>
              <Grid item xs={2}>
              </Grid>
              <Grid item xs={5}>
                <FormGroup>
                  {bonusPointsLoaded && bonusPoints.map((bonusPoint) => {
                    return (
                      <FormControlLabel
                        key={"bonusPoint-T2-" + bonusPoint.id}
                        value={bonusPoint.id}
                        className={'bbsa-gamescore-bonus-point-label'}
                        sx={{paddingBottom: 0, paddingTop: 0}}
                        control={
                          <Checkbox onChange={handleChangeConditionTeam2}
                                    value={bonusPoint.id}
                                    checked={(conditionsTeam2.filter(i => i === "" + bonusPoint.id).length > 0)}
                                    value={bonusPoint.id}/>}
                        label={bonusPoint.name}
                      />
                    )
                  })}
                </FormGroup>
              </Grid>
            </Grid>
          </Box>
          <React.Fragment>
            <Box sx={{display: 'flex', flexDirection: 'row', pt: 2, borderTop: "black 1px solid"}}>
              <Button
                color="inherit"
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{mr: 1}}
              >
                Précédent
              </Button>
              <Box sx={{flex: '1 1 auto'}}/>
              {(activeStep === 2) ? (
                <Button onClick={handleEndGameClick} disabled={validatingGame}>
                  Valider
                </Button>
              ) : (
                <Button onClick={handleNext}>
                  Suivant
                </Button>
              )}
            </Box>
          </React.Fragment>
        </Box>
      </Stack>
    </Box>
  )
}

function GameScoreAdd(props) {

  return (
    <GameContext id={props.gameId}>
      <GameScoreAddContext gameId={props.gameId} onGameEnded={props.onGameEnded}
                           onGameEndError={props.onGameEndError}/>
    </GameContext>
  )
}

export default GameScoreAdd;
