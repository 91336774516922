import React, {Fragment, useEffect, useState} from "react";
import {useSnackbar} from "notistack";
import SeasonService from "../../services/SeasonService";
import DaysService from "../../services/DaysService";
import {Box, Container, Hidden, IconButton, Modal} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DayTable from "../components/DayTable/DayTable";
import GameAdd from "../components/GameAdd/GameAdd";
import GameStart from "../components/GameStart/GameStart";
import GameDelayed from "../components/GameDelayed/GameDelayed";
import GameScoreAdd from "../components/GameScoreAdd/GameScoreAdd";
import GameForfeit from "../components/GameForfeit/GameForfeit";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '70%',
  maxWidth: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function ManageGamePage(props) {

  const {enqueueSnackbar} = useSnackbar();

  const [days, setDays] = useState([]);
  const [daysLoaded, setDaysLoaded] = useState(false);
  const [openAddGame, setOpenAddGame] = useState(false);
  const [openStartGame, setOpenStartGame] = useState(false);
  const [openDelayGame, setOpenDelayGame] = useState(false);
  const [openScoreGame, setOpenScoreGame] = useState(false);
  const [openForfeitGame, setOpenForfeitGame] = useState(false);
  const [addGameDayId, setAddGameDayId] = useState(0);
  const [startGameId, setStartGameId] = useState(0);
  const [forfeitGameId, setForfeitGameId] = useState(0);
  const [delayGameId, setDelayGameId] = useState(0);
  const [scoreGameId, setScoreGameId] = useState(0);

  const handleAddGame = (dayId) => {
    setOpenAddGame(true);
    setAddGameDayId(dayId);
  }

  const handleCloseAddGame = () => {
    setOpenAddGame(false)
    setAddGameDayId(0);

  }

  const handleGameAdded = (response) => {
    enqueueSnackbar("Match ajouté !", {variant: "success"});
    handleCloseAddGame()
    setDaysLoaded(false);
  }

  const handleStartGame = (gameId) => {
    setOpenStartGame(true);
    setStartGameId(gameId);
  }

  const handleCloseStartGame = () => {
    setOpenStartGame(false)
    setStartGameId(0);
  }

  const handleForfeitGame = (gameId) => {
    setOpenForfeitGame(true);
    setForfeitGameId(gameId);
  }

  const handleCloseForfeitGame = () => {
    setOpenForfeitGame(false)
    setForfeitGameId(0);
  }


  const handleGameStarted = (response) => {
    enqueueSnackbar("Match Démarré !", {variant: "success"});
    handleCloseStartGame()
    setDaysLoaded(false);
  }

  const handleDelayGame = (gameId) => {
    setOpenDelayGame(true);
    setDelayGameId(gameId);
  }

  const handleCloseDelayGame = () => {
    setOpenDelayGame(false)
    setDelayGameId(0);
  }

  const handleGameDelayed = (response) => {
    enqueueSnackbar("Match reporté !", {variant: "success"});
    handleCloseDelayGame()
    setDaysLoaded(false);
  }

  const handleScoreGame = (gameId) => {
    setOpenScoreGame(true);
    setScoreGameId(gameId);
  }

  const handleCloseScoreGame = () => {
    setOpenScoreGame(false)
    setScoreGameId(0);
  }

  const handleGameEnded = (response) => {
    enqueueSnackbar("Match terminé !", {variant: "success"});
    handleCloseScoreGame()
    setDaysLoaded(false);
  }

  const handleGameEndError = (error) => {
    enqueueSnackbar("Erreur lors de la validation du match !" + error.message, {variant: "error"});
  }

  const handleGameForfeit = (response, teamName) => {
    enqueueSnackbar("L'équipe " + teamName + " a déclaré forfait !", {variant: "success"});
    handleCloseForfeitGame()
    setDaysLoaded(false);
  }


  useEffect(() => {
    if (!daysLoaded) {
      SeasonService.getCurrent().then((season) => {
        DaysService.getAllDaysOfSeason(season.id).then((days) => {
          setDaysLoaded(true);
          setDays(days)
        })
      })
    }
  })

  return (
    <Fragment>
      <h1>Gestion des matchs</h1>
      <Hidden mdDown={"sm"}>
        <Container maxWidth={"lg"} className={'content-bb'}>
          {days.map((day) => {
            return (
              <DayTable
                key={"DayTable-" + day.id}
                day={day}
                onAddGameClick={handleAddGame}
                onStartGameClick={handleStartGame}
                onDelayGameClick={handleDelayGame}
                onForfeitGameClick={handleForfeitGame}
                onAddScoreClick={handleScoreGame}
                adminOptions
                showStatus
              />
            )
          })}
        </Container>
      </Hidden>
      <Hidden mdUp={"sm"}>
        {days.map((day) => {
          return (
            <DayTable
              key={"DayTable-" + day.id}
              day={day}
              onAddGameClick={handleAddGame}
              onStartGameClick={handleStartGame}
              onDelayGameClick={handleDelayGame}
              onForfeitGameClick={handleForfeitGame}
              onAddScoreClick={handleScoreGame}
              adminOptions
              showStatus
            />
          )
        })}
      </Hidden>

      <Modal
        open={openAddGame}
        onClose={handleCloseAddGame}
      >
        <Box sx={style}>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{mr: 2, position: "absolute", top: 15, right: 0}}
            onClick={handleCloseAddGame}
          >
            <CloseIcon/>
          </IconButton>

          <GameAdd dayId={addGameDayId} onGameAdded={handleGameAdded}/>
        </Box>
      </Modal>
      <Modal
        open={openStartGame}
        onClose={handleCloseStartGame}
      >
        <Box sx={style}>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{mr: 2, position: "absolute", top: 15, right: 0}}
            onClick={handleCloseStartGame}
          >
            <CloseIcon/>
          </IconButton>

          <GameStart gameId={startGameId} onGameStarted={handleGameStarted}/>
        </Box>
      </Modal>
      <Modal
        open={openDelayGame}
        onClose={handleCloseDelayGame}
      >
        <Box sx={style}>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{mr: 2, position: "absolute", top: 15, right: 0}}
            onClick={handleCloseDelayGame}
          >
            <CloseIcon/>
          </IconButton>

          <GameDelayed gameId={delayGameId} onGameDelayed={handleGameDelayed}/>
        </Box>
      </Modal>
      <Modal
        open={openScoreGame}
        onClose={handleCloseScoreGame}
      >
        <Box sx={style}>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{mr: 2, position: "absolute", top: 15, right: 0}}
            onClick={handleCloseScoreGame}
          >
            <CloseIcon/>
          </IconButton>

          <GameScoreAdd gameId={scoreGameId} onGameEnded={handleGameEnded} onGameEndError={handleGameEndError}/>
        </Box>
      </Modal>
      <Modal
        open={openForfeitGame}
        onClose={handleCloseForfeitGame}
      >
        <Box sx={style}>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{mr: 2, position: "absolute", top: 15, right: 0}}
            onClick={handleCloseForfeitGame}
          >
            <CloseIcon/>
          </IconButton>

          <GameForfeit gameId={forfeitGameId} onGameForfeit={handleGameForfeit}/>
        </Box>
      </Modal>
    </Fragment>
  )
}
