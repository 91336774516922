import CommonService from "./CommonService";

class RaceService extends CommonService {
  async getPositionals(raceId) {
    return this.request({
      url: process.env.REACT_APP_API_URL + '/races/' + raceId + '/positionals',
      method: 'GET'
    })
  }

  async getStaffRace(raceId) {
    return this.request({
      url: process.env.REACT_APP_API_URL + '/races/' + raceId + '/raceStaffs',
      method: 'GET'
    })
  }

  async getLinemen(raceId) {
    return this.request({
      url: process.env.REACT_APP_API_URL + '/races/' + raceId + '/linemen',
      method: 'GET'
    })
  }
}

export default new RaceService();
