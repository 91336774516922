import CommonService from "./CommonService";

class UpgradeCostsService extends CommonService{
    async list() {
        return this.request({
            url: process.env.REACT_APP_API_URL + "/upgradeCosts",
            method: 'GET'
        })
    }
}

export default new UpgradeCostsService();


