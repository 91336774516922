import React, {useEffect, useState} from "react";
import {Alert, Box, Button, Container, Grid, Paper, Stack, Typography} from "@mui/material";
import {useSnackbar} from "notistack";
import * as PropTypes from "prop-types";
import {Link} from "react-router-dom";
import BBSAInputField from "../components/BbsaInputField/BBSAInputField";
import AuthService from "../../services/AuthService";


function RegisterPage(props) {
  const {enqueueSnackbar} = useSnackbar();

  const [nom, setNom] = useState("");
  const handleChangeNom = (value) => {
    setNom(value)
  }
  const [prenom, setPrenom] = useState("");
  const handleChangePrenom = (value) => {
    setPrenom(value)
  }
  const [username, setUsername] = useState("");
  const handleChangeUsername = (value) => {
    setUsername(value);
    validateField("user", value)
  }
  const [email, setEmail] = useState("");
  const handleChangeEmail = (value) => {
    setEmail(value);
    validateField("email", value)
  }
  const [password, setPassword] = useState("");
  const handleChangePassword = (value) => {
    setPassword(value);
    validateField("password", value)
  }
  const [saving, setSaving] = useState(false);
  const [registrationSucces, setRegistrationSuccess] = useState(false);
  const [formError, setFormError] = useState({
    email: '',
    password: '',
    user: '',
  })
  const [formErrorStatus, setFormErrorStatus] = useState({
    emailValid: false,
    passwordValid: false,
    userValid: false
  })
  const [formValid, setFormValid] = useState(false);

  const validateField = (fieldName, value) => {
    switch (fieldName) {
      case 'email':
        formErrorStatus.emailValid = value.match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/i);
        formError.email = formErrorStatus.emailValid ? '' : "Le format de l'adresse email est invalide";
        break;
      case 'password':
        formErrorStatus.passwordValid = value.match(/^\S*(?=\S{8,})(?=\S*\d)(?=\S*[A-Z])(?=\S*[a-z])(?=\S*[!@#$%^&*+ù.µ|éèç"'(§{à})°\-_¨\[\]´`£,;\/:=~<>\\²³? ])\S*$/g);
        formError.password = formErrorStatus.passwordValid ? '' : 'Le mot de passe doit contenir : minimum 8 caracteres, minimum 1 lettre en majuscule, minimum 1 lettre en minuscule, minimum 1 chiffre, minimum 1 caractère spécial';
        break;
      case 'user':
        formErrorStatus.userValid = value.match(/^[A-Z0-9a-z\.]{5,}$/i);
        // formErrorStatus.userValid = (value.length >= 5);
        formError.user = formErrorStatus.userValid ? '' : 'L\'utilisateur doit contenir au minimum 5 caracteres, pas de caractères spéciaux, pas d\'espace';
        break;
      default:
        break;
    }
    setFormError({...formError})
    setFormErrorStatus({...formErrorStatus})
  }


  useEffect(() => {
    setFormValid(formErrorStatus.userValid && formErrorStatus.emailValid && formErrorStatus.passwordValid)
  }, [formErrorStatus])


  const handleOnClickRegister = () => {
    const user = {
      name: username
      , firstname: prenom
      , lastname: nom
      , email: email
      , password: password
    }
    setSaving(true);
    AuthService.register(user).then(() => {
      setSaving(false);
      enqueueSnackbar("Insciption réussie", {variant: "success"});
      setRegistrationSuccess(true);
    }).catch((e) => {
      setSaving(false);
      enqueueSnackbar(e.message, {variant: "error"});
    })
  }

  if (registrationSucces) {
    return (
      <Container maxWidth={"sm"} className={'content-bb'}>
        <FormSuccess/>
      </Container>
    )
  }

  return (
    <React.Fragment>
      <Container maxWidth={"sm"} className={'content-bb'}>
        <FormErrors formErrors={formError} formValid={formValid}/>
        <Paper>
          <Box padding={2}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <BBSAInputField
                  defaultValue={nom}
                  label={"Nom"}
                  onChange={handleChangeNom}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <BBSAInputField
                  defaultValue={prenom}
                  label={"Prenom"}
                  onChange={handleChangePrenom}
                />
              </Grid>
              <Grid item xs={12}>
                <BBSAInputField
                  defaultValue={username}
                  label={"Nom d'utilisateur"}
                  onChange={handleChangeUsername}
                />
              </Grid>
              <Grid item xs={12}>
                <BBSAInputField
                  defaultValue={email}
                  label={"E-Mail"}
                  onChange={handleChangeEmail}
                />
              </Grid>
              <Grid item xs={12}>
                <BBSAInputField
                  type={"password"}
                  defaultValue={password}
                  label={"Mot de passe"}
                  onChange={handleChangePassword}
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  disabled={saving || !formValid}
                  fullWidth
                  size={"small"}
                  variant={'contained'}
                  onClick={handleOnClickRegister}
                >S'enregistrer</Button>
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </Container>
    </React.Fragment>
  )

}

function FormErrors(props) {
  const {formErrors, formValid = false} = props;
  if (formValid) {
    return (<></>);
  }
  return (
    <Stack
      justifyContent={"start"}
    >
      {Object.keys(formErrors).map((fieldName, i) => {
        if (formErrors[fieldName].length > 0) {
          return (
            <Alert severity="error">
              <Typography align={"left"} key={i}>{formErrors[fieldName]}</Typography>
            </Alert>
          )
        } else {
          return '';
        }
      })}
    </Stack>
  )
}

FormErrors.propTypes = {
  formErrors: PropTypes.shape({
    password: PropTypes.string,
    emailValid: PropTypes.bool,
    passwordValid: PropTypes.bool,
    userValid: PropTypes.bool,
    user: PropTypes.string,
    email: PropTypes.string
  })
};

function FormSuccess(props) {
  return (
    <Alert severity={"success"}>
      <Typography>Votre compte a bien été enregistré. Veuillez contacter l'administrateur de votre ligue pour activer
        votre compte.</Typography>
      <br/>
      <Link to={"/"}>Retour a la page d'acceuil</Link>
    </Alert>
  )
}


export default RegisterPage;

