import {Button} from "@mui/material";
import {Fragment, useState} from "react";
import ValidationBeforeAction from "./ValidationBeforeAction";

export default function ActionButton(props) {
  const {
    disabled = false,
    actionLabel = "Action",
    elementToBuy = "",
    onDoAction = () => {},
    onClose = () => {},
    variant = 'contained',
    color = 'secondary',
    fullWidth = false,
    title = "Validation"
  } = props;

  const [openConfirmation, setOpenConfirmation] = useState(false)

  const handleRequestBuy = () => {
    setOpenConfirmation(true);
  }

  const handleCancel = () => {
    onClose();
    setOpenConfirmation(false);
  }

  const handleApprouve = () => {
    onClose();
    onDoAction();
    setOpenConfirmation(false);
  }

  return (
    <Fragment>
      <Button fullWidth={fullWidth} variant={variant} color={color} disabled={disabled}
              onClick={handleRequestBuy}>{actionLabel}</Button>
      <ValidationBeforeAction
        open={openConfirmation}
        onCancel={handleCancel}
        onApprouve={handleApprouve}
        actionLabel={actionLabel}
        elementToBuy={elementToBuy}
        title={title}
      />
    </Fragment>
  )
}
