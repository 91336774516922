import CommonService from "./CommonService";

class SkillsService extends CommonService{
    async getByType(id, random) {
        return this.request({
            url: process.env.REACT_APP_API_URL + "/skill_types/"+id + "/skills?onlyRandom=" + random,
            method: 'GET'
        })
    }
}

export default new SkillsService();


