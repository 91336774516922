import React, {useEffect, useRef, useState} from "react";
import {Box, Stack, styled, Typography} from "@mui/material";
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import PauseCircleIcon from '@mui/icons-material/PauseCircle';
import useGameboard from "../../contexts/gameboard/useGameboard";
import ticktackSoundFile from "./sounds/clock-ticking.mp3";
import hornSoundFile from "./sounds/airhorn.mp3";

function GameChrono(props) {
  const {
    time,
    onStart = () => {
    },
    onPause = () => {
    },
    onNextTurn = () => {
    },
    onNextTurnSuccess = () => {
    },
    onNextTurnError = () => {
    },
    onTick=(t)=>{

    },
    freezeChrono = false,
    disabled = false
  } = props;
  const {game, team1, team2, activeTeam, nextTurn} = useGameboard();
  const [statusTeam1, setStatusTeam1] = useState("inactive");
  const [statusTeam2, setStatusTeam2] = useState("inactive");

  useEffect(() => {
    if (team1 && team2) {
      let status1 = (team1.id == activeTeam.id) ? "active" : "inactive"
      let status2 = (team2.id == activeTeam.id) ? "active" : "inactive"
      setStatusTeam1(status1)
      setStatusTeam2(status2)
    }
  }, [activeTeam, team1, team2])

  const handleClickTeam = (team) => {
    onNextTurn();
    if (team.id === activeTeam.id) {
      nextTurn(
        () => {
          onNextTurnSuccess();
        },
        () => {
          onNextTurnError();
        },
      );
    }
  }


  return (
    <GameChronoRoot>
      {game && team1 && team2 &&
        <GameChronoContent className={"bbsa-game-chrono-content"}>
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={0}
          >
            <ChronoTeam team={team1} side={"left"} disabled={statusTeam1 === "active" && !disabled ? false : true}
                        onTeamClick={handleClickTeam}/>
            <ChronoTeam team={team2} side={"right"} disabled={statusTeam2 === "active" && !disabled ? false : true}
                        onTeamClick={handleClickTeam}/>
            {(activeTeam.id !== 0) &&
              <ChronoActions onStart={onStart} onPause={onPause} onTick={onTick} freezeChrono={freezeChrono} time={time}/>}
          </Stack>
        </GameChronoContent>
      }
    </GameChronoRoot>
  )
}

function ChronoTeam(props) {
  const {
    team,
    disabled = false,
    side="center",
    onTeamClick = () => {
    }
  } = props;

  const handleOnClick = () => {
    if (!disabled) {
      onTeamClick(team);
    }
  }

  return (
    <GameChronoTeamRoot className={'bbsa-game-chrono-team-root'} onClick={handleOnClick}>
      <GameChronoTeamBackground side={side} disabled={disabled} className={'bbsa-game-chrono-team-background'}>
        <GameChronoTeamImageBlason className={'bbsa-game-chrono-team-blason'} alt={team.name} src={team.blasonUrl}/>
      </GameChronoTeamBackground>
    </GameChronoTeamRoot>
  )
}

function ChronoActions(props) {
  const {activeTeam} = useGameboard();
  const {
    onPause = () => {
    },
    onStart = () => {
    },
    time = 5,
    onTick = (t) => {
    },
    freezeChrono = false,
  } = props;
  const iconFontSize = 64;
  const [timerId, setTimerId] = useState(0);
  const [remainingTime, setRemainingTime] = useState(time);
  const [timeToDisplay, setTimeToDisplay] = useState(getDisplayTime(time));
  const [inPause, setInPause] = useState(true);
  const [ticktackAudio] = useState(new Audio(ticktackSoundFile));
  const [playTickTack, setPlayTickTack] = useState(false);
  const [hornAudio] = useState(new Audio(hornSoundFile));
  const [bgColor, setBgColor] = useState(perc2color(100));
  const freeze = useRef(false);


  useEffect(() => {
    if (playTickTack) {
      ticktackAudio.loop = true;
      ticktackAudio.play();
    } else {
      ticktackAudio.pause();
    }
  }, [playTickTack])

  useEffect(() => {
    freeze.current = freezeChrono;
  }, [freezeChrono])


  useEffect(() => {
    let tId = 0;
    setPlayTickTack(false);
    if (timerId !== 0) {
      clearInterval(timerId);
    }
    setRemainingTime(time);
    setTimeToDisplay(getDisplayTime(time))
    if (!inPause) {
      tId = doTimer(time);
    }
    setTimerId(tId);
  }, [activeTeam])


  const handlePlay = () => {
    clearInterval(timerId);
    let tId = doTimer(remainingTime);
    setInPause(false);
    setTimerId(tId);
    onStart();
  }


  const handlePause = () => {
    clearInterval(timerId);
    setTimerId(0);
    setInPause(true);
    setPlayTickTack(false);
    onPause(remainingTime)
  }

  const handleTimerTimeout = () => {
    setInPause(true);
    setPlayTickTack(false);
    if (timerId !== 0) {
      clearInterval(timerId);
    }
    hornAudio.play();
    setRemainingTime(time);
    setTimeToDisplay(getDisplayTime(time))
  }

  const handleCalcTime = (t) => {
    if (freeze.current) {
      return t;
    } else {
      return t - 1;
    }
  }

  const doTimer = (initialTimer) => {
    setBgColor(perc2color((initialTimer / time) * 100))
    return createTimer(initialTimer, (timestamp) => {
      setRemainingTime(timestamp);
      if (timestamp < 31) {
        setPlayTickTack(true);
      }
      onTick(timestamp);
      setBgColor(perc2color((timestamp / time) * 100))

      setTimeToDisplay(getDisplayTime(timestamp))
    }, handleTimerTimeout, handleCalcTime)
  }

  return (
    <GameChronoActionsContent className={'bbsa-game-chrono-actions-content'}>
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={0}
      >
        {inPause && <PlayCircleIcon sx={{fontSize: iconFontSize}} onClick={handlePlay}/>}
        {!inPause && <PauseCircleIcon sx={{fontSize: iconFontSize, color: bgColor}} onClick={handlePause}/>}
        <DisplayTime align={"center"}>{timeToDisplay}</DisplayTime>
      </Stack>
    </GameChronoActionsContent>
  )
}

const GameChronoRoot = styled(Box)(({props, theme}) => ({
  [theme.breakpoints.down("sm")]: {}
}))

const GameChronoContent = styled(Box)(({props, theme}) => ({
  position: "relative",
  zIndex: 0,
  [theme.breakpoints.down("sm")]: {}
}))

const GameChronoTeamRoot = styled(Box)(({props, theme}) => ({
  position: "relative",
  width: "50%",
  height: "200px",
  zIndex: "0",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  cursor: "pointer",
  [theme.breakpoints.down("sm")]: {}
}))

const GameChronoTeamBackground = styled(Box)(({disabled, theme, side}) => ({
  position: "absolute",
  backgroundPosition: "0 0",
  backgroundRepeat: "no-repeat",
  top: 0,
  left: 0,
  width: "100%",
  height: "200px",
  zIndex: -1,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  filter: disabled ? "grayscale(100%)" : "",
  opacity: disabled ? "0.5" : "",
  [theme.breakpoints.down("sm")]: {
    justifyContent: (side?side:"center"),
  }
}))

const GameChronoTeamImageBlason = styled("img")(({props, theme}) => ({
  width: "100%",
  maxWidth: "150px",
  minWidth: "75px",
  [theme.breakpoints.down("sm")]: {
    width : "50%"
  }
}))

const GameChronoActionsContent = styled(Box)(({props, theme}) => ({
  position: "absolute",
  cursor: "pointer",
  [theme.breakpoints.down("sm")]: {}
}))

const DisplayTime = styled(Typography)(({props, theme}) => ({
  fontSize:"3rem",
  [theme.breakpoints.down("sm")]: {
    fontSize:"2.5rem",
  }
}))


function getDisplayTime(t) {
  let minutes = parseInt(t / 60, 10)
  let secondes = parseInt(t % 60, 10)
  minutes = minutes < 10 ? "0" + minutes : minutes
  secondes = secondes < 10 ? "0" + secondes : secondes
  return minutes + ":" + secondes
}

function createTimer(timeInSecond, c, t = () => {
}, calcTime = (t) => {
  return t - 1
}) {
  let temps = timeInSecond

  function localTimeout() {
    clearInterval(intervalId);
    t();
  }

  function timer(callback, ct) {
    if (temps <= 0) {
      localTimeout();
    } else {
      temps = ct(temps);
      callback(temps)
    }
  }

  let intervalId = setInterval(timer, 1000, c, calcTime);
  return intervalId;
}

function perc2color(perc) {
  var r, g, b = 0;
  if (perc < 50) {
    r = 255;
    g = Math.round(5.1 * perc);
  } else {
    g = 255;
    r = Math.round(510 - 5.10 * perc);
  }
  var h = r * 0x10000 + g * 0x100 + b * 0x1;
  return '#' + ('000000' + h.toString(16)).slice(-6);
}

export default GameChrono;


