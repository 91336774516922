import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  TextField
} from "@mui/material";
import {useEffect, useState} from "react";
import {LocalizationProvider, TimePicker} from "@mui/x-date-pickers";
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import vsImage from "../../../images/vs.png";
import useSession from "../../contexts/session/useSession";
import {DaysService, GamesService, PoolsService, SeasonService} from "../../../services";

const addGameStructure = {
  progressStatus: "NOT_STARTED",
  time: null,
  poolId: null,
  dayId: null,
  gameCreationParticipations: [
    {
      teamId: 0,
      position: 1
    },
    {
      teamId: 0,
      position: 2
    }
  ]
}


function GameAdd(props) {
  const {currentSession} = useSession();

  const {
    dayId, onGameAdded = (resp) => {
    }
  } = props;
  const [day, setDay] = useState();
  const [pools, setPools] = useState();
  const [teams, setTeams] = useState([]);
  const [dayLoaded, setDayLoaded] = useState(false);
  const [poolsLoaded, setPoolsLoaded] = useState(false);
  const [selectedPool, setSelectedPool] = useState(0);
  const [selectedHour, setSelectedHour] = useState(new moment());

  const [selectedTeam1, setSelectedTeam1] = useState(0);
  const [selectedTeam2, setSelectedTeam2] = useState(0);


  const handleChangePool = (event) => {
    setSelectedPool(event.target.value);
    setSelectedTeam1(0);
    setSelectedTeam2(0)

  }

  const handleChangeHour = (newValue) => {
    setSelectedHour(newValue);
  };

  const handleChangeTeam1 = (event) => {
    setSelectedTeam1(event.target.value);
  }

  const handleChangeTeam2 = (event) => {
    setSelectedTeam2(event.target.value);
  }

  const handleSave = () => {
    let data = {...addGameStructure}
    data.dayId = dayId;
    data.time = selectedHour.format('HH:mm');
    data.poolId = selectedPool;
    data.gameCreationParticipations = []
    if (selectedTeam1 !== 0) {
      data.gameCreationParticipations.push(
        {
          teamId: selectedTeam1,
          position: 1
        }
      )
    }
    if (selectedTeam2 !== 0) {
      data.gameCreationParticipations.push(
        {
          teamId: selectedTeam2,
          position: 2
        }
      )
    }

    GamesService.addGame(data, currentSession.accessToken).then((d) => {
      onGameAdded(d);
    })
  }

  useEffect(() => {
    if (!dayLoaded) {
      DaysService.get(dayId).then((day) => {
        setDay(day);
        setDayLoaded(true)
      })
    }
  }, [dayLoaded, dayId])

  useEffect(() => {
    if (!poolsLoaded) {
      SeasonService.getCurrent().then((season) => {
        PoolsService.getPoolsOfSeason(season.id).then((pools) => {
          setPools(pools);
          setPoolsLoaded(true)
        })
      })
    }
  }, [poolsLoaded])

  useEffect(() => {
    if (selectedPool !== 0) {
      PoolsService.getTeams(selectedPool).then((teams) => {
        setTeams(teams);
      })
    } else {
      setTeams([]);
    }
  }, [selectedPool])

  if (!dayLoaded || !poolsLoaded) {
    return (
      <Box>
        <div>Chargement</div>
      </Box>
    )
  }

  return (
    <Box>
      <Box textAlign={"center"}>
        <h2>Ajout d'un match</h2>
      </Box>
      <Stack spacing={2}>
        <FormControl>
          <InputLabel htmlFor="journee-label">Journée</InputLabel>
          <OutlinedInput
            id="journee"
            value={day.name}
            label="Journée"
            InputProps={{
              readOnly: true,
            }}
          />
        </FormControl>
        <FormControl fullWidth>
          <InputLabel id="pool-label">Pool</InputLabel>
          <Select
            labelId="select-pool"
            id="select-pool"
            value={selectedPool}
            label="Pool"
            onChange={handleChangePool}
          >
            <MenuItem value={0}>Sélectionnez la pool</MenuItem>
            {pools.map((pool) => {
              return <MenuItem key={"pool-" + pool.id} value={pool.id}>{pool.phase.name} - {pool.name}</MenuItem>
            })}
          </Select>
        </FormControl>
        <FormControl fullWidth>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <TimePicker
              ampm={false}
              openTo="hours"
              views={['hours', 'minutes']}
              inputFormat="HH:mm"
              mask="__:__"
              label="Heure"
              onChange={handleChangeHour}
              value={selectedHour}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </FormControl>
        <Grid container>
          <Grid item xs={5}>
            <FormControl fullWidth>
              <InputLabel id="pool-label">Equipe 1</InputLabel>
              <Select
                labelId="select-pool"
                id="select-pool"
                value={selectedTeam1}
                label="Equipe 1"
                onChange={handleChangeTeam1}
              >
                <MenuItem sx={{fontStyle: "italic", color: "gray"}} value={0}>Non déterminé</MenuItem>
                {teams.filter(t => t.id !== selectedTeam2).map((team) => {
                  return <MenuItem value={team.id}>{team.name}</MenuItem>
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={2} textAlign={"center"}>
            <img style={{marginTop: 4}} src={vsImage} alt={"Versus"} width={40}/>
          </Grid>
          <Grid item xs={5}>
            <FormControl fullWidth>
              <InputLabel id="pool-label">Equipe 2</InputLabel>
              <Select
                labelId="select-pool"
                id="select-pool"
                value={selectedTeam2}
                label="Equipe 2"
                onChange={handleChangeTeam2}
              >
                <MenuItem sx={{fontStyle: "italic", color: "gray"}} value={0}>Non déterminé</MenuItem>
                {teams.filter(t => t.id !== selectedTeam1).map((team) => {
                  return <MenuItem value={team.id}>{team.name}</MenuItem>
                })}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <FormControl fullWidth>
          <Button variant="contained" onClick={handleSave} fullWidth>Enregistrer</Button>
        </FormControl>
      </Stack>
    </Box>
  )
}

export default GameAdd;
