import CommonService from "./CommonService";

class AuthService extends CommonService {
  async login(user, password) {
    return this.request({
      url: process.env.REACT_APP_API_URL + "/auth/login",
      method: 'POST',
      body: JSON.stringify(
        {
          username: user,
          password: password
        }
      )
    })
  }

  async register(user) {
    return this.request({
      url: process.env.REACT_APP_API_URL + "/auth/register?message=true",
      method: 'PUT',
      body: JSON.stringify(user)
    })
  }
}

export default new AuthService();


