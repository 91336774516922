import {useContext} from "react";
import {CurrentSessionContext, noSession} from "./SessionContext";
import AuthService from "../../../services/AuthService";
import UserService from "../../../services/UserService";
import Cookies from "universal-cookie";


export default function useSession(){
    const {currentSession, setCurrentSession, prevUrl, setPrevUrl, myTeams} = useContext(CurrentSessionContext)

  function login(username, password, callback = () => {
  }) {
    AuthService.login(username, password)
      .then(response => {
        const cookies = new Cookies();
        let bloodbowlCookie = cookies.get("emporiumBloodbowl")
        if (bloodbowlCookie === null || bloodbowlCookie === undefined) {
          let value = {
            username,
            password
          }
          cookies.set('emporiumBloodbowl', value, {
            path: '/',
            maxAge: 2147483647
          })
        }

        let accessToken = response.accessToken;
        UserService.me(accessToken).then((user) => {
          var isAdmin = false;
          user.roles.filter((r) => r == "ADMIN").map((r) => {
            isAdmin = true
          });
          setCurrentSession({
            ...currentSession,
            accessToken,
            user,
            connected: true,
            isAdmin: isAdmin
          })
        })
      })
      .catch((error) => {
        callback(error);
        logout();
      })
    ;
  }

  function reconnectFromCookie() {
    if (currentSession === noSession) {
      const cookies = new Cookies();
      let bloodbowlCookie = cookies.get("emporiumBloodbowl")
      if (bloodbowlCookie !== null && bloodbowlCookie !== undefined) {
        login(bloodbowlCookie.username, bloodbowlCookie.password);
      }
    }
  }

  function logout() {
    const cookies = new Cookies();
    cookies.remove("emporiumBloodbowl")
    setCurrentSession(noSession)
  }

    return {currentSession, login, logout, reconnectFromCookie, prevUrl, setPrevUrl, myTeams};
}
