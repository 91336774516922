import React, {Fragment, useState} from "react";
import {Box, Container, Fab, Grid, Paper, styled, Typography} from "@mui/material";
import useTeam from "../../contexts/team/useTeam";
import {StaffMenu} from "./StaffMenu";
import Banner from "../Banner/Banner";

const StaffDetailsStyled = styled(Paper)(({theme})=>({
    height : "100%",
    [".bbsa-staff-details-table-root.filled"] : {
        backgroundColor : theme.palette.background.paper,
    },
    [".bbsa-staff-details-staff-info"] : {
        fontFamily : theme.typography.fontFamily,
        fontSize: "0.8em"
    },
    [theme.breakpoints.down("sm")]: {
    }
}))


function StaffDetails (props){
  const {
    variant="filled",
    sx,
    showTreasury = true,
    showStaff = true,
    title = null,
  } = props;
  const {isMyTeam, team} = useTeam();

  return (
    <StaffDetailsStyled>
      <Box className={"bbsa-staff-details-table-root " + variant} sx={sx}>
        {title &&
          <StaffDetailsTitle className={"bbsa-staff-details-title"}>
            {showStaff && isMyTeam && <StaffMenu/>}
            <Typography align={"center"} sx={{pt : '6px'}}>{title}</Typography>
          </StaffDetailsTitle>
        }
        <StaffDetailsContent>
          {showStaff && !title && isMyTeam &&
            <Box sx={{height : "38px"}}>
              <StaffMenu/>
            </Box>
          }
          {team && showStaff && team.staffs.map((info) =>
            <StaffInfo key={"staff-info-" + info.id} name={info.name} value={info.number} price={info.totalValue}/>
          )}
          {team && showTreasury &&
            <Fragment>
              <StaffInfo name={"Trésorerie"} price={team.treasury}/>
              <StaffInfo name={"Valeur d'équipe (VE)"} price={team.teamValue}/>
              <StaffInfo name={"Valeur d'équipe actuelle (VEA)"} price={team.actualTeamValue}/>
            </Fragment>
          }
        </StaffDetailsContent>
      </Box>
    </StaffDetailsStyled>
  )
}

function StaffInfo (props) {
  const {name, value, price, variant='contained'} = props;

  return(
    <StaffInfoGridContainer container variant={variant}>
      {value &&
        <Grid item xs={2} align={'center'}>
          {value && <Typography className={'bbsa-staff-details-staff-info'} variant={'div'}>{value}</Typography>}
        </Grid>
      }
      <Grid item xs={value?8:10}  align={'left'}>
        <Typography className={'bbsa-staff-details-staff-info'} variant={'div'}>{name}</Typography>
      </Grid>
      <Grid item xs={2}  align={'right'}>
        <Typography className={'bbsa-staff-details-staff-info'} variant={'div'}>{price}K</Typography>
      </Grid>
    </StaffInfoGridContainer>
  )
}


const StaffInfoGridContainer = styled(Grid)(({theme, variant})=>({
}))


const StaffDetailsTitle = styled(Box)(({theme, variant})=>({
  height : "38px",
  position : "relative",
  color : theme.palette.primary.contrastText,
  background : theme.palette.secondary.gradient?theme.palette.secondary.gradient:theme.palette.secondary.main
}))

const StaffDetailsContent = styled(Container)(({theme, variant})=>({
  position : "relative",
  color : theme.palette.text.primary,
}))







export default StaffDetails;
