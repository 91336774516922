import React, {Fragment} from "react";
import {Box, styled, Typography} from "@mui/material";

export default function Banner(props){
    const {imgSource, text=null, bgClassName="", sx} = props;

    return(
      <BannerRoot className={"bbsa-banner-root"} sx={sx}>
        <BannerBackground src={imgSource} className={"bbsa-banner-background " + bgClassName} />
        <BannerFilter enabled={imgSource?true:false} className={"bbsa-banner-filter"}/>
        <BannerContent className={"bbsa-banner-content"}>
          {text &&
            <BannerSimpleText className={'bbsa-banner-simple-text'} align={'center'}>{text}</BannerSimpleText>
          }
          {!text &&
            <Fragment>
              {props.children}
            </Fragment>
          }
        </BannerContent>
      </BannerRoot>
    )
}

const BannerRoot = styled(Box)(({theme})=>({
    width : "100%",
    position: "relative",
    ["& p"] : {
        fontFamily : theme.typography.fontFamily,
        color : theme.palette.primary.contrastText,
    },
    [theme.breakpoints.down("sm")]: {
    }
}))

const BannerBackground = styled(Box)(({src, theme}) => ({
  backgroundImage: src ? "url('" + src + "')" : "",
  background: !src ? (theme.palette.secondary.gradient ? theme.palette.secondary.gradient : theme.palette.secondary.light) : "",
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  backgroundPosition: "center",
  backgroundSize: "cover",
  zIndex: "0"
}))

const BannerFilter = styled(Box)(({enabled, theme}) => ({
  background: enabled ? theme.components.bbsa.banner.filter.color : "",
  position: "absolute",
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  zIndex: -1
}))

const BannerContent = styled(Box)(({props, theme}) => ({
  paddingTop: "24px",
  paddingBottom: "24px",
  position: "relative"
}))

const BannerSimpleText = styled(Typography)(({props, theme}) => ({
  fontSize: "1.5em"
}))


