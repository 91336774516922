import React, {createContext, useEffect, useState} from "react";
import useGame from "../game/useGame";
import GamesService from "../../../services/GamesService";
import {GAME_STATUS_INPROGRESS} from "../../components/Global/Global";
import {useExitPage} from "../routes/useExitPage";
import {Alert} from "@mui/material";

const defaultContextValues = {}

export const CurrentGameStreamContext = createContext(defaultContextValues);

export const GameStreamContext = (props) => {
  const {game, events, removeEvent, addEvent, handleNoStreamingWithServer} = useGame();
  const [sseEmiter, setSseEmiter] = useState(null);
  const [trxToRemove, setTrxToRemove] = useState(null);
  const [trxToAdd, setTrxToAdd] = useState(null);
  const [connectionLost, setConnectionLost] = useState(null);

  useEffect(() => {
    if ((sseEmiter === null) && game !== null && game.progressStatus === GAME_STATUS_INPROGRESS) {
      let listener = GamesService.streamEvents(game.id, (trx) => {
        switch (trx.typeOfStream) {
          case "DELETE" :
            setTrxToRemove(trx)
            break;
          case "ADD" :
            setTrxToAdd(trx)
            break;
          default :
        }
      }, () => {
        setConnectionLost(true)
        handleNoStreamingWithServer(true)
      }, (sse) => {
        setConnectionLost(false)
        handleNoStreamingWithServer(false)
      });
      setSseEmiter(listener);
    }
  }, [events])

  useExitPage(() => {
    if (sseEmiter) {
      sseEmiter.close();
    }
  })

  useEffect(() => {
    if (trxToRemove !== null) {
      removeEvent(trxToRemove);
      setTrxToRemove(null)
    }
  }, [trxToRemove])

  useEffect(() => {
    if (trxToAdd !== null) {
      addEvent(trxToAdd);
      setTrxToAdd(null)
    }
  }, [trxToAdd])


  const values = {events, connectionLost}

  return (
    <CurrentGameStreamContext.Provider value={values}>
      {connectionLost && sseEmiter &&
        <Alert severity={"error"} color={"error"}>la connexion avec le serveur est interrompue</Alert>
      }
      {props.children}
    </CurrentGameStreamContext.Provider>
  )
}
