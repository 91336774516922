export const CONTENT_TYPE_AUTO_DETECT = 'CONTENT-TYPE-AUTO'


class CommonService {
  async request(options, contentType = 'application/json', convertMethod = 'json') {
    const headers = new Headers({})

    if (contentType !== CONTENT_TYPE_AUTO_DETECT) {
      headers.append('Content-Type', contentType);
    }

    if (options.token) {
      headers.append('Authorization', 'Bearer ' + options.token)
    }

    const defaults = {headers: headers};
    options = Object.assign({}, defaults, options);

    return fetch(options.url, options)
      .then(response => {
          if (!response.ok) {
            return response.json().then(json => {
              return Promise.reject(json);
            })
          }
          switch (convertMethod) {
            case "json" :
              return response.json().then(json => {
                return json;
              })
            case "none" :
            default :
              return response;
          }
        }
      );
  };

  async requestBlob(options) {
    const headers = new Headers({})

    if (options.token) {
      headers.append('Authorization', 'Bearer ' + options.token)
    }

    const defaults = {headers: headers};
    options = Object.assign({}, defaults, options);

    const r = await fetch(options.url, options);
    const src = await convertBlobToBase64(await r.blob());
    return src;
  };

  async requestSSE(options) {
  }
}

function convertBlobToBase64(blob) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onerror = reject;
    reader.onload = () => {
      resolve(reader.result);
    };

    reader.readAsDataURL(blob);
  });

}

export default CommonService;
