import React, {Fragment} from "react";
import {Container, Paper} from "@mui/material";
import SportsFootballIcon from '@mui/icons-material/SportsFootball';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import VerifiedIcon from '@mui/icons-material/Verified';
import ArticleIcon from '@mui/icons-material/Article';
import GroupIcon from '@mui/icons-material/Group';
import {Link} from "react-router-dom";

export default function ManagePage(props) {
  return (
    <Fragment>
      <Container maxWidth={"lg"} className={'content-bb'}>
        <h1>Gestion</h1>
        <CardItem name={"Matchs"} iconComponent={<SportsFootballIcon/>} link={"/gestion/games"}/>
        <CardItem name={"Journées"} iconComponent={<CalendarTodayIcon/>} link={"/gestion/days"}/>
        <CardItem name={"News"} iconComponent={<ArticleIcon/>} link={"/gestion/news"}/>
        <CardItem name={"Users"} iconComponent={<GroupIcon/>} link={"/gestion/users"}/>
        <CardItem name={"Approb."} iconComponent={<VerifiedIcon/>} link={"/gestion/approbations"}/>
      </Container>
    </Fragment>
  )
}

function CardItem(props) {
  const {name, link, iconComponent} = props;
  return (
    <Link to={link}>
      <Paper sx={{
        marginRight: 2,
        marginBottom: 2,
        padding: 5,
        maxHeight: 100,
        maxWidth: 100,
        minHeight: 100,
        minWidth: 100,
        float: "left"
      }}>
        {iconComponent}
        <h2>{name}</h2>
      </Paper>
    </Link>
  )
}
