import React from "react";
import {Box, Stack, styled, Typography} from "@mui/material";
import useGameboard from "../../contexts/gameboard/useGameboard";

export default function GameCurrentTurn(props) {
  const {variant} = props;
  const {activeTeam, turn} = useGameboard();

  return (
    <GameCurrentTurnRoot variant={variant} className={"bbsa-current-game-turn-root"}>
      <Stack>
        <HalftimeLabel className={"bbsa-game-current-turn-halftime"} align={"center"}
                       variant={'span'}>{getHalftimeLabel(turn.halftime)}</HalftimeLabel>
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={1}
        >
          <TurnLabel className={"bbsa-game-current-turn-text"} align={"center"} variant={'span'}>
            tour
          </TurnLabel>
          <TurnNumberLabel className={"bbsa-game-current-turn-value"} align={"center"}
                           variant={'span'}>{turn.number}</TurnNumberLabel>
        </Stack>
        <ActiveTeamLabel className={"bbsa-game-current-turn-activeTeam"} align={"center"}
                         variant={'span'}>{activeTeam.name}</ActiveTeamLabel>
      </Stack>
    </GameCurrentTurnRoot>
  )
}

function getHalftimeLabel(halftimeNumber) {
  switch (halftimeNumber) {
    case 1 :
      return "1er mi-temps"
    case 2 :
      return "2eme mi-temps"
    default :
      return "prolongation"
  }
}

const GameCurrentTurnRoot = styled(Box)(({variant, theme}) => ({
  fontFamily: theme.typography.fontFamily,
  color: (variant === "primary" || variant === "secondary") ? theme.palette[variant].contrastText : theme.palette.text.primary,
  backgroundColor: (variant === "primary" || variant === "secondary") ? theme.palette[variant].main : "",
  [theme.breakpoints.down("sm")]: {}
}))

const HalftimeLabel = styled(Typography)(({variant, theme}) => ({
  [theme.breakpoints.down("sm")]: {}
}))

const TurnLabel = styled(Typography)(({variant, theme}) => ({
  [theme.breakpoints.down("sm")]: {}
}))

const TurnNumberLabel = styled(Typography)(({variant, theme}) => ({
  fontSize: "3rem",
  [theme.breakpoints.down("sm")]: {}
}))

const ActiveTeamLabel = styled(Typography)(({variant, theme}) => ({
  fontSize: "0.7rem",
  [theme.breakpoints.down("sm")]: {}
}))

