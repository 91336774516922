import {createContext, useEffect, useState} from "react";
import {SeasonService} from "../../../services";
import CircularProgress from '@mui/material/CircularProgress';
import {Backdrop, Stack, Typography} from "@mui/material";
import BBSABackdrop from "../../components/BbsaBackdrop/BBSABackdrop";
import useLoading from "../loading/useLoading";

const noSeason = {
    season: null,
    setSeason: () => {
    }
}


export const CurrentSeasonContext = createContext(noSeason);

export const SeasonContext = (props) => {
    const [season, setSeason] = useState(noSeason.season);
    const {showLoading, hideLoading} = useLoading();

    useEffect(() => {
        if (season === null) {
            showLoading("Chargement de la saison")
            SeasonService.getCurrent().then(season => {
                setSeason(season);
                hideLoading();
            })
        }
    }, [season])

    const value = {season, setSeason};

    return (
        <CurrentSeasonContext.Provider value={value}>
            {season !== null && props.children}
        </CurrentSeasonContext.Provider>
    );
};
