import React from "react";
import {EventButton} from "./EventSelectionStyles";

function PlayerSelectionButton(props) {
  const {
    player, onClick = (p) => {
    }
  } = props;

  return (
    <React.Fragment>
      <EventButton className={"bbsa-player-selection-button"} onClick={() => {
        onClick(player)
      }} fullWidth variant={"contained"}
                   color={"success"}>#{player.number} {player.positionalName}{(player.name !== null ? "(" + player.name + ")" : "")}</EventButton>
    </React.Fragment>
  )
}

export default PlayerSelectionButton;
