import React, {Fragment, useEffect, useState} from "react";
import {GamesService} from "../../services";
import {styled} from "@mui/material/styles";
import {Stack, Table, TableCell, TableRow, Typography} from "@mui/material";
import Blason from "../Blason/Blason";
import {
  GAME_STATUS_FINISHED,
  GAME_STATUS_FORFEIT,
  GAME_STATUS_INPROGRESS,
  GAME_STATUS_NOT_STARTED, GAME_STATUS_REPORTED
} from "../../V2/components/Global/Global";

export default function GameCard(props) {
  const {gameId} = props

  const [id, setId] = useState(gameId);
  const [game, setGame] = useState(null)
  const [team1, setTeam1] = useState(null)
  const [team2, setTeam2] = useState(null)

  useEffect(() => {
    if (id !== null && game === null) {
      GamesService.get(id).then((game) => {
        setGame(game);
        setTeam1(game.teamParticipations.filter(t => t.position === 1)[0])
        setTeam2(game.teamParticipations.filter(t => t.position === 2)[0])
      })
    }
  }, [id, game])


  return (
    <GameCardRoot>
      {game && team1 && team2 &&
        <TableParticipation size={"small"}>
          <ParticipationLine participation={team1} game={game}></ParticipationLine>
          <ParticipationLine participation={team2} game={game}></ParticipationLine>
          {/*<Stack*/}
          {/*  alignItems={"center"}*/}
          {/*>*/}
          {/*  <Blason  source={team1.team.blasonUrl} label={team1.name} size={"small"} disabled={team1.gameResult==="LOSE"}></Blason>*/}
          {/*  <Blason  source={team2.team.blasonUrl} label={team2.name} size={"small"} disabled={team2.gameResult==="LOSE"}></Blason>*/}
          {/*</Stack>*/}
          {/*<Stack>*/}
          {/*  <GameCardTypography>{team1.team.shortname}</GameCardTypography>*/}
          {/*  <GameCardTypography>{team2.team.shortname}</GameCardTypography>*/}
          {/*</Stack>*/}
          {/*<Stack>*/}
          {/* {(game.progressStatus === GAME_STATUS_FINISHED || game.progressStatus === GAME_STATUS_FORFEIT) &&*/}
          {/*    <Fragment>*/}
          {/*      <GameCardTypography color={team1.gameResult==="WIN"?"primary":""}>{team1.score}</GameCardTypography>*/}
          {/*      <GameCardTypography color={team2.gameResult==="WIN"?"primary":""}>{team2.score}</GameCardTypography>*/}
          {/*    </Fragment>*/}
          {/*  }*/}
          {/*  {(game.progressStatus === GAME_STATUS_NOT_STARTED ||game.progressStatus === GAME_STATUS_INPROGRESS ||game.progressStatus === GAME_STATUS_REPORTED) &&*/}
          {/*    <Fragment>*/}
          {/*      <GameCardTypography>-</GameCardTypography>*/}
          {/*      <GameCardTypography>-</GameCardTypography>*/}
          {/*    </Fragment>*/}
          {/*  }*/}
          {/*</Stack>*/}
        </TableParticipation>
      }
    </GameCardRoot>
  )
}

const ParticipationLine = (props) => {
  const {participation, game} = props;
  return (
    <TableRow>
      <TableCell align={"center"} width={"1%"}>
        <Blason  source={participation.team.blasonUrl} label={participation.name} size={"small"} disabled={participation.gameResult==="LOSE"}></Blason>
      </TableCell>
      <TableCell align={"left"} width={"98%"}>
        <GameCardTypography>{participation.team.shortname}</GameCardTypography>
      </TableCell>
      <TableCell align={"right"} width={"1%"}>
         {(game.progressStatus === GAME_STATUS_FINISHED || game.progressStatus === GAME_STATUS_FORFEIT) &&
              <GameCardTypography color={participation.gameResult==="WIN"?"primary":""}>{participation.score}</GameCardTypography>
          }
          {(game.progressStatus === GAME_STATUS_NOT_STARTED ||game.progressStatus === GAME_STATUS_INPROGRESS ||game.progressStatus === GAME_STATUS_REPORTED) &&
               <GameCardTypography>-</GameCardTypography>
          }
      </TableCell>
      <TableCell></TableCell>
    </TableRow>
  )
}



const GameCardRoot = styled(Stack)(({theme}) => ({
  padding: "0px 0px",
  minHeight: "44px",
  minWidth : 160,
  [theme.breakpoints.down("sm")]: {}
}));

const GameCardTypography = styled(Typography)(({theme, color}) => ({
  padding: 0,
  fontWeight: "border",
  color : color==="primary"?theme.palette.primary.main:"",
  fontFamily: (theme.components.bbsa.typography.secondaryFontFamily ? theme.components.bbsa.typography.secondaryFontFamily : theme.typography.font),
  [theme.breakpoints.down("sm")]: {}
}));

const TableParticipation = styled(Table)(({theme, color}) => ({
  'td' : {
    border : 0,
    paddingLeft : 5,
    paddingRight : 5,
  },
  fontFamily: (theme.components.bbsa.typography.secondaryFontFamily ? theme.components.bbsa.typography.secondaryFontFamily : theme.typography.font),
  [theme.breakpoints.down("sm")]: {}
}));
