import React from "react";
import {Box, Divider, Paper, Stack, Typography} from "@mui/material";
import "./NewsPaper.css"
import backgroundImage from "../../../images/oldpaper.jpg";
import backgroundHeaderImage from "../../../images/news_header_1311_140.jpg";
import backgroundContentImage from "../../../images/news_content.jpg";

function NewsPaper(props) {

  const {content, date} = props;

  function createMarkup() {
    return {__html: content};
  }

  return (
    <React.Fragment>
      <Box
        className={"bbsa-newspaper-root"}
        sx={{
          backgroundImage: `url(${backgroundContentImage})`
        }}
      >
          <Stack>
        <Box className={"bbsa-newspaper-header"} sx={{ height : 152, backgroundImage : `url(${backgroundHeaderImage})`, position : 'relative'}}>
                <Typography className={'bbsa-newspaper-date'} sx={{position:'absolute', top:92,left:160,fontSize:"1.2em", fontWeight : "bolder", fontFamily : "OldLondon"}}>{date}</Typography>
        </Box>
        <Box className={["ck-content", "bbsa-newspaper-content"]} sx={{backgroundImage : `url(${backgroundContentImage})`, position : 'relative'}} dangerouslySetInnerHTML={createMarkup()}/>
          </Stack>
      </Box>

    </React.Fragment>
  )
}

export default NewsPaper;
