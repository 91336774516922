import React, {Fragment, useState} from "react";
import {Box, Button, Dialog, DialogContent, DialogContentText, DialogTitle, IconButton} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import EventCategoryButton from "./EventCategoryButton";


function EventAddButton(props) {
  const {
    game,
    onEventSelected = () => {
    },
    onSelectionClose = () => {
    },
    onSelectionOpen = () => {
    },
    eventCategories,
    disabled = false
  } = props;
  const [openEventCategories, setOpenEventCategories] = useState(false);

  const handleOpenEventCategories = () => {
    onSelectionOpen();
    setOpenEventCategories(true);
  }
  const handleCloseEventCategories = () => {
    onSelectionClose();
    setOpenEventCategories(false);
  }

  const handleOnClickEventCategory = (category, event, team, player, victim, injuries) => {
    handleCloseEventCategories();
    onEventSelected({category, event, team, player, victim, injuries});
  }


  return (
    <Fragment>
      <Box className={"bbsa-event-add-root"}>
        <Button
          fullWidth
          variant={"contained"}
          color={"success"}
          onClick={handleOpenEventCategories}
          disabled={disabled}
        >
          Ajouter un evenement
        </Button>
      </Box>
      <Dialog
        open={openEventCategories}
        onClose={handleCloseEventCategories}
        scroll={'paper'}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title" align={"right"}>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={handleCloseEventCategories}
          >
            <CloseIcon/>
          </IconButton>
        </DialogTitle>
        <DialogContent dividers={true}>
          <DialogContentText
            id="scroll-dialog-description"
            tabIndex={-1}
          >
            {eventCategories.map((value) => {
              return (<EventCategoryButton onClick={handleOnClickEventCategory}
                                           key={"eventsCategories-" + value.id} game={game}
                                           category={value}/>)
            })}
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </Fragment>
  )
}

export default EventAddButton
