import React from "react";
import SportsScoreIcon from "@mui/icons-material/SportsScore";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import FlakyIcon from "@mui/icons-material/Flaky";
import UpdateIcon from "@mui/icons-material/Update";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import {Box, Stack, Tooltip} from "@mui/material";
import SportsIcon from "@mui/icons-material/Sports";

export default function GameStatusIcon(props) {
  const {status, showLabel = false} = props;

  switch (status) {
    case "NOT_STARTED" :
      return <StatusGeneric icon={<SportsScoreIcon/>} label={"non démarré"} showLabel={showLabel ? true : false}/>;
    case "IN_PROGRESS" :
      return <StatusGeneric icon={<HourglassEmptyIcon/>} label={"en cours"} showLabel={showLabel ? true : false}/>;
    case "FINISHED" :
      return <StatusGeneric icon={<DoneAllIcon/>} label={"terminé"} showLabel={showLabel ? true : false}/>;
    case "FORFEIT" :
      return <StatusGeneric icon={<FlakyIcon/>} label={"terminé"} showLabel={showLabel ? true : false}/>;
    case "DELAYED" :
      return <StatusGeneric icon={<UpdateIcon/>} label={"reporté"} showLabel={showLabel ? true : false}/>;
    case "STARTING" :
      return <StatusGeneric icon={<SportsIcon/>} label={"Sifflé"} showLabel={showLabel ? true : false}/>;
    default :
      return <StatusGeneric icon={<QuestionMarkIcon/>} label={status} showLabel={showLabel ? true : false}/>;
  }
}

function StatusGeneric(props) {
  const [tooltipOpen, setTooltipOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setTooltipOpen(false);
  }

  const handleTooltipOpen = () => {
    setTooltipOpen(true);
  };

  const {icon, label, showLabel} = props;
  return (
    <Box className={"bbsa-status-root"}>
      <Stack direction="row" sx={{display: 'inline-flex'}}>
        <Tooltip
          // PopperProps={{
          //     disablePortal: true,
          // }}
          onClose={handleTooltipClose}
          open={tooltipOpen}
          disableTouchListener
          title={label}
          arrow
        >
          <Box onClick={handleTooltipOpen}>
            {icon}
          </Box>
        </Tooltip>

        {showLabel && <div className={"bbsa-status-label"}> {label}</div>}
      </Stack>
    </Box>
  )
}

