import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography} from "@mui/material";

export default function ValidationBeforeAction(props) {
    const {
        open,
        onCancel = () => {},
        onApprouve = () => {},
        actionLabel="Acheter",
        elementToBuy = "",
        title="Validation",
    } = props;

    return (
        <Dialog
            open={open}
            onClose={onCancel}
            scroll={'paper'}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
        >
            <DialogTitle id="scroll-dialog-title">{title}</DialogTitle>
            <DialogContent dividers={true}>
                <DialogContentText
                    id="scroll-dialog-description"
                    tabIndex={-1}
                >
                    En cliquant sur <Typography component={"span"} color={"primary"}>{actionLabel}</Typography>, vous approuvez que vous ne pourrez pas etre remboursé(Trésorerie) et/ou récupérer les points(PSP) dépensés pour l'élement <b><Typography component={"span"} color={"primary"}>{elementToBuy}</Typography></b>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant={"outlined"} color={"primary"} onClick={onCancel}>Annuler</Button>
                <Button variant={"contained"} color={"secondary"} onClick={onApprouve}>{actionLabel}</Button>
            </DialogActions>
        </Dialog>

    )
}
