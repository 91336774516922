import React, {useEffect, useState} from "react";
import {Dialog, DialogContent, DialogContentText, DialogTitle, IconButton} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import PlayerSelectionButton from "./PlayerSelectionButton";
import EventBreadcrumbs from "./EventBreadcrumbs ";
import useGame from "../../contexts/game/useGame";
import {EventButton} from "./EventSelectionStyles";
import useGameboard from "../../contexts/gameboard/useGameboard";

const styleModal = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '70%',
  height: '70%',
  maxWidth: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

function TeamSelectionButton(props) {
  const {getTeam} = useGame();
  const {getAvailablePlayers} = useGameboard();
  const {
    teamParticipation,
    onClick = (t, p) => {
    },
    event = null,
    hasVictim = false,
    activeTeamOnly = false,
    selectPlayer = true,
  } = props;

  const [openPlayers, setOpenPlayers] = useState(false);
  const [players, setPlayers] = useState([])
  const [teamLoaded, setTeamLoaded] = useState(false)

  useEffect(() => {
    if (!teamLoaded) {
      let team = getTeam(teamParticipation.team.id);
      setPlayers(team.players);
      setTeamLoaded(true)
    }
  }, [players, teamLoaded])

  const handleOpenPlayers = () => {
    if (selectPlayer) {
      setOpenPlayers(true);
    } else {
      onClick(teamParticipation.team, getTeam(teamParticipation.team.id).players[0]);
    }
  }
  const handleClosePlayers = () => {
    setOpenPlayers(false);
  }


  const handleOnClickPlayer = (player) => {
    handleClosePlayers();
    onClick(teamParticipation.team, player);
  }

  return (
    <React.Fragment>
      <EventButton className={"bbsa-team-selection-button"} onClick={handleOpenPlayers} fullWidth
                   variant={"contained"} color={"success"}>{teamParticipation.team.name}</EventButton>


      <Dialog
        open={openPlayers}
        onClose={handleClosePlayers}
        scroll={'paper'}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title" align={"right"}>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={handleClosePlayers}
          >
            <CloseIcon/>
          </IconButton>
          <EventBreadcrumbs category={null} event={event} team={teamParticipation.team} player={null}
                            victim={null} victimTeam={null} hasVictim={hasVictim}/>
        </DialogTitle>
        <DialogContent dividers={true}>
          <DialogContentText
            id="scroll-dialog-description"
            tabIndex={-1}
          >
            {getAvailablePlayers(players).map((value) => {
              return (<PlayerSelectionButton onClick={handleOnClickPlayer} key={"teams-" + value.id}
                                             player={value}/>)
            })}

          </DialogContentText>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  )
}

export default TeamSelectionButton;
