import {createContext, useEffect, useState} from "react";
import GamesService from "../../../services/GamesService";
import TeamsService from "../../../services/TeamsService";
import EventsService from "../../../services/EventsService";


const defaultContextValues = {
  game: null,
  events: [],
  turns: [],
  team1: null,
  team2: null,
  eventCategories: []
}

export const CurrentGameContext = createContext(defaultContextValues)


export const GameContext = (props) => {
  const {id} = props;
  const [game, setGame] = useState(defaultContextValues.game);
  const [events, setEvents] = useState(defaultContextValues.events);
  const [eventCategories, setEventCategories] = useState(defaultContextValues.eventCategories);
  const [team1, setTeam1] = useState(defaultContextValues.team1);
  const [team2, setTeam2] = useState(defaultContextValues.team2);
  const [turns, setTurns] = useState(defaultContextValues.turns);
  const [connectionLost, setConnectionLost] = useState(false);
  const [desynchro, setDesynchro] = useState(false);

  useEffect(() => {
    if (game === null) {
      GamesService.get(id).then(async (game) => {
        setGame(game)
      })
    }
  }, [game])

  useEffect(() => {
    if (team1 === null && game !== null && game.teamParticipations[0]) {
      TeamsService.get(game.teamParticipations[0].team.id).then(team => {
        team.players = team.players
          .sort((a, b) => {
            return a.number - b.number
          })
          .slice()
        setTeam1(team);
      });
    }
  }, [team1, game])

  useEffect(() => {
    if (team2 === null && game !== null && game.teamParticipations[1]) {
      TeamsService.get(game.teamParticipations[1].team.id).then(team => {
        team.players = team.players
          .sort((a, b) => {
            return a.number - b.number
          })
          .slice()
        setTeam2(team);
      });
    }
  }, [team2, game])

  useEffect(() => {
    if (connectionLost) {
      setDesynchro(true)
    } else {
      if (desynchro && !connectionLost) {
        setDesynchro(false);
        setTeam1(defaultContextValues.team1)
        setTeam2(defaultContextValues.team2)
      }
    }
  }, [connectionLost])

  useEffect(() => {
    if ((team1 !== null && team2 !== null)) {

      GamesService.getEventsHistory(id).then((events) => {
        setEvents(events)
      });
    }
  }, [team1, team2])


  useEffect(() => {
    let turns = [];
    Object.entries(
      events.filter(i => i.eventCode !== 'KickOff').reduce((prev, curr) => {
        prev[curr.turn]
          ? prev[curr.turn].push(curr)
          : (prev[curr.turn] = [curr]);
        return prev;
      }, {})).map(([key, value]) => {
      turns.push({
        number: key,
        events: value
      })
    });
    setTurns(turns);
  }, [events])

  useEffect(() => {
    if (eventCategories === null) {
      EventsService.getEventCategories().then(categories => {
        setEventCategories(categories);
      })
    }
  }, [events])

  const value = {game, turns, events, setEvents, team1, team2, eventCategories, setConnectionLost};

  return (
    <CurrentGameContext.Provider value={value}>
      {props.children}
    </CurrentGameContext.Provider>
  )
}
