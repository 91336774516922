import {FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import {useEffect, useState} from "react";


export default function BBSASelectField(props){
  const {
    label = "",
    value = 0,
    noSelectionLabel = "non défini",
    items = [],
    labelConstructor = (item) => {
      return item.label
    },
    valueConstructor = (item) => {
      return item.value
    },
    onChanged = ()=>{}
  } = props;

  const [selectedItem, setSelectedItem] = useState(value);

  const handleSelectionChanged = (e) => {
    setSelectedItem(e.target.value);
  }

  useEffect(()=>{
    onChanged(selectedItem);
  }, [selectedItem])


  return (
    <FormControl fullWidth size="small">
      <InputLabel id="pool-label">{label}</InputLabel>
      <Select
        labelId="select-pool"
        id="select-pool"
        value={selectedItem}
        label="Pool"
        onChange={handleSelectionChanged}
      >
        <MenuItem value={0}>{noSelectionLabel}</MenuItem>
        {items.map((item, i) => {
          return (
            <MenuItem
              key={"bbsa_select_field_item-" + i}
              value={valueConstructor(item)}
            >
              {labelConstructor(item)}
            </MenuItem>
          )
        })}
      </Select>
    </FormControl>
  )
}
