import CommonService from "./CommonService";

class GamesService extends CommonService {
  async get(id) {
    return this.request({
      url: process.env.REACT_APP_API_URL + '/games/' + id,
      method: 'GET'
    })
  }

  async addGame(data, token) {
    return this.request({
      url: process.env.REACT_APP_API_URL + '/games',
      method: 'POST',
      body: JSON.stringify(data),
      token: token
    }, 'application/json', 'none')
  }

  async setEndGame(gameId, data, token) {
    return this.request({
      url: process.env.REACT_APP_API_URL + '/games/' + gameId + '/end?message=true',
      method: 'PUT',
      body: JSON.stringify(data),
      token: token
    }, 'application/json', 'none')
  }

  startGame(gameId, token) {
    return this.request({
      url: process.env.REACT_APP_API_URL + '/games/' + gameId + '/status',
      method: 'PUT',
      body: JSON.stringify("STARTING"),
      token: token
    }, 'application/json', 'none')
  }

  kickOffGame(gameId, token) {
    return this.request({
      url: process.env.REACT_APP_API_URL + '/games/' + gameId + '/status',
      method: 'PUT',
      body: JSON.stringify("IN_PROGRESS"),
      token: token
    }, 'application/json', 'none')
  }

  delayGame(gameId, token) {
    return this.request({
      url: process.env.REACT_APP_API_URL + '/games/' + gameId + '/status',
      method: 'PUT',
      body: JSON.stringify("DELAYED"),
      token: token
    }, 'application/json', 'none')
  }

  getMyGames(token) {
    return this.request({
      url: process.env.REACT_APP_API_URL + '/games/my',
      token: token
    })
  }

  async getEventsHistory(gameId) {
    return this.request({
      url: process.env.REACT_APP_API_URL + '/games/' + gameId + '/events/history',
      method: 'GET'
    })
  }

  streamEvents(id, callback = (event) => {
  }, onConnexionLost = () => {
  }, onConnectionOpen = () => {
  }) {
    const sse = new EventSource(process.env.REACT_APP_API_URL + '/games/' + id + "/events/stream",
      {withCredentials: false});

    function getRealtimeData(data) {
      callback(data);
    }

    sse.onmessage = e => getRealtimeData(JSON.parse(e.data));
    sse.onerror = () => {
      onConnexionLost();
    }
    sse.onopen = () => {
      onConnectionOpen(sse);
    }

    return sse;
  }

  async getEventsToValidate(id, token) {
    return this.request({
      url: process.env.REACT_APP_API_URL + '/games/' + id + '/validate',
      token: token
    })
  }

    addJourneymen(id, dataJourneymen, token) {
      return this.request({
        url: process.env.REACT_APP_API_URL + '/games/' + id + '/journeyman',
        token: token,
        method : 'PUT',
        body : JSON.stringify(dataJourneymen)
      }, 'application/json', 'none')
    }
}

export default new GamesService();
