import CommonService from "./CommonService";

class DaysService extends CommonService {
  async get(id) {
    return this.request({
      url: process.env.REACT_APP_API_URL + '/days/' + id,
      method: 'GET'
    })
  }

  async getRecents(id) {
    return this.request({
      url: process.env.REACT_APP_API_URL + '/days/season/' + id + '/recents',
      method: 'GET'
    })
  }

  getAllDaysOfSeason(seasonId) {
    return this.request({
      url: process.env.REACT_APP_API_URL + '/days/season/' + seasonId,
      method: 'GET'
    })
  }

  add(data, token) {
    return this.request({
      url: process.env.REACT_APP_API_URL + '/days',
      method: 'PUT',
      body: JSON.stringify(data),
      token: token
    })
  }

  update(id, data, token) {
    return this.request({
      url: process.env.REACT_APP_API_URL + '/days/' + id,
      method: 'POST',
      body: JSON.stringify(data),
      token: token
    })
  }

  delete(id, token) {
    return this.request({
      url: process.env.REACT_APP_API_URL + '/days/' + id,
      method: 'DELETE',
      token: token
    }, 'application/json', 'none')
  }
}

export default new DaysService();
