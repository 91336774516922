import {createContext, useEffect, useState} from "react";
import {SeasonService} from "../../../services";
import CircularProgress from '@mui/material/CircularProgress';
import {Backdrop, Stack, Typography} from "@mui/material";
import BBSABackdrop from "../../components/BbsaBackdrop/BBSABackdrop";

const defaultContextValues = {
    show : false,
    text : ""
}

export const CurrentLoadingContext = createContext(defaultContextValues);

export const LoadingContext = (props) => {
    const [loadingState, setLoadingState] = useState(defaultContextValues);

    const values = {loadingState, setLoadingState};

    return (
        <CurrentLoadingContext.Provider value={values}>
            <BBSABackdrop show={loadingState.show}>
                <Typography>{loadingState.text}</Typography>
            </BBSABackdrop>
            {props.children}
        </CurrentLoadingContext.Provider>
    );
};
