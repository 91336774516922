import {useContext} from "react";
import {CurrentThemeContext} from "./ThemeContext";
import {theme as themeLight} from "./bloodbowl";
import {theme as themeDark} from "./bloodbowldark";

export function useTheme() {
  const {theme, setTheme} = useContext(CurrentThemeContext);

  function toggleTheme(){

    if(theme.palette.mode==='light'){
      setTheme(themeDark)
    } else {
      setTheme(themeLight)
    }
  }


  return {theme, toggleTheme}
}
