import React, {useEffect, useState} from "react";
import GamesService from "../../services/GamesService";
import {Box, Container, Grid, Tab} from "@mui/material";
import GameCard from "../components/GameCard/GameCard";
import useSession from "../contexts/session/useSession";
import DaysService from "../../services/DaysService";
import SeasonService from "../../services/SeasonService";
import {TabContext, TabList, TabPanel} from "@mui/lab";
import TeamCard from "../components/TeamCard/TeamCard";
import {Link} from "react-router-dom";
import {styled} from "@mui/material/styles";
import GameCardContainer from "../components/GameCard/GameCardContainer";

export default function AccountPage(props) {
    const {currentSession, myTeams} = useSession()
    const [games, setGames] = useState([]);
    const [currentTab, setCurrentTab] = React.useState('1');

    const handleChangeTab = (event, newValue) => {
        setCurrentTab(newValue);
    };

    useEffect(() => {
        SeasonService.getCurrent().then((season) => {
            GamesService.getMyGames(currentSession.accessToken).then((games) => {
                DaysService.getAllDaysOfSeason(season.id).then((days) => {
                    let extendedGames = [];
                    games.map(game => {
                        let g = {
                            ...game,
                            day: days.filter(day => day.id === game.dayId)[0]
                        }
                        extendedGames.push(g)
                    })
                    setGames(extendedGames);
                })
            })

        })
    }, [])

    return (
        <TabContext value={currentTab}>
            <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                <TabList onChange={handleChangeTab} aria-label="lab API tabs example">
                    <Tab label="Mes matchs" value="1"/>
                    <Tab label="Mes équipes" value="2"/>
                    <Tab label="Mon profil" value="3"/>
                </TabList>
            </Box>
            <TabPanel value="1">
                <Container maxWidth={"lg"}>
                    <GamesContainer games={games.filter(g => g.progressStatus === "IN_PROGRESS")}/>
                    <GamesContainer games={games.filter(g => g.progressStatus === "NOT_STARTED")}/>
                    <GamesContainer games={games.filter(g => g.progressStatus === "DELAYED")}/>
                    <GamesContainer games={games.filter(g => g.progressStatus === "FINISHED")}/>
                </Container>
            </TabPanel>
            <TabPanel value="2">
                <Container maxWidth={"lg"}>
                    <TeamsContainer teams={myTeams}/>
                </Container>
            </TabPanel>
            <TabPanel value="3">Mon profil</TabPanel>
        </TabContext>
    )
}

function GamesContainer(props) {
    const {games} = props;

    return (
        <Grid container spacing={2} paddingBottom={2}>
            {games.map(game =>
                <Grid item xs={6} sm={4} md={3}>
                    <GameCardContainer>
                        <GameCard game={game} day={game.day} gameId={game.id}/>
                    </GameCardContainer>
                </Grid>
            )}
        </Grid>
    )
}

function TeamsContainer(props) {
    const {teams} = props;

    return (
        <Grid container spacing={2} paddingBottom={2}>
            {teams.map(team =>
                <Grid item xs={12} sm={6} md={4} lg={4}>
                    <Link to={"/teams/" + team.id}>
                        <TeamCard team={team} key={team.id}/>
                    </Link>
                </Grid>
            )}
            <Grid item xs={12} sm={6} md={4} lg={4}>
                <TeamCard create onClick={() => {
                    alert("Fonction pas encore disponible")
                }}/>
            </Grid>
        </Grid>
    )
}

const TeamCardContainer = styled(Box)(({theme}) => ({
    display: "inline-block",
    paddingRight: 20,
    paddingBottom: 20,
    [theme.breakpoints.down("sm")]: {}
}))
