import {
    Grid,
    IconButton,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Typography
} from "@mui/material";
import React, {Fragment, useEffect, useState} from "react";
import {RacesService, TeamsService} from "../../../services";
import Blason from "../Blason/Blason";
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import AddCircleIcon from '@mui/icons-material/AddCircle';
export default function JourneyManSelector (props) {
    const {team, onChanged=()=>{}} = props;
    const [players, setPlayers] = useState(null);
    const [selectableLinemen, setSelectableLinemen] = useState([]);
    const [maxJN, setMaxJN] = useState(0);
    const [selectedJN, setSelectedJN] = useState(0);
    const [journeymen, setJourneymen] = useState([]);

    const handleChangeCount = (posId, count) => {
        setSelectedJN(selectedJN + count);
        if(count>0){
            addJourneyman(posId)
        } else {
            removeJourneyman(posId)
        }
    }

    const addJourneyman = (posId) => {
        let newArray = [];
        for (let i = 0; i < journeymen.length; i++) {
            newArray.push(journeymen[i])
        }
        newArray.push(posId);
        setJourneymen(newArray)
    }
    const removeJourneyman = (posId) => {
        let removed = false;
        let newArray = [];
        for (let i = 0; i < journeymen.length; i++) {
            if(journeymen[i]===posId){
                if(removed){
                    newArray.push(journeymen[i]);
                }
                removed = true;
            } else {
                newArray.push(journeymen[i]);
            }
        }
        setJourneymen(newArray)
    }

    useEffect(()=> {
        onChanged(team.id, journeymen, selectedJN===maxJN)
    }, [journeymen, maxJN])

    useEffect(()=>{
        const p = team.players.filter(p => p.active===true)
            .filter(p => {
            let x = p.injuries.filter(i => (i.active === true && i.injuryTypeCode === 'DEAD') || (i.active === true && i.injuryTypeCode === 'MissNextGame'));
                if(x.length > 0){
                    return false
                }
                return true
            }
        );
        setPlayers(p);
        if(11-p.length > 0){
            setMaxJN(11-p.length)
        }
        RacesService.getLinemen(team.race.id).then((linemen)=>{
            setSelectableLinemen(linemen);
        });
    }, [])


    return (
        <Fragment>
            {team &&
                <TableContainer>
                    <Table>
                       <TableBody>
                            <TableRow>
                                <TableCell width={"20%"}>
                                    <Stack
                                        justifyContent="center"
                                        alignItems="center"
                                        spacing={2}
                                    >
                                        <Blason size={64} source={team ? team.blasonUrl : ""}/>
                                        <Typography>{team ? team.shortname : ""}</Typography>
                                    </Stack>
                                </TableCell>
                                <TableCell width={"10%"}>
                                    <Typography>{selectedJN + "/" + maxJN}</Typography>
                                </TableCell>
                                <TableCell width={"70%"}>
                                    <Stack>
                                        {
                                            selectableLinemen.map(l => {
                                                return (<LinemanSelector lineman={l} canAdd={selectedJN<maxJN} canRemove={selectedJN>0}  onChange={handleChangeCount}/>)
                                            })
                                        }
                                    </Stack>
                                </TableCell>
                            </TableRow>
                       </TableBody>
                    </Table>
                </TableContainer>
            }
        </Fragment>
    )
}


const LinemanSelector = (props) => {
    const {lineman, canAdd, canRemove, onChange=()=>{}} = props;
    const [count, setCount] = useState(0);

    const handleLess = () => {
        handleCounted(-1);
    }

    const handleMore = () => {
        handleCounted(1);
    }

    const handleCounted = (value) => {
        setCount(count + value);
        onChange(lineman.id, value);
    }

    return (
        <Stack
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            spacing={2}
        >
            <Typography>{lineman.name}</Typography>
            <IconButton disabled={!canRemove || count<1} aria-label="delete" size="large" color={"primary"} onClick={handleLess}>
                <RemoveCircleIcon fontSize="inherit" />
            </IconButton>
            <Typography>{count}</Typography>
            <IconButton disabled={!canAdd} aria-label="delete" size="large" color={"primary"} onClick={handleMore}>
                <AddCircleIcon fontSize="inherit" />
            </IconButton>
        </Stack>
    )
}
