import {Box, Button, Stack, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import DeleteIcon from '@mui/icons-material/Delete';
import useSession from "../../contexts/session/useSession";
import {DaysService} from "../../../services";


function DayDelete(props) {
  const {currentSession} = useSession();
  const {
    dayId, onDayDeleted = () => {
    }
  } = props;
  const [dayLoaded, setDayLoaded] = useState(false);
  const [day, setDay] = useState(null);

  useEffect(() => {
    if (!dayLoaded) {
      DaysService.get(dayId).then((day) => {
        setDay(day);
        setDayLoaded(true)
      })
    }
  }, [dayLoaded, dayId])

  if (!dayLoaded) {
    return (
      <Box>
        <div>Chargement</div>
      </Box>
    )
  }

  const handleDeleteClick = () => {
    DaysService.delete(dayId, currentSession.accessToken).then((response) => {
      onDayDeleted(day);
    })
  }

  return (
    <Box>
      <Stack spacing={2}>
        <Box textAlign={"center"}>
          <h2>Confirmation</h2>
        </Box>
        <Box textAlign={"center"}>
          <Typography variant={"h6"}>
            {day.name}
          </Typography>
          <Typography>
            Etes-vous de vouloir effacer cette journée ?
          </Typography>
        </Box>
        <Button variant={"contained"} onClick={handleDeleteClick} startIcon={<DeleteIcon/>}>Effacer</Button>
      </Stack>
    </Box>
  )
}

export default DayDelete;
