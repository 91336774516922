import {styled} from "@mui/material/styles";
import {Avatar} from "@mui/material";

export default function Blason(props){
  const {
    source,
    label,
    size="small",
    disabled=false,
    spacing=0,
  } = props;

  return (
    <ImgStyled spacing={spacing} disabled={disabled} src={source} alt={label} size={size}/>
  )
}

const ImgStyled = styled(Avatar)(({theme, size, disabled, spacing}) => ({
  height :(size==="xs"?24:(size==="small" || size==="sm"?32:size)),
  width :(size==="xs"?18:(size==="small" || size==="sm"?32:size)),
  padding: spacing,
  minHeight: 24,
  filter: disabled?"grayscale(100%)":"",
  [theme.breakpoints.down("sm")]: {}
}));
