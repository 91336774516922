import {Box, Card, CardContent, CardMedia, Grid, Stack, styled, Typography} from "@mui/material";
import {Fragment, useEffect, useState} from "react";
import TeamsService from "../../../services/TeamsService";
import BBSALoadingBox from "../BbsaLoadingBox/BBSALoadingBox";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

export default function TeamCard(props) {
    const {team,
        create = false,
        onClick= ()=>{}
    } = props;

    return (
        <TeamCardRoot className={"bbsa-team-card-root"} create onClick={onClick}>
            {!create && team &&
                <Grid container>
                    <Grid item xs={12}>
                        <Typography align={"center"} noWrap variant={"h6"}>{team.name}</Typography>
                    </Grid>
                    <Grid item xs={12} sx={{display: 'flex'}}>
                        <TeamCardBlasonContent>
                            <CardMediaBlason
                                component="img"
                                sx={{width: "100px"}}
                                image={team.blasonUrl}
                                alt="Live from space album cover"
                            />
                        </TeamCardBlasonContent>
                        <TeamCardContent>
                            <TeamInfo id={team.id}/>
                        </TeamCardContent>
                    </Grid>
                </Grid>
            }
            {create &&
                <NewTeamCardContent>
                    <AddCircleOutlineIconStyled fontSize={"large"}/>
                </NewTeamCardContent>
            }
        </TeamCardRoot>
    )
}


function TeamInfo(props) {
    const {id} = props;
    const [team, setTeam] = useState(null);


    useEffect(() => {
        if (team === null) {
            TeamsService.get(id).then((team) => {
                setTeam(team)
            }).catch((error) => {

            })
        }
    }, [id])

    if (team === null) {
        return (
            <BBSALoadingBox/>
        )
    }

    return (
        <Fragment>
            <Stack>
                <TeamCardInfo noWrap variant={"body"}><b>Trésorerie</b> : {team.treasury}K</TeamCardInfo>
                <TeamCardInfo noWrap variant={"body"}><b>VE</b> : {team.teamValue}K</TeamCardInfo>
                <TeamCardInfo noWrap variant={"body"}><b>VEA</b> : {team.actualTeamValue}K</TeamCardInfo>
                <TeamCardInfo noWrap variant={"body"}><b>Staff</b> : {team.staffs.length}</TeamCardInfo>
                <TeamCardInfo noWrap variant={"body"}><b>Joueurs</b> : {team.players.length}</TeamCardInfo>
            </Stack>
        </Fragment>
    )
}


const TeamCardRoot = styled(Card)(({theme, create}) => ({
    maxWidth: "392px",
    width: "100%",
    height: "180px",
    display: 'flex',
    cursor : (create?"pointer":""),
    [theme.breakpoints.down("sm")]: {
        maxWidth: "600px",
        height: "144px",
    }
}))

const TeamCardContent = styled(CardContent)(({theme}) => ({
    paddingLeft: 0,
    width: '100%',
    [theme.breakpoints.down("sm")]: {}
}))

const TeamCardBlasonContent = styled(Box)(({theme}) => ({
    display: 'flex',
    flexDirection: 'column',
    padding: 10,
    justifyContent: 'center',
    [theme.breakpoints.down("sm")]: {}
}))

const CardMediaBlason = styled(CardMedia)(({theme}) => ({
    width: "100px",
    [theme.breakpoints.down("md")]: {
        width: "80px",
    }

}))

const TeamCardInfo = styled(Typography)(({theme}) => ({
    fontSize: "1rem",
    [theme.breakpoints.down("md")]: {
        fontSize: "0.8rem",
    }
}))

const NewTeamCardContent = styled(CardContent)(({theme}) => ({
    margin : 10,
    border: theme.palette.secondary.main + " 2px dashed",
    display:"flex",
    justifyContent : 'center',
    alignItems : 'center',
    width: '100%',
    [theme.breakpoints.down("sm")]: {}
}))

const AddCircleOutlineIconStyled = styled(AddCircleOutlineIcon)(({theme}) => ({
    color : theme.palette.secondary.main
}))

