import React, {Fragment} from "react";
import {Navigate} from "react-router-dom";
import {Container} from "@mui/material";
import LoginForm from "../components/LoginForm/LoginForm";
import useSession from "../contexts/session/useSession";


export default function LoginPage(props) {
  const {currentSession, prevUrl} = useSession();

  if (currentSession.connected) {
    return (<Navigate to={prevUrl}/>)
  }

  return (
    <Fragment>
      <Container maxWidth={"lg"} className={'content-bb'}>
        <LoginForm/>
      </Container>
    </Fragment>
  )
}
