import {Box, Grid, Paper, Stack, styled, Typography} from "@mui/material";
import {useEffect, useState} from "react";
import Blason from "../Blason/Blason";

export default function Game(props) {
    const {
        game,
        id,
        children,
    } = props;

    const [home, setHome] = useState(null);
    const [visitor, setVisitor] = useState(null);
    const [teamParticipation1, setTeamParticipation1] = useState(null);
    const [teamParticipation2, setTeamParticipation2] = useState(null);

    useEffect(() => {
        let tp1 = game.teamParticipations.filter(t => t.position === 1)[0]
        let tp2 = game.teamParticipations.filter(t => t.position === 2)[0]
        if(tp1){
            setTeamParticipation1(tp1);
            setHome(tp1.team)
        }
        if(tp2){
            setTeamParticipation2(tp2);
            setVisitor(tp2.team)
        }
        }, [])

    return (
        <GameRoot className={"tournament-game-root"}>
            {children && <GameTitle align={"center"} className={"tournament-game-title"}>{children}</GameTitle>}
            <GameContent id={id} className={"tournament-game-content"}>
                <Grid container>
                    <FirstLabelLine item xs={10}>
                        <ParticipantLine team={home}/>
                    </FirstLabelLine>
                    <FirstScoreLine item xs={2}>{game.progressStatus==='FINISHED'?(teamParticipation1!==null?teamParticipation1.score:""):""}</FirstScoreLine>
                    <SecondLabelLine item xs={10}>
                        <ParticipantLine team={visitor}/>
                    </SecondLabelLine>
                    <SecondScoreLine item xs={2}>{game.progressStatus==='FINISHED'?(teamParticipation2!==null?teamParticipation2.score:""):""}</SecondScoreLine>
                </Grid>
            </GameContent>
        </GameRoot>
    )
}


const ParticipantLine = (props) => {
    const {team} = props;

    return (
        <Grid container spacing={1} sx={{p : "3px"}}>
            <Grid item xs={4} sx={{display: "flex", alignItems : "center", justifyContent: 'center'}}>
                    <Blason size={"sm"} source={team ? team.blasonUrl : ""}/>
            </Grid>
            <Grid item xs={8} sx={{display: "flex", alignItems : "center"}}>
                {team ? team.shortname : ""}
            </Grid>
        </Grid>
    )
}


const GameRoot = styled(Box)(({theme}) => ({
    height: 'auto',
    width: 175,
}))

const GameTitle = styled(Typography)(({theme}) => ({
    fontSize: "0.7rem",
    color : theme.palette.text.primary
}))

const GameContent = styled(Paper)(({theme}) => ({
    border: "black 2px solid"
}))

const LabelCell = styled(Grid)(({theme}) => ({
    fontSize: "0.8rem"
}))

const ScoreCell = styled(Grid)(({theme}) => ({
    justifyContent: "center",
    alignItems : "center",
    display: "flex",
    borderLeft: "black 1px solid",
    background: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    fontSize: "0.8rem"
}))

const FirstLabelLine = styled(LabelCell)(({theme}) => ({
    borderBottom: "black 1px solid"
}))

const FirstScoreLine = styled(ScoreCell)(({theme}) => ({
    borderBottom: "black 1px solid"
}))

const SecondLabelLine = styled(LabelCell)(({theme}) => ({}))

const SecondScoreLine = styled(ScoreCell)(({theme}) => ({}))


const SecondLine = styled(Grid)(({theme}) => ({}))
