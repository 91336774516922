import {createTheme} from "@mui/material";

export const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#A3262A',
      light: 'rgba(252,63,69,1)',
      dark: 'rgba(164,0,5,1)',
      // gradient: 'linear-gradient(340deg, rgba(164,0,5,1) 0%, rgba(163,38,42,1) 50%, rgba(252,63,69,1) 100%)',
    },
    secondary: {
      // main: '#2525A1',
      main: '#215994',
      light: '#4782be',
      dark: '#0f4782',
      gradient: 'linear-gradient(187deg, #0f4782 0%, #215994 50%, #4782be 100%)',
    },
    background: {
      // default: "#fafafa"
    }
  },
  components: {
    bbsa: {
      typography: {
        primaryFontFamily: "roboto",
        secondaryFontFamily: "Kimberley"
      },
      layout: {
        header: {
          backgroundImage: "url('/img/headerbackground.jpg')"
          // background : "linear-gradient(to bottom, #1c1711 0%, #2d251c 30%, #2d251c 70%, #1c1711 100%)",
          // logobg : "linear-gradient(to bottom, #1c1711 0%, #1c1711 50%, #110f0a 100% )",
        },
        content: {
          background: {
            // image : "url('/img/552591.jpg')",
          }
        },
        hideBlason: true
      },
      banner: {
        filter: {
          // color : "rgb(64 46 34 / 75%)"
        }
      },
      newsBanner: {
        // image : "/img/banner_news.jpg"
      },
      teamBanner: {
        // image : "/img/banner_team.jpg"
      },
      gameBanner: {
        // image : "/img/banner_game.jpg"
      },
      eventTimeline: {
        // background : "linear-gradient(to bottom, #1c1711 0%, #2d251c 30%, #2d251c 70%, #1c1711 100%)"
      }
    }
  },
  typography: {
    fontFamily: "roboto",
  },
  shape: {
    borderRadius: 0
  }
});
