import {
  Hidden,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@mui/material";
import React, {useEffect, useState} from "react";
import {PoolsService} from "../../services";
import Blason from "../Blason/Blason";
import {Skeleton} from "@mui/lab";
import {styled} from "@mui/material/styles";

export default function Rank(props) {
  const {rankId} = props;
  const [rank, setRank] = useState(null);

  useEffect(() => {
    if (rank === null) {
      PoolsService.getRank(rankId).then((rank) => {
        setRank(rank);
      })
    }
  }, [rank])

  return (
    <RankContainer component={Paper}>
      <RankTable size={"small"}>
        <TableHead>
          <TableRowRankName>
            <TableCellRankName colSpan={8}>
              {rank && rank.name}
              {!rank && <Skeleton variant="text" sx={{fontSize: '1rem'}}/>}
            </TableCellRankName>
          </TableRowRankName>
          <TableRow>
            <TableCell width={"1%"} align="center"></TableCell>
            <TableCell width={"1%"} align="center"></TableCell>
            <TableCell width={"93%"} align="center"></TableCell>
            <TableCell width={"1%"} align="center">J</TableCell>
            <TableCell width={"1%"} align="center">G</TableCell>
            <TableCell width={"1%"} align="center">N</TableCell>
            <TableCell width={"1%"} align="center">P</TableCell>
            <TableCell width={"1%"} align="center">L.P.</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!rank &&
            <TableRow>
              <TableCell colSpan={8}>
                <Skeleton variant="rectangular" width={"100%"} height={60}/>
              </TableCell>
            </TableRow>
          }
          {rank && rank.teams.map((team => {
            return (
              <TableRow>
                <TableCell><Typography>#{team.position}</Typography></TableCell>
                <TableCell><Blason source={team.blasonUrl} label={team.name} size={"small"}/></TableCell>
                <TableCell>
                  <Hidden  smDown={true}><Typography component={'div'}>{team.name}</Typography></Hidden>
                  <Hidden  smUp={true} ><Typography>{team.shortname}</Typography></Hidden>
                </TableCell>
                <TableCell align="center">{team.numberOfGames}</TableCell>
                <TableCell align="center">{team.numberOfWin}</TableCell>
                <TableCell align="center">{team.numberOfDraw}</TableCell>
                <TableCell align="center">{team.numberOfLose}</TableCell>
                <TableCell align="center">{team.lp}</TableCell>
              </TableRow>
            )
          }))}
        </TableBody>
      </RankTable>
    </RankContainer>
  )
}

const RankContainer = styled(TableContainer)(({theme}) => ({
  [theme.breakpoints.down("sm")]: {}
}));

const RankTable = styled(Table)(({theme}) => ({
  [theme.breakpoints.down("sm")]: {}
}));

const TableRowRankName = styled(TableRow)(({theme}) => ({
  background: (theme.palette.secondary.gradient ? theme.palette.secondary.gradient : theme.palette.secondary.main),
  [theme.breakpoints.down("sm")]: {}
}));

const TableCellRankName = styled(TableCell)(({theme}) => ({
  color : theme.palette.secondary.contrastText,
  [theme.breakpoints.down("sm")]: {}
}));
