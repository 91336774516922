import {Box, Button, Fab, Paper, Popover, Stack, styled} from "@mui/material";
import React from "react";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import PlayerLevelUpDialog from "../PlayerLevelUp/PlayerLevelUpDialog";
import ActionButton from "../ValidationBeforeAction/ActionButton";
import useTeam from "../../contexts/team/useTeam";
import TeamsService from "../../../services/TeamsService";
import useSession from "../../contexts/session/useSession";
import {useSnackbar} from "notistack";
import useLoading from "../../contexts/loading/useLoading";
import {AMELIORATION_CARACTERISTIQUE, DE_ALEATOIRE} from "../PlayerLevelUp/PlayerLevelUpForm";
import PlayerCaracteristiques from "../PlayerCaracteristiques/PlayerCaracteristiques";
import PlayerChooseClaimableCaracteristique
  from "../PlayerChooseClaimableCaracteristique/PlayerChooseClaimableCaracteristique";
import PlayerChooseClaimableSkill from "../PlayerChooseClaimableSkill/PlayerChooseClaimableSkill";

export default function PlayerEditionButton(props) {
  const {
    player = null,
  } = props

  const {team, refreshTeam} = useTeam();
  const {currentSession} = useSession();
  const {enqueueSnackbar} = useSnackbar();
  const {showLoading, hideLoading} = useLoading();


  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openAmelioration, setOpenAmelioration] = React.useState(false);
  const [openChooseCaracteristique, setOpenChooseCaracteristique] = React.useState(false);
  const [openChooseSkill, setOpenChooseSkill] = React.useState(false);
  const [claimableCode, setClaimableCode] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickAddAmelioration = () => {
    setAnchorEl(null);
    setOpenAmelioration(true);
  }

  const handleCloseAmelioration = () => {
    setOpenAmelioration(false);
  }

  const handleRevokePlayer = () => {
    showLoading("Révocation " + (player.name?"de" + player.name:"du joueur") + " #" + player.number + " (" + player.positionalName + ") en cours")
    TeamsService.revokePlayer(team.id, player.id, currentSession.accessToken).then(() => {
      enqueueSnackbar("Joueur [" + player.positionalName + "] révoqué de l'équipe " + team.name + " !", {variant: "success"});
      hideLoading()
      refreshTeam();
    }).catch((error) => {
      hideLoading()
      enqueueSnackbar("Erreur lors de la révocation du joueur [" + player.positionalName + "] ! " + error.message, {variant: "error"});
    })
  }

  const handleOnBuyAmelioration = (type,de, caracteristique, competence) => {
    if(type.code === AMELIORATION_CARACTERISTIQUE){
      showLoading("Achat d'une caractéristique pour " + (player.name?player.name:"le joueur ") + " #" + player.number + " (" + player.positionalName + ") en cours")
      TeamsService.buyCaracteristique(team.id, player.id, caracteristique, de.serverRandom, currentSession.accessToken).then((buyResponse)=>{
        hideLoading()
        setOpenAmelioration(false)
        if(buyResponse.claimed){
          if(buyResponse.approved)
            enqueueSnackbar("Caractéristique [" + caracteristique.name + "] achetée pour " + (player.name?player.name:"le joueur ") + " #" + player.number + " (" + player.positionalName + ")!", {variant: "success"});
          else {
            enqueueSnackbar("Votre demande d'ajout de caractéristique [" + caracteristique.name + "] achetée pour " + (player.name?player.name:"le joueur ") + " #" + player.number + " (" + player.positionalName + ") mais doit etre approuvée par l'administrateur!", {variant: "warning"});
          }
          refreshTeam();
        } else {
          setClaimableCode(buyResponse.approbationId)
          setOpenChooseCaracteristique(true);
        }
      }).catch((error)=>{
        hideLoading()
        enqueueSnackbar("Erreur de l'achat de la caractéristique [" + (caracteristique?caracteristique.name:"") + "]  pour " + (player.name?player.name:"le joueur ") + " #" + player.number + " (" + player.positionalName + ")! "+ error.message, {variant: "error"});
      });
    }else{
      showLoading("Achat d'une competence pour " + (player.name?player.name:"le joueur ") + " #" + player.number + " (" + player.positionalName + ") en cours")
      TeamsService.buyCompetence(team.id,player.id, competence, de.random, de.serverRandom, type, currentSession.accessToken).then((buyResponse)=>{
        hideLoading()
        setOpenAmelioration(false)
        if(buyResponse.claimed){
          if(buyResponse.approved)
            enqueueSnackbar("Competence [" + competence.name + "] achetée pour " + (player.name?player.name:"le joueur ") + " #" + player.number + " (" + player.positionalName + ")!", {variant: "success"});
          else {
            enqueueSnackbar("Votre demande d'ajout de competence [" + competence.name + "] achetée pour " + (player.name?player.name:"le joueur ") + " #" + player.number + " (" + player.positionalName + ") mais doit etre approuvée par l'administrateur!", {variant: "warning"});
          }
          refreshTeam();
        } else {
          setClaimableCode(buyResponse.approbationId)
          setOpenChooseSkill(true);
        }
      }).catch((error)=>{
        hideLoading()
        // enqueueSnackbar("Erreur de l'achat de la compétence [" + !random?competence.name:"" + "]  pour " + (player.name?player.name:"le joueur ") + " #" + player.number + " (" + player.positionalName + ")! "+ error.message, {variant: "error"});
        enqueueSnackbar("Erreur de l'achat de la compétence! "+ error.message, {variant: "error"});
      });
    }
  }

  const handleCloseChooseCaracteristique = ()=>{
    setOpenChooseCaracteristique(false);
    refreshTeam();
  }

  const handleCloseChooseSkill = ()=>{
    setOpenChooseSkill(false);
    refreshTeam();
  }

  const open = Boolean(anchorEl);

  return (
    <PlayerEditionButtonRoot className={"bbsa-player-edition-button-root"}>
      <PlayerEditionButtonStyled size={"small"} className={"bbsa-player-edition-button"} onClick={handleClick}>
        <MoreHorizIcon/>
      </PlayerEditionButtonStyled>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <PlayerEditionButtonOptionsContent>
          <Stack>

            <Button
                variant={"text"}
                color={"secondary"}
                onClick={handleClickAddAmelioration}
                disabled={player.waitingImprovement.length>0}
            >
              Améliorer
            </Button>
            <ActionButton
              variant={"text"}
              actionLabel={"Révoquer"}
              elementToBuy={"#" + player.number + " " + player.positionalName}
              title={"Confirmation de révocation"}
              onClose={handleClose}
              onDoAction={handleRevokePlayer}
            />

          </Stack>
        </PlayerEditionButtonOptionsContent>
      </Popover>
      <PlayerLevelUpDialog
          open={openAmelioration}
          onClose={handleCloseAmelioration}
          player={player}
          team={team}
          onBuyAmelioration={handleOnBuyAmelioration}
      />
      <PlayerChooseClaimableCaracteristique
        open={openChooseCaracteristique}
        onClose={handleCloseChooseCaracteristique}
        onCaracteristiqueClaim={()=>{
          refreshTeam();
        }}
        player={player}
        team={team}
        claimableCode={claimableCode}
        />
      <PlayerChooseClaimableSkill
          open={openChooseSkill}
          onClose={handleCloseChooseSkill}
          onSkillClaim={()=>{
            refreshTeam();
          }}
          player={player}
          team={team}
          claimableCode={claimableCode}
      />
    </PlayerEditionButtonRoot>
  )
}


const PlayerEditionButtonRoot = styled(Box)(({theme}) => ({
  position: "absolute",
  top: 0,
  right: 0,
  padding: "10px"
}))

const PlayerEditionButtonStyled = styled(Fab)(({theme}) => ({}))

const PlayerEditionButtonOptionsContent = styled(Paper)(({theme}) => ({
  padding: 5,
}))
