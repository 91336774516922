import {Box, styled, Typography} from "@mui/material";
import {useEffect, useState} from "react";

function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export default function DiceRoll(props){
  const {
    type = "D6",
    value = 0,
    onRollFinish=()=>{},
    onRollStart=()=>{}
  } = props;

  const [count, setCount] = useState(0);
  const [randomV, setRandomV] = useState(1)
  const [display, setDisplay] = useState("none")


  useEffect(() => {
    if(count===0){
      onRollStart();
    }
    setTimeout(()=>{
      if (count < 100) {
        switch (type) {
          case "D6":
            setRandomV(Math.floor(Math.random() * 6) + 1)
            break;
          case "D8":
            setRandomV(Math.floor(Math.random() * 8) + 1)
            break;
          case "D16":
            setRandomV(Math.floor(Math.random() * 16) + 1)
            break;
        }
        setCount(count + 1)
      } else {
        setRandomV(value);
        setDisplay("flex");
        onRollFinish();
      }
    }, count)
  }, [count])

  return (
    <DiceRollRoot className={"bbsa-dice-roll-root"}>
      <Typography>{randomV}</Typography>
      <DiceRollAnimation display={display}>
        <FinalNumber>{value}</FinalNumber>
      </DiceRollAnimation>
    </DiceRollRoot>
  )
}


const DiceRollRoot = styled(Box)(({theme}) => ({
  border : "3px black solid",
  justifyContent: "center",
  display: "flex",
  flexWrap: "wrap",
  alignContent: "center",
  width : 48,
  height : 48,
  borderRadius : 10,
  [theme.breakpoints.down("sm")]: {
  }
}))

const DiceRollAnimation = styled(Box)(({display, theme}) => ({
  justifyContent: "center",
  display: display,
  position : 'absolute',
  flexWrap: "wrap",
  alignContent: "center",
  width : 48,
  height : 48,
  borderRadius : 10,
  [theme.breakpoints.down("sm")]: {
  }
}))

const FinalNumber = styled(Typography)(({theme}) => ({
  animation: "diceSelectedUp 0.8s ease-in-out"
}))
