import React, {useState} from "react";
import {FormControl, Stack, styled, TextField, Typography} from "@mui/material";

function BBSAInputField(props) {
  const {
    defaultValue
    , label
    , onChange = (val) => {
    }
    , fullWidth = true
    , type = 'input'
  } = props;

  const [value, setValue] = useState(defaultValue);
  const handleChangeValue = (e) => {
    setValue(e.target.value);
    onChange(e.target.value);
  }

  return (
    <BBSAInputFieldRoot fullWidth={fullWidth} className={"bbsa-inputfield-root"}>
      <Stack>
        {/*<BBSAInputFieldLabel className={'bbsa-inputfield-label'} variant={"div"}*/}
        {/*                     align={'left'}>{label}</BBSAInputFieldLabel>*/}
        <TextField
            label={label}
          size={'small'}
          id="nom"
          value={value}
          type={type}
          fullWidth={fullWidth}
          onChange={handleChangeValue}
        />
      </Stack>
    </BBSAInputFieldRoot>
  )
}

const BBSAInputFieldRoot = styled(FormControl)(({theme}) => ({
  paddingTop : 10,
  fontFamily: theme.typography.fontFamily,
  [theme.breakpoints.down("sm")]: {}
}))

const BBSAInputFieldLabel = styled(Typography)(({theme}) => ({
  [theme.breakpoints.down("sm")]: {}
}))


export default BBSAInputField;
