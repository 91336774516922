import React from "react";
import SkillChip from "../SkillChip/SkillChip";

function PlayerSkills(props) {
  const {skills, color} = props;
  return (
    <React.Fragment>
      {skills.map((skill) => <SkillChip key={"skill-" + skill.id} color={color} skill={skill}/>)}

    </React.Fragment>
  )
}

export default PlayerSkills;
