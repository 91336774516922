import CommonService from "./CommonService";

class CaracteristicImprovementTypeService extends CommonService{
    async list() {
        return this.request({
            url: process.env.REACT_APP_API_URL + "/characteristic_improvement_types",
            method: 'GET'
        })
    }
}

export default new CaracteristicImprovementTypeService();


