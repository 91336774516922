import React, {Fragment} from "react";
import {Typography} from "@mui/material";

export default function NoPage(prop) {
  return (
    <Fragment>
      <Typography align={"center"}>OUPS 404</Typography>
    </Fragment>
  )
}
