import {DaySelector} from "../components/DaySelector";
import {Box, Container, Divider, Grid, Paper, Tab, Tabs} from "@mui/material";
import {styled} from "@mui/material/styles";
import {Fragment, useEffect, useState} from "react";
import GameCard from "../components/GameCard/GameCard";
import {PhasesService} from "../services";
import {useSeason} from "../components/Season";
import Rank from "../components/Rank/Rank";
import SeasonService from "../services/SeasonService";
import NewsCard from "../components/NewsCard/NewsCard";

export default function HomePage(props) {
  const {season} = useSeason();

  const [day, setDay] = useState(null);
  const [ranks, setRanks] = useState(null);
  const [recentsNews, setRecentsNews] = useState(null);

  const handleChangeDay = (day) => {
    if (day.id === undefined) {
      setDay(null);
    }
    setDay(day);
  }

  useEffect(() => {
    if (ranks === null) {
      PhasesService.getPhasesFromSeason(season.id).then((phases) => {
        let championshipPhases = phases.filter(phase => phase.phaseType === 'CHAMPIONSHIP' && phase.progressStatus === 'IN_PROGRESS');
        if (championshipPhases.length === 0) {
          setRanks([]);
        }
        PhasesService.getPhase(championshipPhases[0].id).then((pools) => {
          setRanks(pools.filter((pool) => !pool.interPool));
        })
      })
    }
  }, [ranks, season])

  useEffect(() => {
    if (recentsNews === null) {
      SeasonService.getLastNews(season.id).then((s) => {
        setRecentsNews(s);
      })
    }
  }, [recentsNews])

  return (
    <Container maxWidth={"lg"}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <DayPaper>
            <Grid container>
              <Grid item xs={12} md={1} sx={{display: 'flex', alignItems: "center"}}>
                <DaySelector onChange={handleChangeDay}/>
              </Grid>
              <Grid item xs={12} md={11}>
                <TabsStyled
                  value={0}
                  variant="scrollable"
                  scrollButtons
                  allowScrollButtonsMobile
                  indicatorColor="transparent"
                >
                  {day && day.games.map((game, i) => {
                    return (
                      <Fragment>
                        <GameCard key={game.id} gameId={game.id}/>
                        <TabDivider label="" icon={<Divider orientation="vertical"/>} disabled/>
                      </Fragment>
                    )
                  })}
                </TabsStyled>
              </Grid>
            </Grid>
          </DayPaper>
        </Grid>
        <Grid item xs={12} md={9}>
          <Box>
            <Grid container spacing={3}>
              {ranks && ranks.filter(r => r.id===3 || r.id===2).map((rank) =>
                <Grid item xs={12}>
                  <Rank key={rank.id} rankId={rank.id}/>
                </Grid>
              )}
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={12} md={3}>
          <Box>
            <Grid container spacing={3}>
              {recentsNews && recentsNews.map((news) =>
                <Grid item xs={12}>
                  <NewsCard newsId={news.id}/>
                </Grid>
              )}
            </Grid>

          </Box>
        </Grid>
      </Grid>
    </Container>
  )
}

const DayPaper = styled(Paper)(({theme}) => ({
  padding: "15px 15px",
  alignContent: "center",
  [theme.breakpoints.down("sm")]: {}
}));

const TabsStyled = styled(Tabs)(({theme}) => ({
  [theme.breakpoints.down("md")]: {},
}));

const TabDivider = styled(Tab)(({theme}) => ({
  minWidth: 0,
  padding: "0px 16px",
  [theme.breakpoints.down("md")]: {},
}));



