import React, {Fragment, useEffect, useState} from "react";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  styled,
  Tooltip,
  Typography
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import TeamsService from "../../../services/TeamsService";
import useSession from "../../contexts/session/useSession";
import useLoading from "../../contexts/loading/useLoading";
import {useSnackbar} from "notistack";
import DiceRoll from "../DiceRoll/DiceRoll";
import SearchIcon from "@mui/icons-material/Search";

const sxButton = {
  mb: "5px"
}


export default function PlayerChooseClaimableSkill(props) {
  const {currentSession} = useSession()
  const {enqueueSnackbar} = useSnackbar();
  const {showLoading, hideLoading} = useLoading();
  const [diceRoll1Finished, setDiceRoll1Finished] = useState(false);
  const [diceRoll2Finished, setDiceRoll2Finished] = useState(false);
  const [approved, setApproved] = useState(true);

  const handleFinishDiceRoll1 = ()=>{
    setDiceRoll1Finished(true);
  }

  const handleStartDiceRoll1 = ()=>{
    setDiceRoll1Finished(false);
  }

  const handleStartDiceRoll2 = ()=>{
    setDiceRoll2Finished(false);
  }

  const handleFinishDiceRoll2 = ()=>{
    setDiceRoll2Finished(true);
  }


  const {
    open = false,
    onClose = () => {
    },
    onSkillClaim = () => {
    },
    player = null,
    team = null,
    claimableCode = null
  } = props;

  const [waitingImprovement, setWaitingImprovement] = useState(null);

  const onClaimSkill = (skill, code) => {
    showLoading("Réclamation d'une competence pour " + (player.name ? player.name : "le joueur ") + " #" + player.number + " (" + player.positionalName + ") en cours")
    TeamsService.claimSkill(code, skill, team.id, player.id, currentSession.accessToken).then((response) => {
      enqueueSnackbar("Compétence [" + skill.name + "] réclamée pour " + (player.name ? player.name : "le joueur ") + " #" + player.number + " (" + player.positionalName + ")!", {variant: "success"});
      onSkillClaim(skill);
      handleClose();
    }).catch((error) => {
      hideLoading()
      enqueueSnackbar("Erreur de l'achat de la competence [" + skill.name + "]  pour " + (player.name ? player.name : "le joueur ") + " #" + player.number + " (" + player.positionalName + ")! " + error.message, {variant: "error"});
    })
  }

  const handleClose = () => {
    onClose();
  }

  useEffect(() => {
    if (claimableCode && team && player && waitingImprovement===null) {
      showLoading();
      TeamsService.getWaitingImprovement(team.id, player.id, claimableCode, currentSession.accessToken).then((wi) => {
        hideLoading()
        if(wi.dice1Type===null){
          setDiceRoll1Finished(true);
        }
        if(wi.dice2Type===null){
          setDiceRoll2Finished(true);
        }
        setApproved(wi.approved);
        setWaitingImprovement(wi)
      }).catch((error) => {
        hideLoading();
      })
    }

  }, [claimableCode, team, player, waitingImprovement])

  if (claimableCode === null || player === null || team === null || waitingImprovement === null) {
    return (
      <Fragment/>
    )
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      scroll={'paper'}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <DialogTitle id="scroll-dialog-title" align={"right"}>
        <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
        >
          <Typography>selectionnez votre amélioration</Typography>
          <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={handleClose}
          >
            <CloseIcon/>
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent>
        {!approved &&
            <Typography>Votre demande d'amélioration de competence <Typography component={"span"} color={"primary"}>{waitingImprovement.claimedSkill?waitingImprovement.claimedSkill.name:""}</Typography> doit etre approuvée par l'adiminstrateur</Typography>
        }
        {approved &&
            <Fragment>
              <Stack
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={2}
              >
                <DiceRoll
                    type={waitingImprovement.dice1Type}
                    value={waitingImprovement.dice1Value}
                    onRollStart={handleStartDiceRoll1}
                    onRollFinish={handleFinishDiceRoll1}
                />
                <DiceRoll
                    type={waitingImprovement.dice2Type}
                    value={waitingImprovement.dice2Value}
                    onRollStart={handleStartDiceRoll2}
                    onRollFinish={handleFinishDiceRoll2}
                />
              </Stack>
              {diceRoll1Finished && diceRoll2Finished &&
                  <Box sx={{pt:"10px"}}>
                    {waitingImprovement.claimableSkills.map(c => {
                      let disabled = false;
                      if(player.positionalSkills.filter(ps => ps.skillId === c.id).length>0){
                        disabled = true;
                      } else if(player.ownedSkills.filter(ps => ps.skillId === c.id).length>0){
                        disabled = true;
                      }


                      return (
                        <Stack
                          direction="row"
                          justifyContent="center"
                          alignItems="center"
                          spacing={2}
                        >

                        <Button disabled={disabled} fullWidth color={"secondary"} variant={"contained"} sx={sxButton}
                                      onClick={() => onClaimSkill(c, claimableCode)}>{c.name}</Button>
                        <AmeliorationDetail skill={c}/>
                        </Stack>
                          )
                    })}
                  </Box>
              }
            </Fragment>
        }
      </DialogContent>
    </Dialog>
  )
}

const AmeliorationDetail = (props) => {
  const {skill} = props;
  const [openTT, setOpenTT] = useState(false);

  const handleChipClick = () => {
    setOpenTT(!openTT);
  }

  const handleTooltipClose = () => {
    setOpenTT(false);
  }

  return (
    <Tooltip
      PopperProps={{
        disablePortal: false,
      }}
      onClose={handleTooltipClose}
      open={openTT}
      disableFocusListener
      disableHoverListener
      disableTouchListener
      title={<TooltipContent>{skill.description}</TooltipContent>}
      arrow
    >

      <IconButton aria-label="detail" size="small" onClick={handleChipClick}>
        <SearchIcon fontSize="small"/>
      </IconButton>
    </Tooltip>

  )
}

const TooltipContent = styled(Typography)(({theme}) => ({
  fontSize: '1rem',
  [theme.breakpoints.down("sm")]: {
    fontSize: "0.6875rem",
  }
}))
