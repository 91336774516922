import {styled, Tabs} from "@mui/material";
import React from "react";

interface
StyledTabsProps
{
  children ? : React.ReactNode;
  value: number;
  onChange: (event: React.SyntheticEvent, newValue: number) => void;
}


const StyledTabs = styled((props: StyledTabsProps) => (
  <Tabs
    {...props}
    TabIndicatorProps={{children: <span className="MuiTabs-indicatorSpan"/>}}
  />
))(({theme}) => ({
  '& .MuiTabs-indicator': {
    display: 'none',
  },
  '& .MuiButtonBase-root': {
    color: theme.palette.text.primary
  },
  '& .Mui-selected': {
    background: (theme.palette.primary.gradient ? theme.palette.primary.gradient : theme.palette.primary.main)
  }
}));

export default function InfoTabs(props) {
  return (
    <StyledTabs
      className={"bbsa-info-tabs-root"}
      {...props}
      TabIndicatorProps={{children: <span className="MuiTabs-indicatorSpan"/>}}
    />
  )
}
