import React, {Fragment, useEffect, useState} from "react";
import {Box, Button, StepContent, StepLabel, Typography} from "@mui/material";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import UpgradeCostsService from "../../../services/UpgradeCostsService";
import ActionButton from "../ValidationBeforeAction/ActionButton";
import PlayerLevelUpCompetenceType from "./PlayerLevelUpCompetenceType";
import PlayerLevelUpCompetence from "./PlayerLevelUpCompetence";
import CaracteristicImprovementTypeService from "../../../services/CaracteristicImprovementTypeService";
import TeamsService from "../../../services/TeamsService";
import useSession from "../../contexts/session/useSession";


export const DE_ALEATOIRE = 'A';
export const DE_CHOISI = 'N';
export const AMELIORATION_CARACTERISTIQUE = 'C';
export const AMELIORATION_COMPETENCE_PRINCIPALE = 'P';
export const AMELIORATION_COMPETENCE_SECONDAIRE = 'S';

function getPSPCost(type, diceType, rank) {
   if (rank === null || type === null || diceType === null || rank === null) {
    return -1;
  }
  switch (type.code) {
    case AMELIORATION_COMPETENCE_PRINCIPALE :
      switch (diceType.code) {
        case DE_ALEATOIRE :
          return rank.randomPrimarySkillCost;
        default :
          return rank.chosenPrimarySkillCost;
      }
      break;
    case AMELIORATION_COMPETENCE_SECONDAIRE :
      switch (diceType.code) {
        case DE_ALEATOIRE :
          return rank.randomSecondarySkillCost;
        default :
          return rank.chosenSecondarySkillCost;
      }
      break;
    case AMELIORATION_CARACTERISTIQUE :
      return rank.randomCharacteristicImprovementCost;
      break;
    default :
      return -1
  }
}

const ameliorationType = [
  {
    code: AMELIORATION_COMPETENCE_PRINCIPALE,
    label: "Competence principale",
    shortlabel: "Competence principale"
  },
  {
    code: AMELIORATION_COMPETENCE_SECONDAIRE,
    label: "Competence secondaire",
    shortlabel: "Competence secondaire"
  },
  {
    code: AMELIORATION_CARACTERISTIQUE,
    label: "Caractéristique",
    shortlabel: "Caractéristique"
  },
]

const ameliorationDe = [
  {
    code: DE_ALEATOIRE,
    approbation: true,
    // label: "Aléatoire (Approb admin nécéssaire)",
    label: "Aléatoire",
    shortlabel: "Aléatoire (manuel)",
    random:true,
    serverRandom : false,
  },
  {
      code: DE_ALEATOIRE,
      approbation: false,
      label: "Aléatoire (jet automatique)",
      shortlabel: "Aléatoire (auto)",
      random:true,
      serverRandom : true,
  },
  {
    code: DE_CHOISI,
    approbation: false,
    label: "Pas de jet de dé",
    shortlabel: "Non",
    random:false,
    serverRandom: false
  }
]


const sxButton = {
  mb: "5px"
}

export default function PlayerLevelUpForm(props) {
  const {
    player,
    team,
    onBuyAmelioration=()=>{},

  } = props;

  const {currentSession} = useSession();

  const [activeStep, setActiveStep] = React.useState(0);
  const [type, setType] = React.useState(null);
  const [de, setDe] = React.useState(null);
  const [competenceType, setCompetenceType] = React.useState(null);
  const [competence, setCompetence] = React.useState(null);
  const [caracteristique, setCaracteristique] = React.useState(null);
  const [playerRankCosts, setPlayerRankCosts] = useState(null);

  const handleNext = (type, dice) => {
    let nextActiveStep = activeStep + 1;
    if (nextActiveStep === 2 && dice && dice.code === DE_ALEATOIRE && !dice.approbation) {
      if(type.code === 'C'){
        nextActiveStep += 1;
      } else {
        nextActiveStep += 2;
      }
    }
    setActiveStep(nextActiveStep);
  };

  const handleBack = (type, dice) => {
    let prevActiveStep = activeStep - 1;
    if (((prevActiveStep === 2 && type.code==='C') || (prevActiveStep === 3 && type.code!=='C')) && dice && dice.code === DE_ALEATOIRE && !dice.approbation) {
      if(type.code === 'C'){
        prevActiveStep -= 1;
      } else {
        prevActiveStep -= 2;
      }
    }

    setActiveStep(prevActiveStep);
  };

  const handleTypeAmelioration = (type) => {
    setType(type);
    handleNext(type);
  }

  const handleDeAmelioration = (de) => {
    setDe(de);
    handleNext(type, de);
  }

  const handleCompetenceType = (competenceType) => {
    setCompetenceType(competenceType);
    handleNext();
  }

  const handleCompetence = (competence) => {
    setCompetence(competence);
    handleNext();
  }

  const handleCaracteristiqueAmelioration = (caracteristique) => {
    setCaracteristique(caracteristique);
    handleNext();
  }

  const handleDoBackDe = () => {
    setType(null);
    handleBack();
  }

  const handleDoBackCompetenceCaracteristiqueType = () => {
    setDe(null);
    handleBack(type);
  }

  const handleDoBackCompetenceCaracteristique = () => {
    setCompetenceType(null);
    handleBack(type);
  }

  const handleDoBackResume = () => {
    handleBack(type, de);
  }

  const handleAddCompetence = () => {
    // onBuyAmelioration(type, de.serverRandom, caracteristique, competence)
    onBuyAmelioration(type, de, caracteristique, competence)
  }


  useEffect(() => {
    if (playerRankCosts === null) {
      UpgradeCostsService.list().then((upgradeCosts) => {
        let playerRankNumber = player.ownedSkills.length;
        let rankCosts = upgradeCosts.filter(uc => uc.nbPlayerUpgrade === playerRankNumber)[0];
        setPlayerRankCosts(rankCosts);
      })
    }
  }, [playerRankCosts]);

  useEffect(() => {
    if (activeStep === 1) {
      setDe(null);
    }
  }, [activeStep]);


  if (playerRankCosts === null) {
    return (
      <Typography>Chargement</Typography>
    )
  }


  return (
    <Box sx={{maxWidth: 400}}>
      <Stepper activeStep={activeStep} orientation="vertical">
        <Step key={"type"}>
          <StepLabel
            optional={type !== null ? type.shortlabel : null}
          >
            Type d'amélioration
          </StepLabel>
          <StepContent>
            <StepTypeAmelioration
              onSelected={handleTypeAmelioration}
            />
          </StepContent>
        </Step>
        <Step key={"de"}>
          <StepLabel
            optional={de !== null ? de.shortlabel : null}
          >
            Jet de dé
          </StepLabel>
          <StepContent>
            <StepDeAmelioration
              type={type}
              onSelected={handleDeAmelioration}
              doBack={handleDoBackDe}
              ameliorationType={type}
              playerRank={playerRankCosts}
            />
          </StepContent>
        </Step>
        {(type && type.code !== 'C' && de) &&
          <Step key={"compétenceType"}>
            <StepLabel
              optional={de?(competenceType !== null ? competenceType.name : (de.serverRandom?"Choisie après l'achat":"")) : ""}
            >
              <Fragment>
                Type de compétence
              </Fragment>
            </StepLabel>
            <StepContent>
              {(type && type.code !== 'C') &&
                <StepCompetenceType
                  competenceType={type.code}
                  positionalId={player.positionalId}
                  onSelected={handleCompetenceType}
                  doBack={handleDoBackCompetenceCaracteristiqueType}
                />
              }
            </StepContent>
          </Step>
        }
        {(type && type.code !== 'C' && de) &&
          <Step key={"compétence"}>
            <StepLabel
              optional={competence ? (type.code !== 'C' ? competence.name : "") : "Choisie après l'achat"}
            >
              <Fragment>
                Compétence
              </Fragment>
            </StepLabel>
            <StepContent>
              {(type && type.code !== 'C') &&
                <StepCompetence
                  competenceType={competenceType}
                  player={player}
                  dice={de}
                  onSelected={handleCompetence}
                  doBack={handleDoBackCompetenceCaracteristique}
                />
              }
            </StepContent>
          </Step>
        }
        {(type && type.code === 'C' && de) &&
          <Step key={'caracteristique'}>
            <StepLabel
              optional={caracteristique ? caracteristique.name : "Choisie après l'achat"}
            >
              Caractéristique
            </StepLabel>
            <StepContent>
              <StepCaracteristique
                onSelected={handleCaracteristiqueAmelioration}
                doBack={handleDoBackCompetenceCaracteristique}
              />
            </StepContent>
          </Step>
        }

        <Step key={"Résumé"}>
          <StepLabel>
            Validation achat
          </StepLabel>
          <StepContent>
            <StepResume
              type={type}
              de={de}
              competence={competence}
              playerRank={playerRankCosts}
              caracteristique={caracteristique}
              doBack={handleDoBackResume}
              onAddCompetence={handleAddCompetence}
            />
          </StepContent>
        </Step>
      </Stepper>
    </Box>
  )
}


const StepTypeAmelioration = (props) => {
  const {
    onSelected = () => {
    },
  } = props;
  return (
    <Box>
      {ameliorationType.map(a =>
        <Button fullWidth color={"secondary"} variant={"contained"} sx={sxButton}
                onClick={() => onSelected(a)}>{a.label}</Button>
      )}
    </Box>
  )
}

const StepDeAmelioration = (props) => {
  const {
    type,
    ameliorationType = {code: 'NONE'},
    playerRank = null,
  } = props
  const {
    onSelected = () => {
    },
    doBack = () => {
    }
  } = props;
  return (
    <Box>
      {ameliorationDe
        .filter(a => {
            return (type !== null && ((type.code === AMELIORATION_CARACTERISTIQUE && a.code === DE_ALEATOIRE) || type.code !== AMELIORATION_CARACTERISTIQUE));
          }
        )
        .map(a =>
          <Button
            fullWidth
            color={"secondary"}
            variant={"contained"}
            sx={sxButton}
            onClick={() => onSelected(a)}
          >
            {a.label} ({getPSPCost(ameliorationType, a, playerRank)} PSP)
          </Button>
        )}
      <Button
        onClick={doBack}
        variant={'outlined'}
        sx={{mr: 1}}
      >
        Précédent
      </Button>
    </Box>
  )
}

const StepCompetenceType = (props) => {
  const {
    competenceType = null,
    positionalId = null,
    onSelected = () => {
    },
    doBack = () => {
    }
  } = props;
  return (
    <Box>
      <PlayerLevelUpCompetenceType
        competenceType={competenceType}
        positionalId={positionalId}
        onSelected={onSelected}
      />
      <Button
        onClick={doBack}
        variant={'outlined'}
        sx={{mr: 1}}
      >
        Précédent
      </Button>
    </Box>
  )
}

const StepCompetence = (props) => {
  const {
    competenceType = null,
    player,
    dice,
    onSelected = () => {
    },
    doBack = () => {
    }
  } = props;
  return (
    <Box>
      <PlayerLevelUpCompetence
        dice={dice}
        player={player}
        competenceType={competenceType}
        onSelected={onSelected}
      />
      <Button
        onClick={doBack}
        variant={'outlined'}
        sx={{mr: 1}}
      >
        Précédent
      </Button>
    </Box>
  )
}

const StepCaracteristique = (props) => {
  const {
    onSelected = () => {
    },
    doBack = () => {
    }
  } = props;

  const [caracteristiqueType, setCaracteristiqueType] = useState(null);

  useEffect(()=>{
    if(caracteristiqueType===null){
      CaracteristicImprovementTypeService.list().then((l)=>{
        setCaracteristiqueType(l);
      });
    }
  }, [caracteristiqueType])

  if(caracteristiqueType===null){
    return (
      <Fragment>

      </Fragment>
    )
  }

  return (
    <Box>
      {caracteristiqueType.map(a =>
        <Button fullWidth color={"secondary"} variant={"contained"} sx={sxButton}
                onClick={() => onSelected(a)}>{a.name}</Button>
      )}
      <Button
        onClick={doBack}
        variant={'outlined'}
        sx={{mr: 1}}
      >
        Précédent
      </Button>
    </Box>
  )
}

const StepResume = (props) => {
  const {
    type,
    de,
    competence,
    caracteristique,
    playerRank,
    onAddCompetence = () => {
    },
    doBack = () => {
    }
  } = props;

  const [label, setLabel] = useState("")




  useEffect(()=>{
    let l = "";

    //type.code === AMELIORATION_CARACTERISTIQUE ? (de?(!de.approbation?  : caracteristique.name) : competence?competence.name: "Competence (la compétence sera sélectionnée apres l'achat)"):""

    if(type && type.code===AMELIORATION_CARACTERISTIQUE){
      if(de && de.serverRandom){
         l = "Caractéristique (la caractéristique sera sélectionnée apres l'achat)";
      } else {
         l = caracteristique.name
      }
    } else if(type && (type.code===AMELIORATION_COMPETENCE_PRINCIPALE || type.code===AMELIORATION_COMPETENCE_SECONDAIRE)){
      if(de && de.serverRandom){
        l = "Compétence (la compétence sera sélectionnée apres l'achat)";
      } else {
        l = competence.name
      }
    }

    setLabel(l);
  },[type, de, competence, caracteristique])


  return (
    <Box>
      <Typography>coût : {getPSPCost(type, de, playerRank)} PSP</Typography>
      <Button
        onClick={doBack}
        variant={'outlined'}
        sx={{mr: 1}}
      >
        Précédent
      </Button>
      <ActionButton
        actionLabel={"Acheter"}
        elementToBuy={label}
        onDoAction={onAddCompetence}
        title={"Validation d'achat"}
      />
    </Box>
  )
}
