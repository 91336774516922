import {useContext} from "react";
import {CurrentLoadingContext} from "./LoadingContext";


export default function useLoading(){
    const {loadingState, setLoadingState} = useContext(CurrentLoadingContext);

    function showLoading(text="", callback=()=>{}){
        setLoadingState({
            show : true,
            text : text,
        });
        callback();
    }

    function hideLoading(){
        setLoadingState({
            show : false,
            text : '',
        });
    }

    function isLoading(){
        return loadingState.show;
    }

    return {showLoading, hideLoading, isLoading};
}
