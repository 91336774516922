import React from "react";
import {Box, Container, Divider, Grid, Paper, Stack, styled, Typography} from "@mui/material";
import PlayerCaracteristiques from "../PlayerCaracteristiques/PlayerCaracteristiques";
import PlayerSkills from "../PlayerSkills/PlayerSkills";
import PlayerStatus from "../PlayerStatus/PlayerStatus";
import useTeam from "../../contexts/team/useTeam";
import PlayerEditionButton from "../PlayerEditionButton/PlayerEditionButton"
import SwitchAccessShortcutIcon from '@mui/icons-material/SwitchAccessShortcut';
import PlayerLevelUpCompetenceType from "../PlayerLevelUp/PlayerLevelUpCompetenceType";
import PlayerChooseClaimableCaracteristique
  from "../PlayerChooseClaimableCaracteristique/PlayerChooseClaimableCaracteristique";
import PlayerChooseClaimableSkill from "../PlayerChooseClaimableSkill/PlayerChooseClaimableSkill";

const TeamDetailsCardsStyled = styled(Paper)(({theme}) => ({
  [".bbsa-team-details-cards-root.filled"]: {
    color: theme.palette.text.primary,
    fontFamily: theme.typography.fontFamily
  },

  [".bbsa-player-card-root.dead"]: {
    backgroundColor: "rgb(103, 103, 103)",
    color: theme.palette.text.primary
  },
  [".bbsa-player-card-root.rpm"]: {
    backgroundColor: "rgb(136, 136, 136)",
    color: theme.palette.text.disabled
  },

}))


export default function TeamDetailsCards(props) {
  const {team, isMyTeam} = useTeam();
  const {variant = "filled"} = props;


  return (
    <TeamDetailsCardsStyled>
      <Box className={"bbsa-team-details-cards-root " + variant}>
        {team.players.filter(p => p.active).map((player) => <PlayerCard team={team} player={player} editable={isMyTeam}/>)}
      </Box>
    </TeamDetailsCardsStyled>
  )
}


const PlayerCardStyled = styled(Box)(({theme}) => ({
  position: "relative",
  ['.bbsa-player-card-name, .bbsa-player-card-number']: {
    fontWeight: 'bolder',
  },
  ['.bbsa-player-card-posname']: {
    fontSize: '0.8rem',
  },
  [theme.breakpoints.down("sm")]: {}
}))

function PlayerCard(props) {
  const {refreshTeam} = useTeam();
  const {
    player,
    editable = false,
    team,
  } = props;

  const [openChooseCaracteristique, setOpenChooseCaracteristique] = React.useState(false);
  const [openChooseSkill, setOpenChooseSkill] = React.useState(false);
  const [claimableCode, setClaimableCode] = React.useState(null);

  var dead = false
  var rpm = false
  var bp = false
  var bpNumber = 0;


  player.injuries.filter(i => (i.active === true && i.injuryTypeCode === 'DEAD')).map(i => dead = true);

  player.injuries.filter(i => (i.active === true && i.injuryTypeCode === 'MissNextGame')).map(i => rpm = true);

  player.injuries.filter(i => (i.active === true && i.injuryTypeCode === 'BP')).map(i => {
    bp = true;
    bpNumber++;
  });

  const handleLevelUp = () => {
    if(player.waitingImprovement[0].improvementType === 'SKILL'){
      setOpenChooseSkill(true);
    } else if(player.waitingImprovement[0].improvementType === 'CHARACTERISTIC'){
      setOpenChooseCaracteristique(true);
    }
    setClaimableCode(player.waitingImprovement[0].code);
  }

  const handleCloseChooseCaracteristique = () => {
    setOpenChooseCaracteristique(false);
  }

  const handleCloseChooseSkill = () => {
    setOpenChooseSkill(false);
  }

  const handleClaimCaracteristique = () => {
    setOpenChooseCaracteristique(false);
  }

  return (
    <PlayerCardStyled className={"bbsa-player-card-root" + (dead ? " dead" : "") + (rpm ? " rpm" : "")}>
      <PlayerChooseClaimableCaracteristique
        open={openChooseCaracteristique}
        onClose={handleCloseChooseCaracteristique}
        onCaracteristiqueClaim={()=>{
          refreshTeam();
        }}
        player={player}
        team={team}
        claimableCode={claimableCode}
      />
      <PlayerChooseClaimableSkill
        open={openChooseSkill}
        onClose={handleCloseChooseSkill}
        onSkillClaim={()=>{
          refreshTeam();
        }}
        player={player}
        team={team}
        claimableCode={claimableCode}
      />

      <Stack spacing={1}>
        <Container maxWidth={"lg"} sx={{paddingTop: "10px"}}>
          <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={1}
          >
            <Stack
              direction="column"
              justifyContent="center"
              alignItems="center"
              spacing={2}
            >
              <Typography variant={'div'} className={"bbsa-player-card-number"}
                          sx={{width: "40px"}}>#{player.number}</Typography>
              {player.waitingImprovement.length>0 &&
                <SwitchAccessShortcutIcon color={"success"} sx={{cursor : "pointer"}} onClick={handleLevelUp}/>
              }
            </Stack>
            <Grid container>
              <Grid item xs={12} sm={4}>
                <Stack spacing={1}>
                  <Stack
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                  >
                    <PlayerStatus BP={bp} BPNumber={bpNumber} RPM={rpm} DEAD={dead}/>
                    <Stack>
                      <Typography variant={'div'} align={"left"}
                                  className={"bbsa-player-card-name"}>{player.name}</Typography>
                      <Typography variant={'div'} align={"left"}
                                  className={"bbsa-player-card-posname"}>{player.positionalName}</Typography>
                      <Typography variant={'div'} align={"left"}
                                  className={"bbsa-player-card-posname"}>{player.psp}&nbsp;PSP&nbsp;|&nbsp;{player.value}K</Typography>
                    </Stack>
                  </Stack>
                  {!dead && <PlayerCaracteristiques player={player}/>}
                </Stack>
              </Grid>
              <Grid item xs={12} sm={8}>
                {!dead && <PlayerSkills skills={player.positionalSkills}/>}
                {!dead && <PlayerSkills skills={player.ownedSkills} color={"success"}/>}
              </Grid>
            </Grid>
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={2}
            >
              {editable && <PlayerEditionButton player={player}/>}
            </Stack>
          </Stack>
        </Container>
        <Divider/>
      </Stack>
    </PlayerCardStyled>

  )
}
