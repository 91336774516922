import {Grid, styled, Tabs} from "@mui/material";
import {Fragment, useEffect, useState} from "react";
import {PhasesService} from "../../../services";
import useLoading from "../../contexts/loading/useLoading";
import TournamentPool from "./TournamentPool";
import Xarrow from "react-xarrows";


export default function TournamentContainer(props) {
    const {phase} = props;
    const {hideLoading, showLoading} = useLoading();

    const [pools, setPools] = useState(null);
    const [games, setGames] = useState(null);

    useEffect(() => {
        showLoading();
        PhasesService.getPoolsWithGames(phase.id).then((pools) => {
            let linkGames = [];
            hideLoading()
            setPools(pools);
            pools.map(pool => {
                pool.games.filter(g => g.nextWinnerGameId !== null).map(g => {
                    linkGames.push(g);
                })
            })
            setGames(linkGames);
        }).catch((error) => {
            hideLoading();
        })
    }, [phase])

    if (pools === null) {
        return (<Fragment/>)
    }

    return (
        <Fragment>

            <TournamentContainerRoot
                variant="scrollable"
                scrollButtons
                allowScrollButtonsMobile
                indicatorColor="transparent"
                centered
            >
                {pools.map((pool) => <TournamentPool pool={pool}/>)}
                {
                    games.map((game) =>
                        <Xarrow y
                                start={'game' + game.id}
                                end={"game" + game.nextWinnerGameId}
                                path={'grid'}
                                showHead={false}
                                color={"green"}
                        />
                    )
                }
            </TournamentContainerRoot>
        </Fragment>
        // <TournamentContainerRoot className={"tournament-container-root"}>
        //     <Grid container>
        //         <Grid item container  xs={6}>
        //             <GameContainer item xs={12}>
        //                 <Game id={"game1"} home={team1} visitor={team2}>1ère demi-finale</Game>
        //             </GameContainer>
        //             <GameContainer item xs={12}>
        //             </GameContainer>
        //             <GameContainer item xs={12}>
        //             </GameContainer>
        //             <GameContainer item xs={12}>
        //                 <Game id={"game2"}  home={team3} visitor={team4}>2ème demi-finale</Game>
        //             </GameContainer>
        //         </Grid>
        //         <Grid item container  xs={6}>
        //             <GameContainer item xs={12}>
        //             </GameContainer>
        //             <GameContainer item xs={12} >
        //                 <Game id={"game3"}  home={team1}>Finale</Game>
        //             </GameContainer>
        //             <GameContainer item xs={12}>
        //                 <Game id={"game4"} home={team2}>3ème place</Game>
        //             </GameContainer>
        //             <GameContainer item xs={12}>
        //             </GameContainer>
        //         </Grid>
        //         <Grid item container  xs={2}>
        //         </Grid>
        //     </Grid>
        //     <Xarrow start={'game1'} path={'grid'} showHead={false} color={"green"} end="game3"/>
        //     <Xarrow start={'game2'} path={'grid'} showHead={false} color={"green"} end="game3"/>
        //     {/*<Xarrow start={'game1'} path={'grid'} showHead={false} startAnchor={"bottom"} gridBreak={"50%"} color={"red"} dashness={true} end="game4"/>*/}
        //     {/*<Xarrow start={'game2'} path={'grid'} showHead={false} startAnchor={"top"} gridBreak={"50%"} color={"red"} dashness={true} end="game4"/>*/}
        //     {/*<Xarrow start={'game7'} path={'grid'} showHead={false} color={"green"} end="game1"/>*/}
        //     {/*<Xarrow start={'game8'} path={'grid'} showHead={false} color={"green"} end="game1"/>*/}
        //
        //     {/*<Xarrow start={'game9'} path={'grid'} showHead={false} color={"green"} end="game2"/>*/}
        //     {/*<Xarrow start={'game10'} path={'grid'} showHead={false} color={"green"} end="game2"/>*/}
        //
        //     {/*<Xarrow start={'game11'} path={'grid'} showHead={false} color={"green"} end="game7"/>*/}
        //     {/*<Xarrow start={'game12'} path={'grid'} showHead={false} color={"green"} end="game7"/>*/}
        //
        // </TournamentContainerRoot>
    )
}

const TournamentContainerRoot = styled(Tabs)(({theme}) => ({}))

