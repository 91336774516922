import CommonService from "./CommonService";

class PoolsService extends CommonService {
  async getPoolsOfSeason(id) {
    return this.request({
      url: process.env.REACT_APP_API_URL + '/pools/season/' + id,
      method: 'GET'
    })
  }

  async getTeams(id) {
    return this.request({
      url: process.env.REACT_APP_API_URL + '/pools/' + id + '/teams',
      method: 'GET'
    })
  }

  async getRank(id) {
    return this.request({
      url: process.env.REACT_APP_API_URL + '/pools/' + id + '/rank',
      method: 'GET'
    })
  }
}

export default new PoolsService();
