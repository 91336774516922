import CommonService from "./CommonService";

class PositionalsService extends CommonService{
    async get(id) {
        return this.request({
            url: process.env.REACT_APP_API_URL + "/positionals/"+id,
            method: 'GET'
        })
    }
}

export default new PositionalsService();


