import React, {useEffect, useState} from "react";
import {
  Box,
  Button,
  IconButton,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography
} from "@mui/material";
import moment from "moment/moment";
import localization from 'moment/locale/fr'
import GameLine from "../GameLine/GameLine";
import SportsIcon from "@mui/icons-material/Sports";
import ShortcutIcon from "@mui/icons-material/Shortcut";
import ScoreboardIcon from "@mui/icons-material/Scoreboard";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import FlakyIcon from "@mui/icons-material/Flaky";
import GameStatusIcon from "../GameStatusIcon/GameStatusIcon";

export default function DayTable(props) {
  const {
    day,
    onAddGameClick,
    onStartGameClick,
    onDelayGameClick,
    onAddScoreClick,
    onForfeitGameClick,
    adminOptions = false,
    showStatus = false
  } = props;
  const [date, setDate] = useState("");
  const [teams, setTeams] = useState(null);

  const handleAddGameClick = () => {
    if (onAddGameClick) {
      onAddGameClick(day.id);
    }
  }

  const handleAddScoreClick = (matchId) => {
    if (onAddScoreClick) {
      onAddScoreClick(matchId);
    }
  }

  const handleDelayGameClick = (matchId) => {
    if (onDelayGameClick) {
      onDelayGameClick(matchId);
    }
  }

  const handleStartGameClick = (matchId) => {
    if (onStartGameClick) {
      onStartGameClick(matchId);
    }
  }

  const handleForfeitGameClick = (matchId) => {
    if (onForfeitGameClick) {
      onForfeitGameClick(matchId);
    }
  }

  useEffect(() => {
    if (teams === null) {

      let date = moment(day.eventDate, 'DD/MM/YYYY')
      setDate(date.locale("fr", localization).format("dddd DD MMMM"));
    }
  })

  return (
    <DayTableRoot className={"bbsa-day-table-root"}>
      <DayTableTitle className={"bbsa-day-table-title"}>{day.name} | {date}</DayTableTitle>
      <TableContainer component={Box}>
        <Table key={"dayTable_" + day.id} size="small">
          <TableBody>
            {day.games !== null && day.games.map((row) =>
              <TableRow key={"day_table_game_" + row.id}>
                {showStatus &&
                  <DayTableCellStatus>
                    <GameStatusIcon status={row.progressStatus}/>
                  </DayTableCellStatus>
                }
                <DayTableCellText>
                  <GameLine game={row}/>
                </DayTableCellText>
                {adminOptions &&
                  <DayTableCellOptions>
                    <GameLineAdminOptions
                      game={row}
                      onStartGameClick={handleStartGameClick}
                      onDelayGameClick={handleDelayGameClick}
                      onAddScoreClick={handleAddScoreClick}
                      onForfeitGameClick={handleForfeitGameClick}
                    />
                  </DayTableCellOptions>
                }
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {adminOptions &&
        <Box key={"dayTableRow_new"} sx={{display: 'block'}} className={"bbsa-dayTable-footer"}>
          <Box className={'bbsa-dayTable-add-game'}>
            <Button onClick={handleAddGameClick} fullWidth color={"success"} startIcon={<AddCircleOutlineIcon/>}>
              Ajouter un match
            </Button>
          </Box>
        </Box>
      }
    </DayTableRoot>
  )
}

function GameLineAdminOptions(props) {
  const {game, onStartGameClick, onDelayGameClick, onAddScoreClick, onForfeitGameClick} = props;

  const handleAddScoreClick = () => {
    if (onAddScoreClick) {
      onAddScoreClick(game.id);
    }
  }

  const handleDelayGameClick = () => {
    if (onDelayGameClick) {
      onDelayGameClick(game.id);
    }
  }

  const handleStartGameClick = () => {
    if (onStartGameClick) {
      onStartGameClick(game.id);
    }
  }

  const handleForfeitGameClick = () => {
    if (onForfeitGameClick) {
      onForfeitGameClick(game.id);
    }
  }

  return (
    <Box>
      {(game.progressStatus === "NOT_STARTED") &&
        <IconButton size={"small"} variant="outlined" color="primary" onClick={handleStartGameClick}
                    aria-label="delete">
          <SportsIcon/>
        </IconButton>}
      {(game.progressStatus === "NOT_STARTED") &&
        <IconButton size={"small"} variant="outlined" color="primary" onClick={handleDelayGameClick}
                    aria-label="delete">
          <ShortcutIcon/>
        </IconButton>}
      {(game.progressStatus === "IN_PROGRESS") &&
        <IconButton size={"small"} variant="outlined" color="primary" onClick={handleAddScoreClick} aria-label="score">
          <ScoreboardIcon/>
        </IconButton>}
      {(game.progressStatus === "DELAYED") &&
        <IconButton size={"small"} variant="outlined" color="primary" onClick={handleStartGameClick}
                    aria-label="delete">
          <SportsIcon/>
        </IconButton>}
      {(game.progressStatus === "DELAYED" || game.progressStatus === "NOT_STARTED") &&
        <IconButton size={"small"} variant="outlined" color="primary" onClick={handleForfeitGameClick}
                    aria-label="delete">
          <FlakyIcon/>
        </IconButton>}
    </Box>
  )

}

const DayTableRoot = styled(Box)(({theme}) => ({
    backgroundColor: theme.palette.background.paper,
    minWidth: "100px",
    [theme.breakpoints.down("sm")]: {}
  }
));

const DayTableTitle = styled(Typography)(({theme}) => ({
    fontFamily: theme.typography.fontFamily,
    background: (theme.palette.secondary.gradient ? theme.palette.secondary.gradient : theme.palette.secondary.main),
    color: theme.palette.secondary.contrastText,
    padding: "10px",
    [theme.breakpoints.down("sm")]: {}
  }
));

const DayTableCellStatus = styled(TableCell)(({theme}) => ({
  paddingLeft: "5px",
  paddingRight: 0
}));

const DayTableCellText = styled(TableCell)(({theme}) => ({
  paddingLeft: "5px",
  paddingRight: "5px"
}));

const DayTableCellOptions = styled(TableCell)(({theme}) => ({
  paddingLeft: 0,
  paddingRight: "5px"
}));

