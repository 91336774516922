import CommonService from "./CommonService";

class PhaseService extends CommonService {
  async getPhase(id) {
    return this.request({
      url: process.env.REACT_APP_API_URL + '/phases/' + id + '/pools',
      method: 'GET'
    })
  }

  async getPoolsWithGames(id) {
    return this.request({
      url: process.env.REACT_APP_API_URL + '/phases/' + id + '/pools?includeGamesWithTeams=true',
      method: 'GET'
    })
  }

  async getPhasesFromSeason(seasonId) {
    return this.request({
      url: process.env.REACT_APP_API_URL + '/seasons/' + seasonId + '/phases',
      method: 'GET'
    }).then((phases) => {
      return phases;
    })
  }

  async getPhasesBonusPoints(phaseId) {
    return this.request({
      url: process.env.REACT_APP_API_URL + '/phases/' + phaseId + '/bonusPoints',
      method: 'GET'
    }).then((conditions) => {
      return conditions;
    })
  }


}

export default new PhaseService();
