import {createContext, useEffect, useState} from "react";
import useGame, {calculateGlobalTurnNumber, calculateHalftime, calculateHalftimeTurnNumber} from "../game/useGame";
import EventsService from "../../../services/EventsService";


const defaultContextValues = {
  turn: {
    global: 0,
    number: 0,
    halftime: 0
  },
  kickOffTeam: {
    name: ""
  },
  activeTeam: {
    name: "",
    players: []
  },
  eventCategories: null,
  eventTypes: null,
}

export const CurrentGameBoardContext = createContext(defaultContextValues);

export const GameBoardContext = (props) => {
  const {game, events, getTeamFromPlayer, getOpposedTeam, team1, team2, setEvents} = useGame();
  const [kickOffTeam, setKickOffTeam] = useState(defaultContextValues.kickOffTeam);
  const [activeTeam, setActiveTeam] = useState(defaultContextValues.activeTeam);
  const [turn, setTurn] = useState(defaultContextValues.turn)
  const [eventCategories, setEventCategories] = useState(defaultContextValues.eventCategories)
  const [eventTypes, setEventTypes] = useState(defaultContextValues.eventTypes)

  useEffect(() => {
    if (eventCategories === null) {
      EventsService.getEventCategories().then((events) => {
        setEventCategories(events);
      })
    }
  }, [eventCategories])

  useEffect(() => {
    if (eventTypes === null) {
      EventsService.getEventsType().then((events) => {
        setEventTypes(events);
      })
    }
  }, [eventTypes])


  useEffect(() => {
    if (kickOffTeam === defaultContextValues.kickOffTeam) {
      events.filter(e => e.eventCode === "KickOff").map((e) => {
        setKickOffTeam(getTeamFromPlayer(e.initiator));
      })
    }
  }, [events, kickOffTeam])

  useEffect(() => {
    let nbrOfEvents = events.filter(event =>
      event.eventCode === 'Turnover'
      || event.eventCode === 'Touchdown'
      || event.eventCode === 'EndOfTurn'
    ).length;

    setTurn({
      global: calculateGlobalTurnNumber(nbrOfEvents),
      number: calculateHalftimeTurnNumber(calculateGlobalTurnNumber(nbrOfEvents)),
      halftime: calculateHalftime(calculateGlobalTurnNumber(nbrOfEvents))
    })
  }, [events])

  useEffect(() => {
    if (events !== null && kickOffTeam !== defaultContextValues.kickOffTeam) {
      let nbrOfEvents = events.filter(event =>
        event.eventCode === 'Turnover'
        || event.eventCode === 'Touchdown'
        || event.eventCode === 'EndOfTurn'
      ).length;

      let active = kickOffTeam;
      if (nbrOfEvents % 2 === 0) {
        active = getOpposedTeam(kickOffTeam)
      }
      if ((turn.halftime % 2) === 0) {
        active = getOpposedTeam(active)
      }

      setActiveTeam({...active});
    }
  }, [turn])

  useEffect(() => {
    if (events.length === 0 && activeTeam !== defaultContextValues.activeTeam && kickOffTeam !== defaultContextValues.kickOffTeam) {
      setActiveTeam(defaultContextValues.activeTeam)
      setKickOffTeam(defaultContextValues.kickOffTeam)
    }
  }, [events])


  const value = {
    game,
    events,
    setEvents,
    kickOffTeam,
    turn,
    activeTeam,
    team1,
    team2,
    eventCategories,
    eventTypes,
    getTeamFromPlayer
  };


  return (
    <CurrentGameBoardContext.Provider value={value}>
      {props.children}

    </CurrentGameBoardContext.Provider>
  )
}

