import {Stack, styled, Tab, Typography} from "@mui/material";
import Game from "./Game";

export default function TournamentPool(props) {
    const {pool} = props;

    return (
        <TournamentPoolRoot
            direction="column"
            justifyContent="space-around"
            alignItems="center"
            spacing={2}
        >
            {pool.games.map((game) => {
                return (
                    <Game id={"game" + game.id} game={game}>{game.name}</Game>
                )
            })}
        </TournamentPoolRoot>
    )
}

const TournamentPoolRoot = styled(Stack)(({theme}) => ({
    width: 225,
    minWidth: 225,
}))

