import React, {Fragment} from "react";
import {Box, Container, styled} from "@mui/material";
import useGame from "../../contexts/game/useGame";
import Timeline from "@mui/lab/Timeline";
import GameEventTimeLineItemKickOff from "./GameEventTimeLineItemKickOff";
import GameEventTimeLineTurn from "./GameEventTimeLineTurn";

const GameEventsTimelineStyled = styled("div")(({theme}) => ({
  [".bbsa-game-event-timeline-item-title-secondary"]: {
    fontSize: "0.7rem",
  },
  [".bbsa-game-event-timeline-item-title-secondary.initiator"]: {
    color: "#1a9aef",
  },
  [".bbsa-game-event-timeline-item-title-secondary.victim"]: {
    color: "#e71313"
  },
  [".bbsa-game-events-timeline-item-no-event"]: {
    fontSize: "0.7rem",
  },
  [theme.breakpoints.down("sm")]: {}
}))

export default function GameEventsTimeline(props) {
  const {variant = "outlined", revert = false} = props;
  const {turns} = useGame();

  return (
    <GameEventsTimelineRoot variant={variant} className={"game-page-timeline-root"}>
      <Container maxWidth={"xs"}>
        <GameEventsTimelineContent className={"bbsa-game-events-content"}>
          {!revert &&
            <Fragment>
              <GameEventTimeLineItemKickOff/>
              {turns.map((turn => <GameEventTimeLineTurn turn={turn}/>))}
            </Fragment>
          }
          {revert &&
            <Fragment>
              {turns.slice().reverse().map((turn => <GameEventTimeLineTurn turn={turn} revert/>))}
              <GameEventTimeLineItemKickOff/>
            </Fragment>
          }
        </GameEventsTimelineContent>
      </Container>
    </GameEventsTimelineRoot>
  )
}

const GameEventsTimelineRoot = styled(Box)(({variant, theme}) => ({
  background: variant === 'contained' ? (theme.components.bbsa.eventTimeline.background ? theme.components.bbsa.eventTimeline.background : theme.palette.background.paper) : "",
  [theme.breakpoints.down("sm")]: {}
}))

const GameEventsTimelineContent = styled(Timeline)(({theme}) => ({
  ["& p"]: {
    fontFamily: theme.typography.fontFamily,
  },
  [theme.breakpoints.down("sm")]: {}
}))

