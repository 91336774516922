import React, {useEffect, useState} from "react";
import {
  GAME_STATUS_FINISHED,
  GAME_STATUS_FORFEIT,
  GAME_STATUS_NOT_STARTED,
  GAME_STATUS_REPORTED, GAME_STATUS_STARTING
} from "../../components/Global/Global";
import {Box, styled, Typography} from "@mui/material";

const GameStateRoot = styled(Box)(({theme}) => ({
  [".bbsa-game-state-text"]: {
    fontSize: "1.0rem"
  },
  [theme.breakpoints.down("sm")]: {}
}))


export default function GameState(props) {
  const {game, align = "center", color = "#ffffff"} = props;
  const [status, setStatus] = useState("");

  useEffect(() => {
    if (game) {
      switch (game.progressStatus) {
        case GAME_STATUS_NOT_STARTED:
          setStatus("Non démarré");
          break;
        case GAME_STATUS_STARTING:
          setStatus("Sifflé");
          break;
        case GAME_STATUS_REPORTED :
          setStatus("Reporté");
          break;
        case GAME_STATUS_FORFEIT:
          setStatus("Forfait");
          break;
        case GAME_STATUS_FINISHED:
          setStatus("Terminé");
          break;
      }
    }
  }, [game])

  return (
    <GameStateRoot className={"bbsa-game-state-root"} sx={props.sx}>
      <Typography className={'bbsa-game-state-text'} style={{color: color}} align={align}>{status}</Typography>
    </GameStateRoot>
  )
}
