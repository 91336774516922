import React, {Fragment, useEffect, useState} from "react";
import useTeam from "../../contexts/team/useTeam";
import BBSAInputField from "../BbsaInputField/BBSAInputField";
import BBSASelectField from "../BbsaSelectField/BBSASelectField";
import RaceService from "../../../services/RaceService";
import {Button, DialogActions, DialogContent, DialogContentText, Typography} from "@mui/material";
import useSession from "../../contexts/session/useSession";
import teamsService from "../../../services/TeamsService";
import ActionButton from "../ValidationBeforeAction/ActionButton";
import useLoading from "../../contexts/loading/useLoading";
import BBSALoadingBox from "../BbsaLoadingBox/BBSALoadingBox";
import JourneyManSelector from "../JourneyManSelector/JourneyManSelector";
import {GamesService} from "../../../services";


export default function AddJourneymanForm(props) {
    const {
        onAddSuccess = () => {
        },
        onAddError = () => {
        },
    } = props;

    const {team} = useTeam();
    const {currentSession} = useSession();
    const {showLoading, hideLoading} = useLoading();

    const [name, setName] = useState("");
    const [positionalId, setPositionalId] = useState(0);
    const [positional, setPositional] = useState(null);
    const [number, setNumber] = useState(0);
    const [positionals, setPositionals] = useState(null);
    const [dataJourneymen, setDataJourneymen] = useState([]);


    useEffect(() => {
        if (positionals === null) {
            RaceService.getPositionals(team.race.id).then((positionals) => {
                setPositionals(positionals);
            })
        }
    }, [positionals])

    const handleJmChanged = (t, j, completed) => {
        let data = constructPositionals(j, getMaxPlayerNumber(team));
        setDataJourneymen(data)
    }

    const handleAddJourneyman = ()=>{
        teamsService.addJourneymen(team.id, dataJourneymen, currentSession.accessToken).then(() => {
            onAddSuccess();
        }).catch((e)=>{
            onAddError(e);
        });
    }

    const constructPositionals = (j, initialNumber) => {
        let pos = [];
        for (let i = 0; i < j.length; i++) {
            let p = {
                positionalId : j[i],
                number : (initialNumber+i+1)
            }
            pos.push(p)
        }
        return pos;
    }

    const getMaxPlayerNumber = (team) => {
        let number = 0;
        let p = team.players.filter(p => p.active);
        for (let i = 0; i < p.length; i++) {
            if(p[i].number>number){
                number = p[i].number;
            }
        }
        return number
    }

    return (
        <Fragment>
            <DialogContent dividers={true}>
                <DialogContentText
                    id="scroll-dialog-description"
                    tabIndex={-1}
                >
                    <JourneyManSelector team={team} onChanged={handleJmChanged}/>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleAddJourneyman}>Ajouter</Button>
                {/*    <ActionButton*/}
                {/*        fullWidth*/}
                {/*        disabled={positional ? false : true}*/}
                {/*        actionLabel={"Acheter"}*/}
                {/*        elementToBuy={positional ? positional.name : ""}*/}
                {/*        onDoAction={handleAddPlayer}*/}
                {/*        title={"Validation d'achat"}*/}
                {/*    />*/}
            </DialogActions>
        </Fragment>
    )
}
