import React from "react";
import {Box, LinearProgress, Stack, styled, Typography} from "@mui/material";


export default function GameInProgress(props) {
  const {type} = props;

  return (
    <GameInProgressRoot type={type}>
      {
        (type === 'animation') ? (
          <Stack>
            <GameInProgressText small align={"center"}>en cours</GameInProgressText>
            <LinearProgress/>
          </Stack>
        ) : (type === 'text') ? (
          <GameInProgressText align={"center"}>En cours</GameInProgressText>
        ) : (
          <GameInProgressText align={"center"}>En cours</GameInProgressText>
        )
      }
    </GameInProgressRoot>
  )

}

const GameInProgressRoot = styled(Box)(({theme, type}) => ({
  width: type === 'animation' ? "100%" : "auto",
  [theme.breakpoints.down("sm")]: {}
}))

const GameInProgressText = styled(Typography)(({theme, small}) => ({
  font: theme.typography.fontFamily,
  fontSize: (small) ? "0.7rem" : "",
  [theme.breakpoints.down("sm")]: {}
}))
