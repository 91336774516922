import React from "react";
import {Box, Button, Fab, Paper, Popover, Stack, styled, Typography} from "@mui/material";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import AddStaffDialog from "../AddStaff/AddStaffDialog";
import useTeam from "../../contexts/team/useTeam";
import {useSnackbar} from "notistack";

export const StaffMenu = (props) => {
  const {refreshTeam} = useTeam();
  const { enqueueSnackbar } = useSnackbar();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openAddStaffDialog, setOpenAddStaffDialog] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleAddStaff = () => {
    handleClose();
    setOpenAddStaffDialog(true);
  }

  const handleCloseAddStaff = () => {
    setOpenAddStaffDialog(false);
  }

  const handleAddStaffSuccess = (staffName) => {
    enqueueSnackbar(staffName + " acheté!", {variant : "success"});
    refreshTeam()
  }

  const handleAddStaffError = (error) => {
    enqueueSnackbar("Erreur lors de l'achat d'un membre du staff : " + error.message, {variant : "error"});
  }


  const open = Boolean(anchorEl);

  return (
    <StaffMenuRoot className={"bbsa-staff-menu-root"}>
      <StaffMenuButton color="white" aria-label="add" onClick={handleClick}>
        <MoreHorizIcon />
      </StaffMenuButton>

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <StaffMenuOptionsContent>
          <Stack>
            <Button variant={"text"} color={"secondary"} onClick={handleAddStaff}>Acheter un membre du staff</Button>
          </Stack>
        </StaffMenuOptionsContent>
      </Popover>
      <AddStaffDialog
        open={openAddStaffDialog}
        onClose={handleCloseAddStaff}
        onAddSuccess={handleAddStaffSuccess}
        onAddError={handleAddStaffError}
      />
    </StaffMenuRoot>
  )
}

const StaffMenuButton = styled(Fab)(({theme})=>({
  opacity : '50%',
  minHeight : "28px",
  height : "28px",
  width : "28px"
}))

const StaffMenuRoot = styled(Box)(({theme})=>({
  position : 'absolute',
  top : 0,
  right : 0,
  padding : "5px"
}))

const StaffMenuOptionsContent = styled(Paper)(({theme}) => ({
  padding : 5,
}))
