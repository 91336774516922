import {useContext} from "react";
import {CurrentTeamContext} from "./TeamContext";


export default function useTeam(){
  const {isMyTeam, team, players, setReload} = useContext(CurrentTeamContext);

  function refreshTeam(){
    setReload(true);
  }

  return {isMyTeam, team, players, refreshTeam};
}
