import React, {Fragment, useEffect, useState} from "react";
import {Typography} from "@mui/material";

function EventBreadcrumbs(props) {
  const {
    category = null,
    event = null,
    team = null,
    player = null,
    victimTeam = null,
    victim = null,
    hasVictim = false,
    hasInjury = false,
    injury = null
  } = props;

  const [label, setLabel] = useState(null);

  const getPlayerName = (p) => {
    let l = null;
    if (p) {
      l = '#' + p.number + ' ' + p.positionalName + (p.name !== null ? "(" + p.name + ")" : "");
    }
    return l;
  }

  useEffect(() => {
    let l = "";
    if (!hasVictim) {
      if ((category || event) && victim === null) {
        if (event) {
          if (team) {
            if (player) {
              l = <Fragment><b>{event.name}</b> attribué à <b>{getPlayerName(player)}</b></Fragment>;
            } else {
              l = <Fragment><b>{event.name}</b> de l'equipe <b>{team.name}</b> attribué à ...</Fragment>;
            }
          } else {
            l = <Fragment><b>{event.name}</b> de l'equipe ...</Fragment>;
          }
        } else {
          l = <Fragment><b>{category.name}</b> de type ... </Fragment>;
        }
      } else if ((category || event) && victim) {
        if (!team && !player) {
          if (event) {
            l = <Fragment><b>{getPlayerName(victim)}</b> victime <b>{event.name}</b></Fragment>;
          } else {
            l = <Fragment><b>{getPlayerName(victim)}</b> victime <b>{category.name}</b> de type ... </Fragment>;
          }
        } else {
          if (event) {
            if (team) {
              if (player) {
                l = <Fragment><b>{getPlayerName(victim)}</b> victime <b>{event.name}</b> infligé
                  par <b>{getPlayerName(player)}</b></Fragment>;
              } else {
                if (hasInjury) {
                  l = <Fragment><b>{getPlayerName(victim)}</b> victime <b>{event.name}</b> de type ...</Fragment>;
                } else {
                  if (injury) {
                    l = <Fragment><b>{getPlayerName(victim)}</b> victime <b>{injury.name}</b> infligé par ... de
                      l'equipe <b>{team.name}</b></Fragment>;
                  } else {
                    l = <Fragment><b>{getPlayerName(victim)}</b> victime <b>{event.name}</b> infligé par ... de
                      l'equipe <b>{team.name}</b></Fragment>;
                  }
                }
              }
            } else {
              l = <Fragment><b>{getPlayerName(victim)}</b> victime <b>{event.name}</b> infligé par ...</Fragment>;
            }
          } else {
            l = <Fragment><b>{getPlayerName(victim)}</b> victime <b>{category.name}</b> de type ... </Fragment>;
          }
        }
      } else {
        l = "##### INITIATOR SECTION ####"
      }
    } else {
      if (!team) {
        l = <Fragment>La victime à partenant à l'équipe ...</Fragment>;
      } else {
        l = <Fragment>La victime ... de l'équipe <b>{team.name}</b></Fragment>;
      }
    }
    setLabel(l);
  }, [category, event, team, player, victimTeam, victim])

  return (
    <Typography sx={{mt: "40px"}}>{label}</Typography>
  )
}

export default EventBreadcrumbs
