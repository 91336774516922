import React, {Fragment, useEffect, useState} from "react";
import {Container, Grid, useTheme} from "@mui/material";
import NewsCard from "../components/NewsCard/NewsCard";
import {SeasonsService} from "../../services";
import {Banner} from "../components";

export default function NewsPage(props) {
  const {components} = useTheme();
  const [news, setNews] = useState(null);

  useEffect(() => {
    if (news === null) {
      SeasonsService.getCurrent().then((season) => {
        SeasonsService.getAllNewsOfSeason(season.id).then((news) => {
          setNews(news);
        })
      })
    }
  })

  return (
    <Fragment>
      <Banner imgSource={components.bbsa.newsBanner.image} text={"Actualités"}/>
      <Container maxWidth={"lg"} sx={{paddingTop: "20px"}}>
        <Grid container
              direction="row"
              spacing={2}
              justifyContent={'center'}
        >
          {news !== null && news.map((news) =>
            <Grid item>
              <NewsCard
                title={news.title}
                imageUrl={news.imageUrl}
                date={news.date}
                newsId={news.id}
              />
            </Grid>
          )}
        </Grid>
      </Container>
    </Fragment>
  )
}
