import React, {useEffect, useState} from "react";
import {
  FormControlLabel,
  styled,
  Switch,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableRow,
  Typography
} from "@mui/material";
import useSession from "../../contexts/session/useSession";
import userService from "../../../services/UserService";

const UsersListStyled = styled("div")(({theme}) => ({
    ['.bbsa-users-list-root']: {
      backgroundColor: theme.palette.background.paper
    },
    [theme.breakpoints.down("sm")]: {}
  }
));


function UsersList(props) {
  const {currentSession} = useSession();

  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState(null);

  useEffect(() => {
    if (!loading && users === null) {
      setLoading(true);
      userService.list(currentSession.accessToken).then((users) => {
        setUsers(users);
        setLoading(false)
      })
    }
  }, [loading, users])

  const handleChangeActivated = (e, username) => {
    userService.setActivated(currentSession.accessToken, e.target.checked, username).then((user) => {
      let l = users.map(u => {
        if (u.name === user.name) {
          return user;
        } else {
          return u
        }
      })
      setUsers(l);
    })
  }


  if (users === null || loading) {
    return (
      <React.Fragment>
        <Typography variant={"h2"} align={"center"}>Chargement</Typography>
      </React.Fragment>
    )
  }


  return (
    <UsersListStyled>
      <TableContainer>
        <Table aria-label="customized table" className={"bbsa-users-list-root"}>
          <TableBody>
            {users.map(user => {
              return (
                <React.Fragment key={"user_" + user.name}>
                  <StyledTableRow>
                    <StyledTableCell align="left" sx={{position: 'relative'}}>
                      <Typography variant={"div"}>
                        {user.name}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell align="left" sx={{position: 'relative'}}>
                      <Typography variant={"div"}>
                        {user.name}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell align="left" sx={{position: 'relative'}}>
                      <Typography variant={"div"}>
                        {user.firstName}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell align="left" sx={{position: 'relative'}}>
                      <Typography variant={"div"}>
                        {user.lastName}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell align="left" sx={{position: 'relative'}}>
                      <Typography variant={"div"}>
                        {user.email}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell align="left" sx={{position: 'relative'}}>
                      <Typography variant={"div"}>
                        {user.roles}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell align="left" sx={{position: 'relative'}}>
                      <Typography variant={"div"}>
                        {(currentSession.user.name !== user.name) &&
                          <FormControlLabel control={<Switch onChange={(e) => {
                            handleChangeActivated(e, user.name)
                          }} defaultChecked={user.activated}/>} label="Activé"/>
                        }
                      </Typography>
                    </StyledTableCell>
                  </StyledTableRow>
                </React.Fragment>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </UsersListStyled>
  )
}

const StyledTableRow = styled(TableRow)(({theme}) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));


const StyledTableCell = styled(TableCell)(({theme}) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
  fontFamily: "Nuffle"
}));


export default UsersList
