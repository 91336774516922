import {Container} from "@mui/material";
import React, {useEffect, useState} from "react";
import {Link, useParams} from "react-router-dom";
import NewsService from "../../services/NewsService";
import useSession from "../contexts/session/useSession";
import NewsPaper from "../components/NewsPaper/NewsPaper";

export default function NewsReaderPage(props) {
  let {id} = useParams();
  let {currentSession} = useSession()

  const [news, setNews] = useState();
  const [newsLoaded, setNewsLoaded] = useState(false);


  useEffect(() => {
    if (!newsLoaded) {
      NewsService.get(id).then((news) => {
        setNews(news);
        setNewsLoaded(true)
      })
    }
  }, [newsLoaded, id])


  return (
    <React.Fragment>
      <Container maxWidth={"lg"} className={'content-bb'}>
        {newsLoaded &&
          <React.Fragment>
            {currentSession.connected && <Link to={'/gestion/news/' + news.id}>Editer</Link>}
            {/*{news.imageUrl && <img alt={news.title} src={news.imageUrl} style={{width : '100%'}}/>}*/}
            <NewsPaper content={news.content} date={news.date}/>
          </React.Fragment>
        }
      </Container>
    </React.Fragment>
  )
}


