import {createContext, useEffect, useState} from "react";
import UserService from "../../../services/UserService";

export const noSession = {
    accessToken : null,
    user : null,
    connected : false,
    isAdmin : false,
    teams : []
}


export const CurrentSessionContext = createContext({
    currentSession : noSession,
    setCurrentSession : () => {}
});

export const SessionContext = (props) => {
    const [currentSession, setCurrentSession] = useState(noSession);
    const [prevUrl, setPrevUrl] = useState("/");
    const [myTeams, setMyTeams] = useState(noSession.teams);

    useEffect(()=>{
        if(currentSession.connected){
            UserService.getMyTeams(currentSession.accessToken).then((teams => {
                setMyTeams(teams)
            }))
        } else {
            setMyTeams([]);
        }
    }, [currentSession])

    const value = {currentSession, setCurrentSession, prevUrl, setPrevUrl, myTeams};

    return (
        <CurrentSessionContext.Provider value={value}>
            {props.children}
        </CurrentSessionContext.Provider>
    );
};
