import React from "react";
import {Card, CardContent, styled} from "@mui/material";

export default function BbsaCard(props) {
  const {mb = 0, mr = 0, mt = 0} = props
  return (
    <BbsaCardRoot {...props} classname={'bbsa-card-root'} mb={mb} mr={mr} mt={mt}>
      <BbsaCardContent classname={'bbsa-card-content'}>
        {props.children}
      </BbsaCardContent>
    </BbsaCardRoot>
  )
}

const BbsaCardRoot = styled(Card)(({theme, mb, mr, mt}) => ({
  marginBottom: mb,
  marginTop: mt,
  marginRight: mr
}))

const BbsaCardContent = styled(CardContent)(({theme}) => ({
  padding: 0,
  paddingBottom: "0!important",
}))
