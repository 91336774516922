import './ck-styles.css'
import React, {useEffect, useState} from "react";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Layout from "./V2/components/layout/Layout";
import NoPage from "./V2/pages/NoPage";
import useSession from "./V2/contexts/session/useSession";
import HomePage from "./V2/pages/HomePage";
import NewsPage from "./V2/pages/NewsPage";
import NewsReaderPage from "./V2/pages/NewsReaderPage";
import RanksPage from "./V2/pages/RanksPage";
import CalendarPage from "./V2/pages/CalendarPage";
import GamePage from "./V2/pages/GamePage";
import BeforeGamePage from "./V2/pages/BeforeGamePage";
import {ThemeProvider} from "@mui/material";
import {ThemeContext} from "./V2/contexts/theme/ThemeContext";
import {useTheme} from "./V2/contexts/theme/useTheme";
import TeamPage from "./V2/pages/TeamPage";
import LoginPage from "./V2/pages/LoginPage";
import {SessionContext} from "./V2/contexts/session/SessionContext";
import RegisterPage from "./V2/pages/RegisterPage";
import UserRoute from "./V2/components/Routes/UserRoute";
import AccountPage from "./V2/pages/AccountPage";
import BoardGamePage from "./V2/pages/BoardGamePage";
import ManagePage from "./V2/pages/ManagePage";
import AdminRoute from "./V2/components/Routes/AdminRoute";
import ManageGamePage from "./V2/pages/ManageGamePage";
import ManageDaysPage from "./V2/pages/ManageDaysPage";
import ManageNewsPage from "./V2/pages/ManageNewsPage";
import ManageUsersPage from "./V2/pages/ManageUsersPage";
import PublicRoute from "./V2/components/Routes/PublicRoute";
import {SeasonContext} from "./V2/contexts/season/SeasonContext";
import {LoadingContext} from "./V2/contexts/loading/LoadingContext";
import ManageApprobationsPage from "./V2/pages/ManageApprobationsPage";
import TeamSheetPage from "./V2/pages/TeamSheetPage";

export function Default() {
    const {theme} = useTheme();

    return (
        <ThemeProvider theme={theme}>
            <LoadingContext>
                <SeasonContext>
                    <SessionContext>
                        <SessionCookie/>
                        <BrowserRouter>
                            <Routes>
                                <Route path="/" element={<Layout/>}>
                                    <Route index element={<PublicRoute><HomePage/></PublicRoute>}/>
                                    <Route path="news" element={<PublicRoute><NewsPage/></PublicRoute>}/>
                                    <Route path="news/:id" element={<PublicRoute><NewsReaderPage/></PublicRoute>}/>
                                    <Route path="ranks" element={<PublicRoute><RanksPage/></PublicRoute>}/>
                                    <Route path="calendar" element={<PublicRoute><CalendarPage/></PublicRoute>}/>
                                    <Route path="game/:id" element={<PublicRoute><GamePage/></PublicRoute>}/>
                                    <Route path="teams/:id" element={<PublicRoute><TeamPage/></PublicRoute>}/>
                                    <Route path="login" element={<LoginPage/>}/>
                                    <Route path="register" element={<RegisterPage/>}/>
                                    <Route path="account" element={
                                        <UserRoute>
                                            <AccountPage/>
                                        </UserRoute>
                                    }/>
                                    <Route path={"before/game/:id"} element={
                                        <UserRoute>
                                            <BeforeGamePage/>
                                        </UserRoute>
                                    }/>
                                    <Route path="gestion" element={
                                        <AdminRoute>
                                            <ManagePage/>
                                        </AdminRoute>
                                    }/>
                                    <Route path="gestion/games" element={
                                        <AdminRoute>
                                            <ManageGamePage/>
                                        </AdminRoute>
                                    }/>
                                    <Route path="gestion/days" element={
                                        <AdminRoute>
                                            <ManageDaysPage/>
                                        </AdminRoute>
                                    }/>
                                    <Route path="gestion/news" element={
                                        <AdminRoute>
                                            <ManageNewsPage/>
                                        </AdminRoute>
                                    }/>
                                    <Route path="gestion/news/:id" element={
                                        <AdminRoute>
                                            <ManageNewsPage/>
                                        </AdminRoute>
                                    }/>
                                    <Route path="gestion/users" element={
                                        <AdminRoute>
                                            <ManageUsersPage/>
                                        </AdminRoute>
                                    }/>
                                    <Route path="gestion/approbations" element={
                                        <AdminRoute>
                                            <ManageApprobationsPage/>
                                        </AdminRoute>
                                    }/>
                                    <Route path="*" element={<NoPage/>}/>
                                </Route>
                                <Route path={"play/game/:id"} element={
                                    <UserRoute>
                                        <BoardGamePage/>
                                    </UserRoute>
                                }/>
                                <Route path={"teams/:id/sheet"} element={
                                    <TeamSheetPage/>
                                }/>
                            </Routes>
                        </BrowserRouter>
                    </SessionContext>
                </SeasonContext>
            </LoadingContext>
        </ThemeProvider>
    );
}

export default function App2(props) {
    return (
        <ThemeContext>
            <Default/>
        </ThemeContext>
    )

}

function SessionCookie(props) {
    const {reconnectFromCookie} = useSession();
    const [cookieLoaded, setCookieLoaded] = useState(false);
    useEffect(() => {
        if (!cookieLoaded) {
            setCookieLoaded(true);
            reconnectFromCookie();
        }
    }, [cookieLoaded, reconnectFromCookie])

    return (<></>)
}



