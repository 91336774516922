import React, {useState} from "react";
import * as PropTypes from "prop-types";
import {Box, ClickAwayListener, Stack, styled, Tooltip, Typography} from "@mui/material";

function PlayerCaracteristiques(props) {
  const {player, disabled = false} = props;
  return (
    <React.Fragment>
      <Stack direction="row" spacing={1}>
        <Caracteristique disabled={disabled} shortName={"M"} name={"Mouvement"} value={player.movement}
                         injuries={player.injuries} characteristicData={player.characteristicData}/>
        <Caracteristique disabled={disabled} shortName={"F"} name={"Force"} value={player.strength}
                         injuries={player.injuries} characteristicData={player.characteristicData}/>
        <Caracteristique disabled={disabled} shortName={"Ag"} name={"Agilité"} value={player.agility} suffix={"+"}
                         injuries={player.injuries} characteristicData={player.characteristicData} reverseColor={true}/>
        <Caracteristique disabled={disabled} shortName={"Cp"} name={"Capacité passe"} value={player.pass} suffix={"+"}
                         injuries={player.injuries} characteristicData={player.characteristicData}  reverseColor={true}/>
        <Caracteristique disabled={disabled} shortName={"Ar"} name={"Armure"} value={player.armour} suffix={"+"}
                         injuries={player.injuries} characteristicData={player.characteristicData}/>
      </Stack>
    </React.Fragment>
  );
}

function Caracteristique(props) {
  const {
    shortName,
    value,
    suffix = "",
    injuries = [],
    characteristicData = [],
    disabled = false,
    reverseColor=false
  } = props;

  var valueState = "normal"
  var componentState = "default"
  var asignedInjuries = [];
  var asignedCharacteristicData = [];

  const [openTT, setOpenTT] = useState(false);

  const handleBoxClick = () => {
    setOpenTT(true);
  }

  const handleTooltipClose = () => {
    setOpenTT(false);
  }

  const calcInjury = (injury) => {
    asignedInjuries.push(injury);
  }

  const calcCharacteristicData = (characteristicData) => {
    asignedCharacteristicData.push(characteristicData);
  }


  switch (shortName) {
    case "M":
      injuries.filter(i => i.injuryTypeCode === 'RC-1M').map(i => calcInjury(i))
      characteristicData.filter(i => i.typeCode === 'A+1M').map(i => calcCharacteristicData(i))
      break;
    case "F":
      injuries.filter(i => i.injuryTypeCode === 'RC-1F').map(i => calcInjury(i))
      characteristicData.filter(i => i.typeCode === 'A+1F').map(i => calcCharacteristicData(i))
      break;
    case "Ag":
      injuries.filter(i => i.injuryTypeCode === 'RC-1AG').map(i => calcInjury(i))
      characteristicData.filter(i => i.typeCode === 'A+1AG').map(i => calcCharacteristicData(i))
      break;
    case "Cp":
      injuries.filter(i => i.injuryTypeCode === 'RC-1CP').map(i => calcInjury(i))
      characteristicData.filter(i => i.typeCode === 'A+1CP').map(i => calcCharacteristicData(i))
      break;
    case "Ar":
      injuries.filter(i => i.injuryTypeCode === 'RC-1AR').map(i => calcInjury(i))
      characteristicData.filter(i => i.typeCode === 'A+1AR').map(i => calcCharacteristicData(i))
      break;
    default :
      break;
  }

  if (value.delta < 0) {
    valueState = reverseColor?"more":"less";
  } else if (value.delta > 0) {
    valueState = reverseColor?"less":"more";
  }

  if (disabled) {
    componentState = "disabled";
  }

  function getInjuriesAndCaracteristics(injuries, caracteristicsData) {
    return (
      <Stack>
        {injuries.filter(i => (i.injuryTypeCode !== 'DEAD' && i.injuryTypeCode !== 'MissNextGame' && i.injuryTypeCode !== 'BP')).map(i => {
          return (
            <div>
              <span style={{color: '#FF7979', fontWeight: "bolder"}}>-1</span>
              <span> {i.injuryTypeName}</span>
            </div>
          )
        })}
        {caracteristicsData.map(i => {
          return (
            <div>
              <span style={{color: '#67CE67', fontWeight: "bolder"}}>+1</span>
              <span> {i.typeName}</span>
            </div>
          )
        })}
      </Stack>
    )
  }

  if (asignedInjuries.length > 0 || asignedCharacteristicData.length > 0) {
    return (
      <ClickAwayListener onClickAway={handleTooltipClose}>
        <Tooltip
          PopperProps={{
            disablePortal: true,
          }}
          onClose={handleTooltipClose}
          open={openTT}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          title={getInjuriesAndCaracteristics(asignedInjuries, asignedCharacteristicData)}
          arrow
        >
          <PlayerCaracteristiquesRoot onClick={handleBoxClick} style={{cursor: 'pointer'}}
                                      className={"bbsa-player-caracteristitique-root"}>
            <Stack>
              <PlayerCaracteristiquesTitle state={componentState} variant={'div'}
                                           className={'bbsa-player-caracteristitique-shortname-content'}>{shortName}</PlayerCaracteristiquesTitle>
              <PlayerCaracteristiquesValue state={valueState} align={"center"} variant={'div'}
                                           className={'bbsa-player-caracteristitique-value '}>{value.value}{suffix}</PlayerCaracteristiquesValue>
            </Stack>
          </PlayerCaracteristiquesRoot>
        </Tooltip>
      </ClickAwayListener>
    );
  } else {
    return (
      <PlayerCaracteristiquesRoot className={"bbsa-player-caracteristitique-root"}>
        <Stack>
          <PlayerCaracteristiquesTitle state={componentState} variant={'div'}
                                       className={'bbsa-player-caracteristitique-shortname-content'}>{shortName}</PlayerCaracteristiquesTitle>
          <PlayerCaracteristiquesValue align={"center"} variant={'div'}
                                       className={'bbsa-player-caracteristitique-value '}>{value.value}{suffix}</PlayerCaracteristiquesValue>
        </Stack>
      </PlayerCaracteristiquesRoot>
    );
  }

}

Caracteristique.propTypes = {
  name: PropTypes.string,
  shortName: PropTypes.string,
  value: PropTypes.any
};


const PlayerCaracteristiquesRoot = styled(Box)(({theme}) => ({
  backgroundColor: "lightgrey",
  border: "darkgrey 1px solid",
  width: "32px",
  height: "32px"
}))

const PlayerCaracteristiquesTitle = styled(Typography)(({theme, state}) => ({
  fontSize: '0.5em',
  color: "white",
  backgroundColor: state === 'default' ? 'orange' : (state === 'disabled' ? 'grey' : "")
}))

const PlayerCaracteristiquesValue = styled(Typography)(({theme, state}) => ({
  fontSize: '0.8em',
  fontFamily: theme.typography.fontFamily,
  color: state === 'normal' ? 'blue' : (state === 'less' ? 'red' : (state === 'more' ? 'green' : theme.palette.text.primary))
}))

export default PlayerCaracteristiques;
