import CommonService from "./CommonService";

class SeasonService extends CommonService {
  async getCurrent() {
    return this.request({
      url: process.env.REACT_APP_API_URL + '/seasons/current',
      method: 'GET'
    })
  }

  async get(id) {
    return this.request({
      url: process.env.REACT_APP_API_URL + '/seasons/' + id,
      method: 'GET'
    })
  }

  async getLastNews(seasonId, numberOfNews = 3) {
    return this.request({
      url: process.env.REACT_APP_API_URL + '/seasons/' + seasonId + '/news?numberOfNews=' + numberOfNews,
      method: 'GET'
    })
  }


  getAllNewsOfSeason(id) {
    return this.getLastNews(id, 0)
  }
}

export default new SeasonService();
