import CommonService from "./CommonService";

class TeamsService extends CommonService {
    async get(id) {
        return this.request({
            url: process.env.REACT_APP_API_URL + '/teams/' + id,
            method: 'GET'
        })
    }

    async buyPlayer(data, accessToken) {
        return this.request({
            url: process.env.REACT_APP_API_URL + '/teams/' + data.teamId + '/player?message',
            method: 'POST',
            body: JSON.stringify(data),
            token: accessToken
        }, 'application/json', 'none')
    }

    buyStaff(data, accessToken) {
        return this.request({
            url: process.env.REACT_APP_API_URL + '/teams/' + data.teamId + '/staff?message',
            method: 'POST',
            body: JSON.stringify(data),
            token: accessToken
        }, 'application/json', 'none')
    }

    async revokePlayer(teamId, playerId, accessToken) {
        return this.request({
            url: process.env.REACT_APP_API_URL + '/teams/' + teamId + '/player/' + playerId + '?message',
            method: 'DELETE',
            token: accessToken
        }, 'application/json', 'none')
    }

    async buyCaracteristique(teamId, playerId, caracteristique, random, accessToken) {
        const data = {
            playerId: playerId,
            characteristicImprovementTypeId: caracteristique ? caracteristique.id : 0,
            serverRandom: random
        }


        return this.request({
            url: process.env.REACT_APP_API_URL + '/teams/' + teamId + '/player/' + playerId + '/characteristic?message',
            method: 'POST',
            body: JSON.stringify(data),
            token: accessToken
        })
    }

    async buyCompetence(teamId, playerId, competence, random, serverRandom, type, accessToken) {
        const data = {
            playerId: playerId,
            skillId: serverRandom ? 0 : competence.id,
            random: random,
            serverRandom: serverRandom,
            primary: type.code === "P" ? true : false
        }

        return this.request({
            url: process.env.REACT_APP_API_URL + '/teams/' + teamId + '/player/' + playerId + '/player_skill?message',
            method: 'POST',
            body: JSON.stringify(data),
            token: accessToken
        })
    }

    async getWaitingImprovement(teamId, playerId, code, accessToken) {
        return this.request({
            url: process.env.REACT_APP_API_URL + '/teams/' + teamId + '/player/' + playerId + '/amelioration/claim/' + code + '?message',
            method: 'GET',
            token: accessToken
        })
    }

    async claimCaracteristique(code, caracteristique, teamId, playerId, accessToken) {
        const data = {
            playerId: playerId,
            characteristicImprovementTypeId: caracteristique.id,
            random: false,
        }

        return this.request({
            url: process.env.REACT_APP_API_URL + '/teams/' + teamId + '/player/' + playerId + '/characteristic/claim/' + code + '?message',
            method: 'POST',
            body: JSON.stringify(data),
            token: accessToken
        }, 'application/json', 'none')
    }

    claimSkill(code, skill, teamId, playerId, accessToken) {
        const data = {
            playerId: playerId,
            skillId: skill.id,
            random: false,
            primary: false
        }

        return this.request({
            url: process.env.REACT_APP_API_URL + '/teams/' + teamId + '/player/' + playerId + '/skill/claim/' + code + '?message',
            method: 'POST',
            body: JSON.stringify(data),
            token: accessToken
        }, 'application/json', 'none')
    }

    async getWaitingImprovementsToApprove(accessToken) {
        return this.request({
            url: process.env.REACT_APP_API_URL + '/admin/improvements/notapproved?message',
            method: 'GET',
            token: accessToken
        })
    }

    validateWaitingImprovement(code, validate, accessToken) {
        if (validate) {
            return this.request({
                url: process.env.REACT_APP_API_URL + '/admin/improvements/' + code + '/validated?message',
                method: 'POST',
                token: accessToken
            }, 'application/json', 'none')
        } else {
            return this.request({
                url: process.env.REACT_APP_API_URL + '/admin/improvements/' + code + '/refused?message',
                method: 'POST',
                token: accessToken
            }, 'application/json', 'none')

        }
    }

    addJourneymen(id, dataJourneymen, token) {
        return this.request({
            url: process.env.REACT_APP_API_URL + '/teams/' + id + '/journeyman',
            token: token,
            method : 'PUT',
            body : JSON.stringify(dataJourneymen)
        }, 'application/json', 'none')
    }
}

export default new TeamsService();
