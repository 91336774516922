import React, {Fragment, useEffect, useState} from "react";
import {Dialog, DialogContent, DialogContentText, DialogTitle, IconButton} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import TeamSelectionButton from "./TeamSelectionButton";
import InjurySelectionButton from "./InjurySelectionButton";
import PlayerSelectionButton from "./PlayerSelectionButton";
import EventBreadcrumbs from "./EventBreadcrumbs ";
import useGameboard from "../../contexts/gameboard/useGameboard";
import {EventButton} from "./EventSelectionStyles";
import useGame from "../../contexts/game/useGame";

const styleModal = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '70%',
  height: '70%',
  maxWidth: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const PUBLIC = {
  positionalName : "Publique",
  name : null,
  id : null
}

function EventTypeButton(props) {
  const {activeTeam, team1, team2, getAvailablePlayers} = useGameboard();
  const {getTeamFromPlayer, getOpposedTeam, getTeam} = useGame();
  const {
    type,
    game,
    onClick = (e, t, p, i) => {
    },
    hasVictim = false,
    victim,
    victimTeam,
    disabled = false
  } = props;

  const [openTeams, setOpenTeams] = useState(false);
  const [openInjuries, setOpenInjuries] = useState(false);
  const [injuries, setInjuries] = useState(null);
  const [initiatorPlayers, setInitatorPlayers] = useState([]);
  const [initiatorTeam, setInitatorTeam] = useState(null);
  const [mainInjury, setMainInjury] = useState(null);

  const handleOpenTeams = () => {
    setInjuries(null);
    setMainInjury(null);
    if (type.linkedInjuryTypes.length > 0) {
      if (type.linkedInjuryTypes.filter(li => li.automatic === false).length === 0) {
        handleOnClickInjurySelection(null);
      } else {
        setOpenInjuries(true);
      }
    } else {
      if (type.eventWay === 'ALL' || (type.eventWay === 'NO_ACTIVE' && !activeTeam.id)) {
        setOpenTeams(true);
      } else if (type.eventWay === "ACTIVE") {
        if (type.playerSelection) {
          setOpenTeams(true)
        } else {
          handleOnClickPlayerActiveTeam(activeTeam.players[0])
        }
      } else if (type.eventWay === "OPPOSED") {
        if (type.playerSelection) {
          setOpenTeams(true)
        } else {
          handleOnClickPlayerOpposedTeam(getOpposedTeam(activeTeam).players[0])
        }
      }
    }
  }
  const handleCloseTeams = () => {
    setOpenTeams(false);
  }

  const handleOnClickTeamSelection = (team, player) => {
    handleCloseTeams();
    onClick(type, team, player, injuries);
  }

  const handleOnClickPlayerActiveTeam = (player) => {
    handleCloseTeams();
    onClick(type, activeTeam, player, injuries);
  }


  const handleOnClickPlayerOpposedTeam = (player) => {
    handleCloseTeams();
    onClick(type, getOpposedTeam(activeTeam.id), player, injuries);
  }

  const handleOnClickPlayer = (player) => {
    handleCloseTeams();
    let team = game.teamParticipations.filter(tp => tp.team.id !== victimTeam.id);
    onClick(type, team, player, injuries);
  }

  const handleOnClickPublic = (player) => {
    let team = game.teamParticipations.filter(tp => tp.team.id !== victimTeam.id);
    onClick(type, team, player, injuries);
  }


  const handleOnClickInjurySelection = (injury) => {
    let i = [];
    type.linkedInjuryTypes.filter(li => li.automatic === true).map((li) => {
      i.push(li.injuryType);
    });
    if (injury) {
      i.push(injury);
    }
    setInjuries(i);
    setMainInjury(injury);
    handleCloseInjuries();
    setOpenTeams(true);
  }

  const handleCloseInjuries = () => {
    setOpenInjuries(false);
  }

  useEffect(() => {
    if (victimTeam !== null) {
      setInitatorPlayers([]);
      setInitatorTeam(null)
      let initiatorTeam = game.teamParticipations.filter(tp => tp.team.id !== victimTeam.id);
      let team = getTeam(initiatorTeam[0].team.id);
      setInitatorPlayers(team.players);
      setInitatorTeam(team)
    }
  }, [victimTeam])

  return (
    <Fragment>
      <EventButton className={"bbsa-event-type-button"} onClick={handleOpenTeams} fullWidth variant={"contained"}
                   color={"success"} disabled={disabled}
      >{type.name}</EventButton>
      <Dialog
        open={openTeams}
        onClose={handleCloseTeams}
        scroll={'paper'}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <Fragment>
          {victim &&
            <Fragment>
              <DialogTitle id="scroll-dialog-title" align={"right"}>
                <IconButton
                  size="large"
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                  onClick={handleCloseTeams}
                >
                  <CloseIcon/>
                </IconButton>
                <EventBreadcrumbs category={null} event={type} team={initiatorTeam} player={null}
                                  victim={victim} victimTeam={victimTeam} injury={mainInjury}/>
              </DialogTitle>
              <DialogContent dividers={true}>
                <DialogContentText
                  id="scroll-dialog-description"
                  tabIndex={-1}
                >
                  {type.publicSelection &&
                      <PlayerSelectionButton onClick={handleOnClickPublic}
                                         key={"teams-public"}
                                         player={PUBLIC}/>
                  }

                  {getAvailablePlayers(initiatorPlayers).map((value) => {
                    return (<PlayerSelectionButton onClick={handleOnClickPlayer}
                                                   key={"player-" + value.id} player={value}/>)
                  })}
                </DialogContentText>
              </DialogContent>
            </Fragment>
          }
        </Fragment>
        <Fragment>
          {!victim &&
            <Fragment>
              {(type.eventWay === 'ACTIVE') &&
                <Fragment>
                  <DialogTitle id="scroll-dialog-title" align={"right"}>
                    <IconButton
                      size="large"
                      edge="start"
                      color="inherit"
                      aria-label="menu"
                      onClick={handleCloseTeams}
                    >
                      <CloseIcon/>
                    </IconButton>
                    <EventBreadcrumbs category={null} event={type} team={activeTeam} player={null}
                                      victim={null} victimTeam={null}/>
                  </DialogTitle>
                  <DialogContent dividers={true}>
                    <DialogContentText
                      id="scroll-dialog-description"
                      tabIndex={-1}
                    >
                      {getAvailablePlayers(activeTeam.players).map((value) => {
                        return (<PlayerSelectionButton onClick={handleOnClickPlayerActiveTeam}
                                                       key={"teams-" + value.id}
                                                       player={value}/>)
                      })}
                    </DialogContentText>
                  </DialogContent>
                </Fragment>
              }
              {type.eventWay === 'OPPOSED' &&
                <Fragment>
                  <DialogTitle id="scroll-dialog-title" align={"right"}>
                    <IconButton
                      size="large"
                      edge="start"
                      color="inherit"
                      aria-label="menu"
                      onClick={handleCloseTeams}
                    >
                      <CloseIcon/>
                    </IconButton>
                    <EventBreadcrumbs category={null} event={type}
                                      team={getOpposedTeam(activeTeam)}
                                      player={null} victim={null} victimTeam={null}/>
                  </DialogTitle>
                  <DialogContent dividers={true}>
                    <DialogContentText
                      id="scroll-dialog-description"
                      tabIndex={-1}
                    >
                      {getAvailablePlayers(getOpposedTeam(activeTeam).players).map((value) => {
                        return (<PlayerSelectionButton onClick={handleOnClickPlayerOpposedTeam}
                                                       key={"teams-" + value.id}
                                                       player={value}/>)
                      })}
                    </DialogContentText>
                  </DialogContent>
                </Fragment>
              }
              {(type.eventWay === 'ALL' || (type.eventWay === 'NO_ACTIVE' && !activeTeam.id)) &&
                <Fragment>
                  <DialogTitle id="scroll-dialog-title" align={"right"}>
                    <IconButton
                      size="large"
                      edge="start"
                      color="inherit"
                      aria-label="menu"
                      onClick={handleCloseTeams}
                    >
                      <CloseIcon/>
                    </IconButton>
                    <EventBreadcrumbs category={null} event={type} team={initiatorTeam}
                                      player={null}
                                      victim={victim} victimTeam={victimTeam}/>
                  </DialogTitle>
                  <DialogContent dividers={true}>
                    <DialogContentText
                      id="scroll-dialog-description"
                      tabIndex={-1}
                    >
                      {game.teamParticipations.map((value) => {
                        return (<TeamSelectionButton onClick={handleOnClickTeamSelection}
                                                     key={"teams-" + value.id}
                                                     teamParticipation={value} event={type}
                                                     selectPlayer={type.playerSelection}
                        />)
                      })}
                    </DialogContentText>
                  </DialogContent>
                </Fragment>
              }
            </Fragment>
          }
        </Fragment>
      </Dialog>

      <Dialog
        open={openInjuries}
        onClose={handleCloseInjuries}
        scroll={'paper'}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title" align={"right"}>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={handleCloseInjuries}
          >
            <CloseIcon/>
          </IconButton>
          <EventBreadcrumbs category={null} event={type} team={initiatorTeam} player={null}
                            victim={victim} victimTeam={victimTeam} hasInjury/>
        </DialogTitle>
        <DialogContent dividers={true}>
          <DialogContentText
            id="scroll-dialog-description"
            tabIndex={-1}
          >
            {type.linkedInjuryTypes.filter(li => li.automatic === false).map((value) => {
              return (<InjurySelectionButton onClick={handleOnClickInjurySelection}
                                             key={"teams-" + value.id} injury={value.injuryType}/>)
            })}
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </Fragment>
  )
}

export default EventTypeButton;

