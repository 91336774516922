import React from "react";
import {EventButton} from "./EventSelectionStyles";


const styleModal = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '70%',
  height: '70%',
  maxWidth: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

function InjurySelectionButton(props) {
  const {
    injury, onClick = (i) => {
    }
  } = props;

  const handleOnClick = () => {
    onClick(injury);
  }

  return (
    <React.Fragment>
      <EventButton className={"bbsa-injury-selection-button"} onClick={handleOnClick} fullWidth variant={"contained"}
                   color={"success"}>{injury.name}</EventButton>
    </React.Fragment>
  )
}

export default InjurySelectionButton;
