import {useContext} from "react";
import {CurrentSeasonContext} from "./SeasonContext";
import {SeasonService} from "../../../services";


export default function useSeason() {
  const {season, setSeason} = useContext(CurrentSeasonContext);

  function changeSeason(id) {
    SeasonService.get(id).then(season => {
      setSeason(season);
    })
  }

  return {season, changeSeason}
}
