import React, {Fragment, useState} from "react";
import TeamBanner from "../components/TeamBanner/TeamBanner";
import {Box, Container, Grid, Hidden, styled} from "@mui/material";
import InfoTabs from "../components/InfoTabs/InfoTabs";
import InfoTab from "../components/InfoTab/InfoTab";
import {TabContext, TabPanel} from "@mui/lab";
import {useParams} from "react-router-dom";
import StaffDetails from "../components/StaffDetails/StaffDetails";
import TeamDetailsCards from "../components/TeamDetails/TeamDetailsCards";
import BbsaCard from "../components/BbsaCard/BbsaCard";
import {TeamContext} from "../contexts/team/TeamContext";
import useTeam from "../contexts/team/useTeam";
import ButtonAdd from "../components/ButtonAdd/ButtonAdd";
import AddPlayerDialog from "../components/AddPlayer/AddPlayerDialog";
import AddJourneymanDialog from "../components/AddPJourneymanDialog/AddJourneymanDialog";
import {useSnackbar} from "notistack";
import Banner from "../components/Banner/Banner";


const TeamPageStyled = styled("div")(({theme}) => ({
  [".MuiTabPanel-root"]: {
    padding: 0
  },
  [theme.breakpoints.down("sm")]: {}
}))


export default function TeamPage(props) {
  const {id} = useParams();
  return (
    <TeamContext id={id}>
      <ContextTeamPage {...props}/>
    </TeamContext>
  )
}

function ContextTeamPage(props) {
  const {id} = useParams();

  const {team, isMyTeam, refreshTeam} = useTeam();
  const { enqueueSnackbar } = useSnackbar();

  const [value, setValue] = React.useState('1');
  const [addPlayerDialogOpen, setAddPlayerDialogOpen] = useState(false);
  const [addJourneymanDialogOpen, setAddJourneymanDialogOpen] = useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleOnClickAddPlayer = () => {
    setAddPlayerDialogOpen(true);
  }

  const handleCloseAddPlayer = () => {
    setAddPlayerDialogOpen(false);
  }

  const handleAddPlayerSuccess = (positional, name, number) => {
    enqueueSnackbar("Joueur [" + positional.name + "] acheté!", {variant : "success"});
    refreshTeam()
  }

  const handleAddPlayerError = (error) => {
    enqueueSnackbar("Erreur lors de l'achat d'un joueur dans l'équipe : " + error.message, {variant : "error"});
  }

  const handleOnClickAddJourneyman = () => {
    setAddJourneymanDialogOpen(true);
  }

  const handleCloseAddJourneyman = () => {
    setAddJourneymanDialogOpen(false);
  }

  const handleAddJourneymanSuccess = () => {
    enqueueSnackbar("Journeyman acheté(s)!", {variant : "success"});
    refreshTeam()
  }

  const handleAddJourneymanError = (error) => {
    enqueueSnackbar("Erreur Journeyman : " + error.message, {variant : "error"});
  }

  return (
    <TeamPageStyled>
      {team &&
        <Fragment>
          <Hidden mdUp>
            <TeamBanner team={team}/>
          </Hidden>
          <Hidden mdDown>
            <Container maxWidth={"lg"} sx={{paddingTop: '20px'}}>
              <Grid container spacing={1}>
                <Grid item xs={12} md={6}>
                  <BbsaCard>
                    <TeamBanner team={team}/>
                  </BbsaCard>
                </Grid>
                <Grid item xs={6} md={3} alignItems="stretch">
                    <StaffDetails title={"Valeurs"} variant={"outlined"} sx={{zIndex:1}} showStaff={false}/>
                </Grid>
                <Grid item xs={6} md={3} alignItems="stretch">
                    <StaffDetails title={"Staff"} variant={"outlined"} sx={{zIndex:1}} showTreasury={false}/>
                </Grid>
              </Grid>
            </Container>
          </Hidden>
          <TabContext value={value}>
            <Container maxWidth={"lg"} sx={{paddingTop: '20px'}}>
              <Box>
                <InfoTabs
                  value={value}
                  onChange={handleChange}
                  variant="scrollable"
                  centered

                >
                  <InfoTab label="Effectif" value="1"/>
                  <InfoTab label="Staff" value="2" sx={{display: {md: "none"}}}/>
                  <InfoTab label="Valeurs" value="3" sx={{display: {md: "none"}}}/>
                  <InfoTab label="Historique" value="4"/>
                </InfoTabs>
              </Box>
            </Container>
            <TabPanel value="1">
              <Fragment>
                <Hidden mdUp>
                  {isMyTeam &&
                    <Fragment>
                      <AddPlayerDialog open={addPlayerDialogOpen} onClose={handleCloseAddPlayer} onAddSuccess={handleAddPlayerSuccess} onAddError={handleAddPlayerError}/>
                      <AddJourneymanDialog open={addJourneymanDialogOpen} onClose={handleCloseAddJourneyman} onAddSuccess={handleAddJourneymanSuccess} onAddError={handleAddJourneymanError}/>
                      <ButtonAdd onClick={handleOnClickAddPlayer}>Acheter un joueur</ButtonAdd>
                      <ButtonAdd onClick={handleOnClickAddJourneyman}>Ajouter des journaliers</ButtonAdd>
                    </Fragment>
                  }
                  <TeamDetailsCards/>
                </Hidden>
                <Hidden mdDown>
                  <Container maxWidth={"lg"}>
                    <BbsaCard>
                      {isMyTeam &&
                        <Fragment>
                          <AddPlayerDialog open={addPlayerDialogOpen} onClose={handleCloseAddPlayer} onAddSuccess={handleAddPlayerSuccess} onAddError={handleAddPlayerError}/>
                          <AddJourneymanDialog open={addJourneymanDialogOpen} onClose={handleCloseAddJourneyman} onAddSuccess={handleAddJourneymanSuccess} onAddError={handleAddJourneymanError}/>
                          <ButtonAdd onClick={handleOnClickAddPlayer}>Acheter un joueur</ButtonAdd>
                          <ButtonAdd onClick={handleOnClickAddJourneyman}>Ajouter des journaliers</ButtonAdd>
                        </Fragment>
                      }
                      <TeamDetailsCards/>
                    </BbsaCard>
                  </Container>
                </Hidden>
              </Fragment>
            </TabPanel>
            <TabPanel value="2">
              <StaffDetails variant={"outlined"} showTreasury={false}/>
            </TabPanel>
            <TabPanel value="3">
              <StaffDetails variant={"outlined"} showStaff={false}/>
            </TabPanel>
          </TabContext>
        </Fragment>
      }
    </TeamPageStyled>
  )
}
