import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography} from "@mui/material";
import useGameboard from "../../contexts/gameboard/useGameboard";
import {Fragment, useEffect, useState} from "react";


export default function GameHalftimeDialog(props) {
  const {turn} = useGameboard();

  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");

  const {
    open = false,
    onClose = ()=>{},
  } = props;

  useEffect(() => {
    switch (turn.halftime) {
      case 1:
        setTitle("");
        break;
      case 2 :
        setTitle("Mi-temps");
        setContent(<InfoMiTemps/>);
        break;
      case 3 :
        setTitle("Fin du match");
        setContent(<InfoFinMatch/>);
        break;
    }
  }, [turn])


  return (
    <Dialog open={open}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {content}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant={"contained"} color={"secondary"} onClick={onClose}>Fermer</Button>
      </DialogActions>
    </Dialog>
  )
}

const InfoMiTemps = () => {
  return (
    <Fragment>
      <Typography>Tous les joueurs retourent au vestiaire!!</Typography>
      <Typography>N'oubliez pas de : </Typography>
      <Typography>
        <ul>
          <li>Rétablir les joueurs K-O (Jet D6 par joueur K-O. sur 4+, le joueur s'sest rétabli et est impatient de
            remettre le couvert
          </li>
        </ul>
      </Typography>
    </Fragment>
  )
}

const InfoFinMatch = () => {
  return (
    <Fragment>
      <Typography>Fin du match</Typography>
      <Typography>N'oubliez pas de : </Typography>
      <Typography>
        <ul>
          <li>Compter la trésorerie</li>
          <li>définir le joueur du match de chaque équipe(encodable dans la liste des évenement, section fin du match)
          </li>
        </ul>
      </Typography>
    </Fragment>
  )
}
