import React, {Fragment, useEffect, useState} from "react";
import {styled, Typography} from "@mui/material";
import TimelineItem from "@mui/lab/TimelineItem";
import {TimelineConnector, TimelineContent, TimelineDot, TimelineOppositeContent, TimelineSeparator} from "@mui/lab";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import useGame from "../../contexts/game/useGame";
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import {GameEventsTimeLineItemContent} from "./GameEventsTimeLineStyles";

const SIDE_LEFT = "LEFT"
const SIDE_RIGHT = "RIGHT"

export default function GameEventTimeLineItem(props) {
  const {isInTeam, team1} = useGame();
  const {event} = props;
  const [side, setSide] = useState(null);
  const [eventIcon, setEventIcon] = useState(<Fragment/>)

  useEffect(() => {
    let side = SIDE_RIGHT;

    if (isInTeam(team1, event.initiator)) {
      side = SIDE_LEFT;
    }

    if (event.eventCode === "Touchdown") {
      setEventIcon(<EmojiEventsIcon fontSize={"small"}/>);
    } else if (event.categoryCode === "Elimination") {
      if (side === SIDE_RIGHT) {
        setEventIcon(<KeyboardDoubleArrowLeftIcon fontSize={"small"}/>);
      } else {
        setEventIcon(<KeyboardDoubleArrowRightIcon fontSize={"small"}/>);
      }
    }

    setSide(side)
  }, [event])

  return (
    <Fragment>
      <TimelineItem>
        <TimelineOppositeContent className={"bbsa-game-events-timeline-item-root left"}
                                 sx={{py: '12px', px: 2, position: "relative"}}>
          <EventTimelineContent align={"right"} side={side} initiatorSide={SIDE_LEFT} victimSide={SIDE_RIGHT}
                                event={event}/>
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineConnector/>
          <TimelineDot variant="outlined">
            {eventIcon}
          </TimelineDot>
          <TimelineConnector/>
        </TimelineSeparator>
        <TimelineContent className={"bbsa-game-events-timeline-item-root right"}
                         sx={{py: '12px', px: 2, position: "relative"}}>
          <EventTimelineContent align={"left"} side={side} initiatorSide={SIDE_RIGHT} victimSide={SIDE_LEFT}
                                event={event}/>
        </TimelineContent>
      </TimelineItem>
    </Fragment>
  )
}


function EventTimelineContent(props) {
  const {getPlayer} = useGame();
  const {side = "NONE", initiatorSide = "NONE", victimSide = "NONE", event, align} = props
  const [initiatorEventName, setInitiatorEventName] = useState("");
  const [victimEventName, setVictimEventName] = useState("");
  const [initiator, setInitiator] = useState(null);
  const [victim, setVictim] = useState(null);

  useEffect(() => {
    let initPlayer = getPlayer(event.initiator);
    if(initPlayer===null){
      initPlayer = {
        number : null,
        positionalName:"Publique"
      }
    }
    setInitiator(initPlayer)
    setVictim(getPlayer(event.victim))

    if (event.victim) {
      setInitiatorEventName(event.categoryName)
      setVictimEventName(event.name)
    } else {
      setInitiatorEventName(event.name)
    }
  }, [event])

  return (
    <GameEventsTimeLineItemContent align={align} className={"bbsa-game-events-timeline-item-content"}>
      {(side === initiatorSide) &&
        <Fragment>
          <PrimaryInitiatorLabel className={"bbsa-game-event-timeline-item-title-primary"}>
            {initiatorEventName}
          </PrimaryInitiatorLabel>
          <SecondaryInitiatorLabel
            className={"bbsa-game-event-timeline-item-title-secondary initiator"}>#{initiator.number} {initiator.positionalName}</SecondaryInitiatorLabel>
        </Fragment>
      }
      {(side === victimSide && victim) &&
        <Fragment>
          <PrimaryVictimLabel className={"bbsa-game-event-timeline-item-title-primary"}>
            {victimEventName}
          </PrimaryVictimLabel>
          <SecondaryVictimLabel
            className={"bbsa-game-event-timeline-item-title-secondary victim"}>#{victim.number} {victim.positionalName}</SecondaryVictimLabel>
        </Fragment>
      }
    </GameEventsTimeLineItemContent>
  )
}

const PrimaryInitiatorLabel = styled(Typography)(({theme}) => ({
  [theme.breakpoints.down("sm")]: {}
}))

const SecondaryInitiatorLabel = styled(Typography)(({theme}) => ({
  fontSize: "0.7rem",
  color: "#1a9aef",
  [theme.breakpoints.down("sm")]: {}
}))

const PrimaryVictimLabel = styled(Typography)(({theme}) => ({
  [theme.breakpoints.down("sm")]: {}
}))

const SecondaryVictimLabel = styled(Typography)(({theme}) => ({
  fontSize: "0.7rem",
  color: "#e71313",
  [theme.breakpoints.down("sm")]: {}
}))


