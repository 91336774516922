import React, {Fragment} from "react";
import {Container, Hidden} from "@mui/material";
import UsersList from "../components/UsersList/UsersList";

export default function ManageUsersPage(props) {
  return (
    <Fragment>
      <Hidden mdDown={"sm"}>
        <Container maxWidth={"lg"} className={'content-bb'}>
          <UsersList></UsersList>
        </Container>
      </Hidden>
      <Hidden mdUp={"sm"}>
        <UsersList></UsersList>
      </Hidden>
    </Fragment>
  )
}
