import React, {Fragment, useEffect, useState} from "react";
import PhasesSlider from "../components/PhaseSlider/PhasesSlider";
import {Container, Stack} from "@mui/material";
import PhaseService from "../../services/PhaseService";
import Rank from "../components/Rank/Rank";
import Tournament from "../components/Tournament/Tournament";

export default function RanksPage(props) {
  const [phaseSelected, sePhaseSelected] = useState(null);
  const [ranks, setRanks] = useState(null);

  const handlePhaseSelected = (phase) => {
    sePhaseSelected(phase);
  }

  useEffect(() => {
    if (phaseSelected !== null) {
      PhaseService.getPhase(phaseSelected.id).then((p) => {
        setRanks(p.filter((pool) => !pool.interPool));
      })
    }
  }, [phaseSelected])

  return (
    <Fragment>
      <Container maxWidth={"md"} sx={{paddingTop: '20px', marginBottom: 2}}>
        <PhasesSlider onPhaseSelected={handlePhaseSelected}/>
      </Container>
      <Container maxWidth={"lg"}>
        <Stack
          spacing={3}
        >
          {phaseSelected && phaseSelected.phaseType==='TOURNAMENT' &&
            <Tournament phase={phaseSelected}/>
          }
          {phaseSelected && phaseSelected.phaseType!=='TOURNAMENT' &&
            <Fragment>
              {ranks && ranks.map((rank) =>
                <Rank key={rank.id} rankId={rank.id}/>
              )}
            </Fragment>
          }
        </Stack>
      </Container>
    </Fragment>
  )
}
