import {Box, Button, Stack, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import SportsIcon from '@mui/icons-material/Sports';
import GamesService from "../../../services/GamesService";
import useSession from "../../contexts/session/useSession";


function GameStart(props) {
  const {currentSession} = useSession();
  const {
    gameId, onGameStarted = () => {
    }
  } = props;
  const [gameLoaded, setGameLoaded] = useState(false);
  const [game, setGame] = useState(null);

  useEffect(() => {
    if (!gameLoaded) {
      GamesService.get(gameId).then((game) => {
        setGame(game);
        setGameLoaded(true)
      })
    }
  }, [gameLoaded, gameId])

  if (!gameLoaded) {
    return (
      <Box>
        <div>Chargement</div>
      </Box>
    )
  }

  const handleStartClick = () => {
    GamesService.startGame(gameId, currentSession.accessToken).then((response) => {
      onGameStarted(response);
    })
  }

  return (
    <Box>
      <Stack spacing={2}>
        <Box textAlign={"center"}>
          <h2>Coup d'envoi</h2>
        </Box>
        <Box textAlign={"center"}>
          <Typography variant={"h6"}>
            {game.teamParticipations.filter(tp => tp.position === 1)[0].team.name} - {game.teamParticipations.filter(tp => tp.position === 2)[0].team.name}
          </Typography>
          <Typography>
            Etes-vous pret à démarrer le match ?
          </Typography>
        </Box>
        <Button variant={"contained"} onClick={handleStartClick} startIcon={<SportsIcon/>}>Siffler</Button>
      </Stack>
    </Box>
  )
}

export default GameStart;
