import TournamentContainer from "./TournamentContainer";

export default function Tournament(props) {
    const {phase} = props;

    return (
        <TournamentContainer phase={phase}></TournamentContainer>

        // <Fragment>
        //     <Box>
        //         <Grid container>
        //             <Grid item xs={3}>
        //                 <TournamentStep>
        //                     <TournamentGame team1={team1} team2={team2} left start/>
        //                     <TournamentGame team1={team3} team2={team4} left start/>
        //                     <TournamentGame team1={team1} team2={team2} left start/>
        //                     <TournamentGame team1={team3} team2={team4} left start/>
        //                     <TournamentGame team1={team1} team2={team2} left start/>
        //                     <TournamentGame team1={team3} team2={team4} left start/>
        //                     <TournamentGame team1={team1} team2={team2} left start/>
        //                     <TournamentGame team1={team3} team2={team4} left start/>
        //                 </TournamentStep>
        //             </Grid>
        //             <Grid item xs={3}>
        //                 <TournamentStep>
        //                     <TournamentGame team1={team1} team2={team2} left/>
        //                     <TournamentGame team1={team3} team2={team4} left/>
        //                     <TournamentGame team1={team1} team2={team2} left/>
        //                     <TournamentGame team1={team3} team2={team4} left/>
        //                 </TournamentStep>
        //             </Grid>
        //             <Grid item xs={3}>
        //                 <TournamentStep>
        //                     <TournamentGame team1={team1} team2={team2} left/>
        //                     <TournamentGame team1={team3} team2={team4} left/>
        //                 </TournamentStep>
        //             </Grid>
        //             <Grid item xs={3}>
        //                 <TournamentStep>
        //                     <TournamentGame team1={team1} team2={team3} left/>
        //                 </TournamentStep>
        //             </Grid>
        //         </Grid>
        //     </Box>
        // </Fragment>
    )
}


