import React, {Fragment, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {GameContext} from "../contexts/game/GameContext";
import {Box, Button, Container, Stack, Typography} from "@mui/material";
import useGame from "../contexts/game/useGame";
import JourneyManSelector from "../components/JourneyManSelector/JourneyManSelector";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import {GamesService} from "../../services";
import useSession from "../contexts/session/useSession";
import { useNavigate } from "react-router-dom";

export default function BeforeGamePage (props) {
    const {id} = useParams();

    return (
        <Fragment>
            <GameContext id={id}>
                <BeforeGameContent/>
            </GameContext>
        </Fragment>
    )
}

const BeforeGameContent = (props) => {
    const {game, team1, team2} = useGame();
    const navigate = useNavigate();
    const {currentSession} = useSession();

    const [teamId1, setTeamId1] = useState(null);
    const [teamId2, setTeamId2] = useState(null);
    const [activeStep, setActiveStep] = React.useState(0);
    const [journeymenOK1, setJourneymenOK1] = React.useState(false);
    const [journeymenOK2, setJourneymenOK2] = React.useState(false);
    const [dataJourneymen, setDataJourneymen] = useState(
        {
                participations : []
            }
        )

    const handleJmChanged1 = (t, j, completed) => {
        setJourneymenOK1(completed);
        dataJourneymen.participations.filter(p => p.id === teamId1.id)[0].positionals = constructPositionals(j,getMaxPlayerNumber(team1));
        setDataJourneymen({...dataJourneymen})
    }

    const handleJmChanged2 = (t, j, completed) => {
        setJourneymenOK2(completed);
        dataJourneymen.participations.filter(p => p.id === teamId2.id)[0].positionals = constructPositionals(j,getMaxPlayerNumber(team2));
        setDataJourneymen({...dataJourneymen})
    }

    const constructPositionals = (j, initialNumber) => {
        let pos = [];
        for (let i = 0; i < j.length; i++) {
            let p = {
                positionalId : j[i],
                number : (initialNumber+i+1)
            }
            pos.push(p)
        }
        return pos;
    }

    const getMaxPlayerNumber = (team) => {
        let number = 0;
        let p = team.players.filter(p => p.active);
        for (let i = 0; i < p.length; i++) {
            if(p[i].number>number){
                number = p[i].number;
            }
        }
        return number
    }

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleStartGameClick = () => {
        GamesService.kickOffGame(game.id, currentSession.accessToken).then((response) => {
            navigate("/play/game/" + game.id)
        })
    }

    const handleValidateJourneymen = () => {
        GamesService.addJourneymen(game.id, dataJourneymen, currentSession.accessToken).then(() => {
            handleNext();
        });
    }


    useEffect(()=>{
        if(game){
            const tp1 = game.teamParticipations.filter(tp => tp.position === 1)[0];
            const tp2 = game.teamParticipations.filter(tp => tp.position === 2)[0];
            if(tp1){
                setTeamId1(tp1);
            }
            if(tp2){
                setTeamId2(tp2);
            }
            if(dataJourneymen.participations.filter(p => p.id === tp1.id).length===0){
                dataJourneymen.participations.push(
                    {
                        id : tp1.id,
                        positionals : []
                    }
                )
            }
            if(dataJourneymen.participations.filter(p => p.id === tp2.id).length===0){
                dataJourneymen.participations.push(
                    {
                        id : tp2.id,
                        positionals : []
                    }
                )
            }
        }
    }, [game])

    if(team1===null || team2===null || game===null){
        return (
            <Typography>Chargement</Typography>
        )
    }


    return (
        <Container maxWidth={"lg"} sx={{align : "center"}}>
            <Typography variant={"h4"} align={"center"}>Séquence d'avant match</Typography>
            <Stepper activeStep={activeStep} sx={{paddingBottom: "30px", borderBottom: "black 1px solid"}}>
                <Step>
                    <StepLabel>Journaliers</StepLabel>
                </Step>
                <Step>
                    <StepLabel>Coup de pouce</StepLabel>
                </Step>
                <Step>
                    <StepLabel>Démarrer</StepLabel>
                </Step>
            </Stepper>
            <Box sx={{display: (activeStep === 0 ? 'block' : 'none')}}>
                <Stack>
                    {teamId1 && <JourneyManSelector team={team1} onChanged={handleJmChanged1}/>}
                    {teamId2 && <JourneyManSelector team={team2} onChanged={handleJmChanged2}/>}
                </Stack>
            </Box>
            <Box sx={{display: (activeStep === 2 ? 'block' : 'none')}}>
                <Typography align={"center"}>La Séquence d'avant match est terminée, cliquez sur le bouton <b>VALIDER</b> pour démarrer le match.</Typography>
            </Box>
            <React.Fragment>
                <Box sx={{display: 'flex', flexDirection: 'row', pt: 2, borderTop: "black 1px solid"}}>
                    <Box sx={{flex: '1 1 auto'}}/>
                    {(activeStep === 0 || activeStep === 2) ? (
                        <Fragment>
                            {activeStep===0 &&
                                <Button variant={"contained"} disabled={(!journeymenOK1 || !journeymenOK2)} onClick={handleValidateJourneymen}>
                                    Suivant
                                </Button>
                            }
                            {activeStep===2 &&
                                <Button variant={"contained"} onClick={handleStartGameClick}>
                                    Valider
                                </Button>
                            }
                        </Fragment>
                    ) : (
                        <Button variant={"contained"} onClick={handleNext}>
                            Suivant
                        </Button>
                    )}
                </Box>
            </React.Fragment>

        </Container>
    )
}
