import React, {createContext, Fragment, useEffect, useState} from "react";
import {theme as light} from "./bloodbowl";
import {theme as dark} from "./bloodbowldark";
import Cookies from "universal-cookie";

const defaultContextValues = {theme: light}

export const CurrentThemeContext = createContext(defaultContextValues)

export const ThemeContext = (props) => {
  const [theme, setTheme] = useState(null)

  const value = {theme, setTheme};

  useEffect(()=>{
    const cookies = new Cookies();
    let bloodbowlCookie = cookies.get("emporiumBloodbowlTheme")
    if(bloodbowlCookie !== null && bloodbowlCookie !== undefined){
      if(bloodbowlCookie.mode==='dark'){
        setTheme(dark)
      } else {
        setTheme(light)
      }
    } else {
      setTheme(light)
    }
  }, [])


  useEffect(()=>{
    if(theme!==null){
      const cookies = new Cookies();
      let value = {
        mode : theme.palette.mode
      }
      cookies.set('emporiumBloodbowlTheme', value, {
        path: '/',
        maxAge: 2147483647
      })
    }
  },[theme]);


    return (
    <CurrentThemeContext.Provider value={value}>
      {theme && <Fragment>{props.children}</Fragment>}
    </CurrentThemeContext.Provider>
  )
}

