import {Box, Button, Paper, styled} from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import React from "react";


function ButtonAdd(props){
  const {
    onClick = ()=>{},
    variant = "contained",
  } = props

  return (
    <AddButtonRoot className={"bbsa-add-button-root"}>
      <Button onClick={onClick}  fullWidth color={"success"} startIcon={<AddCircleOutlineIcon />}>
        {props.children}
      </Button>
    </AddButtonRoot>
  )
}

const AddButtonRoot = styled(Paper)(({ theme, variant }) => ({
  border: "#008000 dashed 2px",
  [theme.breakpoints.down("sm")]: {
  }
}));

export default ButtonAdd;
