import React, {Fragment, useEffect, useState} from "react";
import {Divider, styled, Typography} from "@mui/material";
import GameEventTimeLineItem from "./GameEventTimeLineItem";
import GameEventTimeLineNoEvent from "./GameEventTimeLineNoEvent";

export default function GameEventTimeLineTurn(props) {
  const {turn, revert = false} = props;
  let secondteamPlaying = false;
  let isFirstEventOfTurn = true;
  const [orderedEvents, setOrderedEvents] = useState([])

  useEffect(() => {
    if (revert) {
      setOrderedEvents(turn.events.slice().reverse())
    } else {
      setOrderedEvents(turn.events);
    }
  }, [turn])

  return (
    <Fragment>
      {!revert &&
        <Divider className={"bbsa-game-event-timeline-turns-divider"}>
          <Typography className={"bbsa-game-event-timeline-turns-divider-text"}
                      align={'center'}>Tour {turn.number}</Typography>
        </Divider>
      }
      {orderedEvents.map((event) => {
        switch (event.eventCode) {
          case "EndOfTurn":
            if (!secondteamPlaying) {
              secondteamPlaying = true;
              if (!isFirstEventOfTurn && revert) {
                return <TurnDivider className={"bbsa-game-event-timeline-turn-team-divider"} variant="middle"/>
              }
            }
            break;
          case "Touchdown":
          case "Turnover":
            isFirstEventOfTurn = false;
            if (secondteamPlaying) {
              return <GameEventTimeLineItem event={event}/>
            } else {
              secondteamPlaying = true;
              return (
                <Fragment>
                  <GameEventTimeLineItem event={event}/>
                  <TurnDivider className={"bbsa-game-event-timeline-turn-team-divider"} variant="middle"/>
                </Fragment>
              )
            }
          default :
            isFirstEventOfTurn = false;
            return <GameEventTimeLineItem event={event}/>
        }
      })}
      {isFirstEventOfTurn && <GameEventTimeLineNoEvent/>}
      {revert &&
        <Divider className={"bbsa-game-event-timeline-turns-divider top"}>
          <Typography className={"bbsa-game-event-timeline-turns-divider-text"}
                      align={'center'}>Tour {turn.number}</Typography>
        </Divider>
      }
    </Fragment>
  )
}


const TurnDivider = styled(Typography)(({theme}) => ({
  border: theme.palette.text.disabled + " 1px dashed",
  marginLeft: '40%',
  marginRight: '40%',
  [theme.breakpoints.down("sm")]: {}
}))

