import Slider from "react-slick";
import {Box, Container, styled, Typography} from "@mui/material";
import React, {Fragment, useEffect, useRef, useState} from "react";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import PhaseService from "../../../services/PhaseService";
import useSeason from "../../contexts/season/useSeason";

var settings = {
  dots: false,
  infinite: false,
  centerMode: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  variableWidth: true,
  nextArrow: <NextArrow/>,
  prevArrow: <PrevArrow/>
};

function PhasesSlider(props) {
  const {season} = useSeason();
  const slider = useRef(null);
  const {
    onPhaseSelected = (phase) => {
    }
  } = props;
  const [phases, setPhases] = useState(null);
  const [activePhase, setActivePhase] = useState(null);

  useEffect(() => {
    if (phases === null) {
      PhaseService.getPhasesFromSeason(season.id).then((phases) => {
        let extendedPhase = phases.map((p, i) => {
          return {...p, position: i}
        }).slice();
        setPhases(extendedPhase);
        if (extendedPhase.length > 0) {
          var p = extendedPhase.filter(phase => phase.phaseType === 'CHAMPIONSHIP');
          if (p.length !== 0) {
            handleClickItem(p[0])
          } else {
            p = extendedPhase.filter(phase => phase.phaseType === 'EXHIBITION');
            if (p.length !== 0) {
              handleClickItem(p[0])
            } else {
              handleClickItem(extendedPhase[0])
            }
          }

        }
      })
    }
  }, [phases])


  const handleClickItem = (phase) => {
    onPhaseSelected(phase);
    setActivePhase(phase);
  }

  useEffect(() => {
    if (activePhase) {
      slider.current.slickGoTo(activePhase.position)
    }
  }, [activePhase])


  return (
    <Fragment>
      {phases !== null &&
        <PhasesSliderRoot maxWidth={'false'} className={"bbsa-phases-slider-root"}>
          <Slider {...settings} ref={slider}>
            {phases.map((phase) =>
              <Box key={"PhasesSlider_day_" + phase.id} onClick={() => handleClickItem(phase)}>
                <PhasesSliderItem active={activePhase.id === phase.id ? true : false}
                                  className={"bbsa-phases-slider-item"}>{phase.name}</PhasesSliderItem>
              </Box>
            )}
          </Slider>
        </PhasesSliderRoot>
      }
    </Fragment>
  )
}

function NextArrow(props) {
  const {className, style, onClick} = props;
  return (
    <LeftArrow className={className + " bbsa-days-slider-button"} style={{...style, display: "block"}}
               onClick={onClick}/>
  );
}

function PrevArrow(props) {
  const {className, style, onClick} = props;
  return (
    <RightArrow className={className + " bbsa-days-slider-button"} style={{...style, display: "block"}}
                onClick={onClick}/>
  );
}


const LeftArrow = styled(ArrowForwardIosIcon)(({theme}) => ({
    color: theme.palette.text.primary,
    // display: "block",
    [theme.breakpoints.down("sm")]: {}
  }
));

const RightArrow = styled(ArrowBackIosIcon)(({theme}) => ({
    color: theme.palette.text.primary,
    // display: "block",
    [theme.breakpoints.down("sm")]: {}
  }
));
const PhasesSliderRoot = styled(Container)(({theme}) => ({
    color: theme.palette.text.primary,
    [theme.breakpoints.down("sm")]: {}
  }
));

const PhasesSliderItem = styled(Typography)(({theme, active}) => ({
  fontFamily: theme.typography.fontFamily,
  borderRadius: theme.shape.borderRadius,
  paddingLeft: "10px",
  paddingRight: "10px",
  cursor: "pointer",
  color: active ? theme.palette.primary.contrastText : "",
  background: active ? (theme.palette.primary.gradient ? theme.palette.primary.gradient : theme.palette.primary.main) : "",
  [theme.breakpoints.down("sm")]: {}
}));


export default PhasesSlider;
