import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import GameBanner from "../components/GameBanner/GameBanner";
import DaysService from "../../services/DaysService";
import {TabContext, TabPanel} from "@mui/lab";
import {Box, Container, Hidden, styled} from "@mui/material";
import InfoTab from "../components/InfoTab/InfoTab";
import InfoTabs from "../components/InfoTabs/InfoTabs";
import {GameContext} from "../contexts/game/GameContext";
import useGame from "../contexts/game/useGame";
import GameEventsTimeline from "../components/GameEventsTimeline/GameEventsTimeline";
import BbsaCard from "../components/BbsaCard/BbsaCard";
import {GameStreamContext} from "../contexts/gamestream/GameStreamContext";

const GamePagetyled = styled("div")(({theme}) => ({
  [".MuiTabPanel-root"]: {
    padding: 0
  },
  [theme.breakpoints.down("sm")]: {}
}))

export function Default(props) {
  const {game, events} = useGame();
  const [day, setDay] = useState(null);
  const [value, setValue] = React.useState('1');

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (game !== null && day === null) {
      DaysService.get(game.dayId).then((day) => {
        setDay(day);
      })
    }
  }, [game])

  return (
    <GamePagetyled>
      <Hidden mdUp>
        <GameBanner game={game} day={day}/>
      </Hidden>
      <Hidden mdDown>
        <Container maxWidth={"lg"} sx={{paddingTop: '20px'}}>
          <BbsaCard>
            <GameBanner game={game} day={day}/>
          </BbsaCard>
        </Container>
      </Hidden>

      <TabContext value={value}>
        <Container maxWidth={"lg"} sx={{paddingTop: '20px'}}>
          <Box>
            <InfoTabs
              value={value}
              onChange={handleChange}
              variant="scrollable"
              centered

            >
              <InfoTab label="Evènements" value="1"/>
            </InfoTabs>
          </Box>
        </Container>
        <TabPanel value="1">
          <Hidden mdUp>
            <GameEventsTimeline events={events} variant={"contained"}/>
          </Hidden>
          <Hidden mdDown>
            <Container maxWidth={"lg"}>
              <BbsaCard>
                <GameEventsTimeline events={events} variant={"contained"}/>
              </BbsaCard>
            </Container>
          </Hidden>
        </TabPanel>
      </TabContext>
    </GamePagetyled>
  )
}

export default function GamePage(props) {
  const {id} = useParams();

  return (
    <GameContext id={id}>
      <GameStreamContext>
        <Default {...props}/>
      </GameStreamContext>
    </GameContext>
  )
}

