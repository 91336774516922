import {styled, Tab} from "@mui/material";
import React from "react";

interface
StyledTabProps
{
  label: string;
}

const StyledTab = styled((props: StyledTabProps) => (
  <Tab disableRipple {...props} />
))(({theme}) => ({
  textTransform: 'none',
  fontWeight: theme.typography.fontWeightRegular,
  fontSize: theme.typography.pxToRem(15),
  marginRight: theme.spacing(1),
  color: 'rgba(255, 255, 255, 0.7)',
  '&.MuiButtonBase-root': {
    padding: "0px",
    minHeight: "30px",
    fontFamily: theme.typography.fontFamily,
    paddingLeft: "10px",
    paddingRight: "10px",
    borderRadius: theme.shape.borderRadius,
  },
  '&.Mui-selected': {
    color: theme.palette.primary.contrastText,
  },

}));

export default function InfoTab(props) {
  return (
    <StyledTab className={"bbsa-info-tab-root"} {...props} />
  )
}
