import {Box, InputLabel, MenuItem, Select} from "@mui/material";
import {useEffect, useState} from "react";
import {styled} from "@mui/material/styles";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


export default function SelectField(props) {
  const {
    label = "",
    value,
    noSelectionLabel = "non défini",
    items = null,
    variant = 'outlined',
    fullWidth = true,
    noBorder = false,
    font = "secondary",
    noValueAllowed = false,
    labelConstructor = (item) => {
      return item.label
    },
    valueConstructor = (item) => {
      return item.value
    },
    onChanged = () => {
    }
  } = props;

  const handleSelectionChanged = (e) => {
    onChanged(e.target.value);
  }

  return (
    <SelectFieldRoot class={"bbsa-selectfield-root"} fullWidth={fullWidth} size="small">
      <InputLabel id="pool-label">{label}</InputLabel>
      {items &&
        <SelectStyled
          font={font}
          labelId="select-pool"
          id="select-pool"
          variant={variant}
          value={value}
          fullWidth={fullWidth}
          IconComponent={ExpandMoreIcon}
          label="Pool"
          onChange={handleSelectionChanged}
          noBorder={noBorder}
        >
          {noValueAllowed && <MenuItemStyled font={font} value={0}>{noSelectionLabel}</MenuItemStyled>}
          {items.map((item, i) => {
            return (
              <MenuItemStyled
                font={font}
                key={"bbsa_select_field_item-" + i}
                value={valueConstructor(item)}
              >
                {labelConstructor(item)}
              </MenuItemStyled>
            )
          })}
        </SelectStyled>
      }
    </SelectFieldRoot>
  )
}

const SelectFieldRoot = styled(Box)(({theme}) => ({
  [theme.breakpoints.down("sm")]: {}
}));

const SelectStyled = styled(Select)(({theme, font, noBorder}) => ({
  fontFamily: font === "primary" ? (theme.components.bbsa.typography.primaryFontFamily ? theme.components.bbsa.typography.primaryFontFamily : theme.typography.font) : (font === "secondary" ? (theme.components.bbsa.typography.secondaryFontFamily ? theme.components.bbsa.typography.secondaryFontFamily : theme.typography.font) : ""),
  textTransform: "uppercase",
  boxShadow: noBorder ? 'none' : '',
  '& .MuiOutlinedInput-notchedOutline': {
    border: noBorder ? 0 : "",
  },
  'p': {
    fontFamily: font === "primary" ? (theme.components.bbsa.typography.primaryFontFamily ? theme.components.bbsa.typography.primaryFontFamily : theme.typography.font) : (font === "secondary" ? (theme.components.bbsa.typography.secondaryFontFamily ? theme.components.bbsa.typography.secondaryFontFamily : theme.typography.font) : ""),
    textTransform: "uppercase",
  },
  '& .MuiOutlinedInput-input': {
    paddingLeft: noBorder ? 0 : "",
    paddingBottom: noBorder ? 3 : "",
    paddingTop: noBorder ? 3 : "",
  },
  [theme.breakpoints.down("sm")]: {}
}));

const MenuItemStyled = styled(MenuItem)(({theme, font}) => ({
  fontFamily: font === "primary" ? (theme.components.bbsa.typography.primaryFontFamily ? theme.components.bbsa.typography.primaryFontFamily : theme.typography.font) : (font === "secondary" ? (theme.components.bbsa.typography.secondaryFontFamily ? theme.components.bbsa.typography.secondaryFontFamily : theme.typography.font) : ""),
  textTransform: "uppercase",
  'p': {
    fontFamily: font === "primary" ? (theme.components.bbsa.typography.primaryFontFamily ? theme.components.bbsa.typography.primaryFontFamily : theme.typography.font) : (font === "secondary" ? (theme.components.bbsa.typography.secondaryFontFamily ? theme.components.bbsa.typography.secondaryFontFamily : theme.typography.font) : ""),
    textTransform: "uppercase",
  },
  [theme.breakpoints.down("sm")]: {}
}));
