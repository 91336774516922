import React, {useEffect, useState} from "react";
import {DaysService} from "../../services";
import {useSeason} from "../Season";
import {styled} from "@mui/material/styles";
import {Box, Stack, Typography} from "@mui/material";
import {SelectField} from "../Common";

export default function DaySelector(props) {
  const {
    onChange = () => {}
  } = props;

  const [days, setDays] = useState(null);
  const [selected, setSelected] = useState(0);
  const {season} = useSeason();

  useEffect(() => {
    DaysService.getAllDaysOfSeason(season.id).then((days) => {
       setDays(days);
    })
  }, [season])

  const handleChange = (id) => {
    setSelected(id);
    onChange(id);
  }


  return (
    <DaySelectorRoot noBorder className={"bbsa-day-selector-root"}>
      <Stack>
        <DaySelectorTitle className={"bbsa-day-selector-title"}>Jour de saison</DaySelectorTitle>
        {days &&
          <DaySelectorInput
            noBorder
            value={selected}
            onChanged={handleChange}
            items={days.map(p => {
              return {
                value: p,
                label: <Typography>{p.name}</Typography>
              }
            })}
          />
        }
      </Stack>
    </DaySelectorRoot>
  )
}

const DaySelectorRoot = styled(Box)(({theme}) => ({
  maxWidth: "125px",
  [theme.breakpoints.down("sm")]: {}
}));

const DaySelectorTitle = styled(Box)(({theme}) => ({
  fontSize: "0.7rem",
  [theme.breakpoints.down("sm")]: {}
}));

const DaySelectorInput = styled(SelectField)(({theme}) => ({
  [theme.breakpoints.down("sm")]: {}
}));

