import {useLocation} from "react-router-dom";
import useSession from "../../contexts/session/useSession";
import {useEffect} from "react";

function PublicRoute(props) {
  const {setPrevUrl, prevUrl} = useSession();
  const {children} = props;
  const location = useLocation()


  useEffect(() => {
    setPrevUrl(location.pathname)
  }, [location])

  return (
    <>
      {children}
    </>
  );

}

export default PublicRoute;
