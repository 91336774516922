import React, {Fragment, useEffect, useState} from "react";
import {Dialog, DialogContent, DialogContentText, DialogTitle, IconButton} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import EventTypeButton from "./EventTypeButton";
import TeamSelectionButton from "./TeamSelectionButton";
import EventBreadcrumbs from "./EventBreadcrumbs ";
import PlayerSelectionButton from "./PlayerSelectionButton";
import useGameboard from "../../contexts/gameboard/useGameboard";
import {EventButton} from "./EventSelectionStyles";

const styleModal = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '70%',
  height: '70%',
  maxWidth: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

function EventCategoryButton(props) {
  const {activeTeam, eventTypes, getAvailablePlayers} = useGameboard();
  const {
    category, game, onClick = (c, e, t, p, v, i) => {
    }
  } = props;

  const [events, setEventTypes] = useState([]);
  const [eventTypesLoaded, setEventTypesLoaded] = useState(false);
  const [openEventTypes, setOpenEventTypes] = useState(false);
  const [openVictim, setOpenVictim] = useState(false);
  const [victim, setVictim] = useState(null);
  const [victimTeam, setVictimTeam] = useState(null);

  useEffect(() => {
    if (!eventTypesLoaded) {
      let e = eventTypes.filter(e => e.eventCategoryId === category.id)
      // EventsService.getEventTypesByCategory(category.id).then((events) => {
      setEventTypesLoaded(true)
      setEventTypes(e);
    }
  }, [events, category.id])

  const handleOpenEventTypes = () => {
    setVictim(null);
    setVictimTeam(null);
    if (category.hasVictim) {
      setOpenVictim(true);
    } else {
      if (events.length > 1) {
        setOpenEventTypes(true);
      } else {
        if (events[0].playerSelection) {
          setOpenEventTypes(true);
        } else {
          let p = null;
          if (events[0].eventWay === 'ACTIVE') {
            p = activeTeam.players[0];
            handleOnClickPlayer(p);
          } else if (events[0].eventWay === 'ALL') {
            setOpenEventTypes(true);
          }
        }
      }
    }
  }
  const handleCloseEventTypes = () => {
    setOpenEventTypes(false);
  }

  const handleOnClickEvent = (event, team, player, injuries) => {
    handleCloseEventTypes();
    onClick(category, event, team, player, victim, injuries)
  }

  const handleOnClickTeamSelection = (team, player) => {
    handleCloseEventTypes();
    onClick(category, events[0], team, player, null, null);
  }

  const handleCloseVictim = () => {
    setOpenVictim(false);
  }

  const handleOnClickVictimSelection = (team, player) => {
    setVictim(player);
    setVictimTeam(team);
    handleCloseVictim();
    setOpenEventTypes(true);
  }

  const handleOnClickPlayer = (player) => {
    handleCloseEventTypes();
    handleOnClickTeamSelection(activeTeam, player);
  }


  return (
    <Fragment>
      <EventButton className={"bbsa-event-category-button-root"} onClick={handleOpenEventTypes} fullWidth
                   variant={"contained"} color={"success"}>{category.name}</EventButton>

      <Dialog
        open={openEventTypes}
        onClose={handleCloseEventTypes}
        scroll={'paper'}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        {events.length > 1 &&
          <Fragment>
            <DialogTitle id="scroll-dialog-title" align={"right"}>
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={handleCloseEventTypes}
              >
                <CloseIcon/>
              </IconButton>
              <EventBreadcrumbs category={category} event={null} team={null} player={null} victim={victim}
                                victimTeam={victimTeam}/>
            </DialogTitle>
            <DialogContent dividers={true}>
              <DialogContentText
                id="scroll-dialog-description"
                tabIndex={-1}
              >
                {events.map((value) => {
                  if ((value.eventWay === 'NO_ACTIVE' && !activeTeam.id) || value.eventWay !== 'NO_ACTIVE') {
                    return (<EventTypeButton victim={victim} victimTeam={victimTeam}
                                             onClick={handleOnClickEvent}
                                             key={"eventsCategories-" + value.id} game={game}
                                             type={value}/>)
                  }
                })}
              </DialogContentText>
            </DialogContent>
          </Fragment>
        }
        {events.length === 1 &&
          <Fragment>
            {events[0].eventWay === 'ACTIVE' &&
              <Fragment>
                <DialogTitle id="scroll-dialog-title" align={"right"}>
                  <IconButton
                    size="large"
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    onClick={handleCloseEventTypes}
                  >
                    <CloseIcon/>
                  </IconButton>
                  <EventBreadcrumbs category={null} event={events[0]} team={activeTeam} player={null}
                                    victim={null} victimTeam={null}/>
                </DialogTitle>
                <DialogContent dividers={true}>
                  <DialogContentText
                    id="scroll-dialog-description"
                    tabIndex={-1}
                  >

                    {getAvailablePlayers(activeTeam.players).map((value) => {
                      return (
                        <PlayerSelectionButton onClick={handleOnClickPlayer}
                                               key={"teams-" + value.id}
                                               player={value}/>)
                    })}
                  </DialogContentText>
                </DialogContent>
              </Fragment>
            }
            {events[0].eventWay === 'ALL' &&
              <Fragment>
                <DialogTitle id="scroll-dialog-title" align={"right"}>
                  <IconButton
                    size="large"
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    onClick={handleCloseEventTypes}
                  >
                    <CloseIcon/>
                  </IconButton>
                  <EventBreadcrumbs category={category} event={events[0]} team={null} player={null}
                                    victim={victim} victimTeam={victimTeam}/>
                </DialogTitle>
                <DialogContent dividers={true}>
                  <DialogContentText
                    id="scroll-dialog-description"
                    tabIndex={-1}
                  >
                    {game.teamParticipations.map((value) => {
                      return (<TeamSelectionButton onClick={handleOnClickTeamSelection}
                                                   key={"teams-" + value.id}
                                                   teamParticipation={value} event={events[0]}/>)
                    })}
                  </DialogContentText>
                </DialogContent>
              </Fragment>
            }
          </Fragment>
        }
      </Dialog>
      <Dialog
        open={openVictim}
        onClose={handleCloseVictim}
        scroll={'paper'}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title" align={"right"}>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={handleCloseVictim}
          >
            <CloseIcon/>
          </IconButton>
          <EventBreadcrumbs category={category} event={null} team={null} player={null} victim={victim}
                            victimTeam={victimTeam} hasVictim/>
        </DialogTitle>
        <DialogContent dividers={true}>
          <DialogContentText
            id="scroll-dialog-description"
            tabIndex={-1}
          >
            {game.teamParticipations.map((value) => {
              return (<TeamSelectionButton onClick={handleOnClickVictimSelection}
                                           key={"teams-" + value.id} teamParticipation={value}
                                           hasVictim/>)
            })}
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </Fragment>
  )
}

export default EventCategoryButton;

