import CommonService from "./CommonService";

class EventsService extends CommonService {
  async getEventsType() {
    return this.request({
      url: process.env.REACT_APP_API_URL + '/eventTypes',
      method: 'GET'
    })
  }

  async getEventCategories() {
    return this.request({
      url: process.env.REACT_APP_API_URL + '/eventCategories',
      method: 'GET'
    })
  }

  async getEventTypesByCategory(id) {
    return this.request({
      url: process.env.REACT_APP_API_URL + '/eventCategories/' + id,
      method: 'GET'
    })
  }

  async add(gameId, data, token) {
    return this.request({
      url: process.env.REACT_APP_API_URL + '/games/' + gameId + '/events',
      method: 'POST',
      body: JSON.stringify(data),
      token: token
    }, 'application/json', 'none')
  }

  async delete(eventId, token) {
    return this.request({
      url: process.env.REACT_APP_API_URL + '/events/' + eventId,
      method: 'DELETE',
      token: token
    }, 'application/json', 'none')
  }
}

export default new EventsService();
