import React, {Fragment, useEffect, useState} from "react";
import DaysService from "../../services/DaysService";
import DayTable from "../components/DayTable/DayTable";
import {Box, Container, Hidden} from "@mui/material";
import BbsaCard from "../components/BbsaCard/BbsaCard";
import useSeason from "../contexts/season/useSeason";

export default function CalendarPage(props) {
  const {season} = useSeason();
  const [days, setDays] = useState(null);

  useEffect(() => {
    if (days === null) {
      DaysService.getAllDaysOfSeason(season.id).then((days) => {
        setDays(days);
      })
    }
  })

  return (
    <Fragment>
      <Hidden mdDown={"sm"}>
        <Container maxWidth={"lg"} sx={{paddingTop: "20px"}}>
          <Box>
            {days !== null && days.map((day) =>
              <BbsaCard key={"dayTable_" + day.id} mb={10}>
                <DayTable day={day}/>
              </BbsaCard>
            )}
          </Box>
        </Container>
      </Hidden>
      <Hidden mdUp={"sm"}>
        <Box sx={{backgroundColor: "#ffffff"}}>
          {days !== null && days.map((day) =>
            <DayTable key={"dayTable_" + day.id} day={day}/>
          )}
        </Box>
      </Hidden>
    </Fragment>
  )
}
