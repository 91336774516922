import React, {Fragment} from "react";
import {Box, Grid, styled, Table, TableBody, TableCell, tableCellClasses, TableRow, Typography} from "@mui/material";


export default function GameSheet(props) {
  const {events, team} = props;


  return (
    <Fragment>
      <Box className={"bbsa-game-sheet-root"}>
        <Grid container>
          <Grid xs={12}>
            {events &&
              <GameSheetTeam events={events} team={team}/>
            }
          </Grid>
        </Grid>
      </Box>
    </Fragment>
  )
}

function GameSheetTeam(props) {
  const {events, team} = props;

  return (
    <Fragment>
      <Table size="small">
        <TableBody>
          {team && events && team.players.map(player => {
              let playerEvents = events.filter(e => e.perpetratorPlayerId === player.id);
              let xp = 0;
              if (playerEvents.length !== 0) {
                playerEvents.map(pe => {
                  xp += pe.type.xp
                })
              }

              let eventsWithInjuries = events.filter(event => event.victimPlayerId === player.id);

              if (xp > 0 || eventsWithInjuries.length > 0) {
                return (
                  <TableRow>
                    <TableCellStyled>
                      <Typography sx={{color: "green"}}>#{player.number} {player.positionalName}</Typography>
                    </TableCellStyled>
                    <TableCellStyled>
                      <Typography sx={{color: "green"}}>{xp}&nbsp;PSP</Typography>
                    </TableCellStyled>
                    <TableCellStyled>
                      <GameSheetPlayerInjuries events={eventsWithInjuries} playerId={player.id}/>
                    </TableCellStyled>
                  </TableRow>
                )
              }
            }
          )}
        </TableBody>
      </Table>
    </Fragment>

  )
}

function GameSheetPlayerInjuries(props) {
  const {events, playerId} = props;


  return (
    <Fragment>
      {events.filter(event => event.victimPlayerId === playerId).map(event => {
        if (event.injuries !== null) {
          return (
            <Fragment>
              {event.injuries.map(i => {
                return (
                  <Typography sx={{color: "red"}}>{i.injuryTypeName} {i.injuryTypeInfo?"(" + i.injuryTypeInfo + ")":""}</Typography>
                )
              })}
            </Fragment>
          )
        }
      })}
    </Fragment>
  )
}

const TableCellStyled = styled(TableCell)(({theme}) => ({
  [`&.${tableCellClasses.head}`]: {
    color: theme.palette.primary.contrastText,
    padding: 0,
  },
  [`& p`]: {
    fontSize: '0.8rem',
  },
  fontFamily: theme.typography.fontFamily,
  [theme.breakpoints.down("sm")]: {
    [`& p`]: {
      fontSize: '0.7rem',
    },
  }
}));

