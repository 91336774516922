import CommonService from "./CommonService";

class NewsService extends CommonService {
  async get(id) {
    return this.request({
      url: process.env.REACT_APP_API_URL + '/news/' + id,
      method: 'GET'
    })
  }

  async save(id, content) {
    if (id === 0) {
      return this.request({
        url: process.env.REACT_APP_API_URL + '/news',
        body: JSON.stringify(content),
        method: 'PUT'
      })
    } else {
      return this.request({
        url: process.env.REACT_APP_API_URL + '/news/' + id,
        body: JSON.stringify(content),
        method: 'POST'
      })
    }
  }
}

export default new NewsService();
