import {Box, Button, FormControl, InputLabel, MenuItem, OutlinedInput, Select, Stack, TextField} from "@mui/material";
import React, {useEffect, useState} from "react";
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import moment from "moment";
import useSession from "../../contexts/session/useSession";
import {DaysService, PhaseService, SeasonService} from "../../../services";

function DayUpdate(props) {
  const {currentSession} = useSession();
  const {
    dayId, onDayUpdated = (resp) => {
    }
  } = props;

  const [name, setName] = useState("");
  const [position, setPosition] = useState(0);
  const [dateEvent, setDateEvent] = useState(null);
  const [phaseId, setPhaseId] = useState(0);
  const [phases, setPhases] = useState([]);
  const [day, setDay] = useState(null);
  const [dayLoaded, setDayLoaded] = useState(false);


  const handleChangeName = (e) => {
    setName(e.target.value)
  }

  const handleChangePosition = (e) => {
    setPosition(e.target.value)
  }

  const handleChangePhase = (e) => {
    setPhaseId(e.target.value)
  }

  const handleSave = () => {
    const data = {...day};
    data.name = name;
    data.position = position;
    data.phase = {id: phaseId};
    data.eventDate = dateEvent.format("DD/MM/YYYY");
    DaysService.update(dayId, data, currentSession.accessToken).then((day) => {
      onDayUpdated(day);
    })
  }

  useEffect(() => {
    if (!dayLoaded) {
      DaysService.get(dayId).then((d) => {
        setDay(d);
        setName(d.name);
        setPosition(d.position);
        setDateEvent(moment(d.eventDate, "DD/MM/YYYY"))
        SeasonService.getCurrent().then((s) => {
          PhaseService.getPhasesFromSeason(s.id).then((p) => {
            setPhases(p);
            setDayLoaded(true);
          })
        })
      })
    }
  }, [dayLoaded, dayId])

  if (!dayLoaded) {
    return (
      <Box>
        <div>Chargement</div>
      </Box>
    )
  }

  return (
    <Box>
      <Box textAlign={"center"}>
        <h2>Modification d'une journée</h2>
      </Box>
      <Stack spacing={2}>
        <FormControl>
          <InputLabel htmlFor="journee-label">Nom</InputLabel>
          <OutlinedInput
            id="journee"
            value={name}
            label="Journée"
            onChange={handleChangeName}
          />
        </FormControl>
        <FormControl>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              label="Date"
              inputFormat="DD/MM/YYYY"
              value={dateEvent}
              onChange={(newValue) => {
                setDateEvent(newValue);
              }}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </FormControl>
        <FormControl fullWidth>
          <InputLabel id="pool-label">Phase</InputLabel>
          <Select
            labelId="select-pool"
            id="select-pool"
            value={phaseId}
            label="Equipe 1"
            onChange={handleChangePhase}
          >
            <MenuItem sx={{fontStyle: "italic", color: "gray"}} value={0}>Non déterminé</MenuItem>
            {phases.map((phase) => {
              return <MenuItem value={phase.id}>{phase.name}</MenuItem>
            })}
          </Select>
        </FormControl>
        <FormControl>
          <InputLabel htmlFor="journee-label">Position</InputLabel>
          <OutlinedInput
            id="position"
            value={position}
            label="Position"
            type="number"
            InputLabelProps={{
              shrink: true,
            }}
            onChange={handleChangePosition}
          />
        </FormControl>
        <FormControl fullWidth>
          <Button variant="contained" onClick={handleSave} fullWidth>Enregistrer</Button>
        </FormControl>
      </Stack>
    </Box>
  )
}

export default DayUpdate;
