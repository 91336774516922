import React, {Fragment, useEffect, useState} from "react";
import {Box, Container, Divider, Grid, Paper, Tab, Tabs} from "@mui/material";
import GameCard from "../components/GameCard/GameCard";
import NewsCard from "../components/NewsCard/NewsCard";
import SeasonService from "../../services/SeasonService";
import PhaseService from "../../services/PhaseService";
import useSeason from "../contexts/season/useSeason";
import {DaySelector} from "../components/DaySelector";
import {styled} from "@mui/material/styles";
import Rank from "../components/Rank/Rank";

export default function HomePage(props) {
  const {season} = useSeason();
  const [daySelected, setDaySelected] = useState(null);
  const [recentsNews, setRecentsNews] = useState(null);
  const [ranks, setRanks] = useState(null);


  const handleDaySelected = (day) => {
    setDaySelected(day)
  }

  useEffect(() => {
    if (recentsNews === null) {
      SeasonService.getLastNews(season.id).then((s) => {
        setRecentsNews(s);
      })
    }
  }, [recentsNews])

  useEffect(() => {
    if (ranks === null) {
      PhaseService.getPhasesFromSeason(season.id).then((phases) => {
        if (phases.length > 0) {
          var p = phases.filter(phase => phase.phaseType === 'CHAMPIONSHIP');
          if (p.length !== 0) {
            PhaseService.getPhase(p[0].id).then((p) => {
              setRanks(p.filter((pool) => !pool.interPool));
            })
          }
        }
      })
    }
  }, [ranks])


  return (
    <Fragment>
      <Container maxWidth={"lg"}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <DayPaper>
              <Grid container>
                <Grid item xs={12} md={1} sx={{display: 'flex', alignItems: "center"}}>
                  <DaySelector onChange={handleDaySelected}/>
                </Grid>
                <Grid item xs={12} md={11}>
                  <TabsStyled
                    value={0}
                    variant="scrollable"
                    scrollButtons
                    allowScrollButtonsMobile
                    indicatorColor="transparent"
                  >
                    {daySelected && daySelected.games.map((game, i) => {
                      return (
                        <Fragment>
                          <GameCard key={game.id} gameId={game.id}/>
                          <TabDivider label="" icon={<Divider orientation="vertical"/>} disabled/>
                        </Fragment>
                      )
                    })}
                  </TabsStyled>
                </Grid>
              </Grid>
            </DayPaper>
          </Grid>
          <Grid item xs={12} md={9} spacing={3}>
            <Box>
              <Grid container spacing={3}>
                {ranks && ranks.map(rank =>
                  <Grid item xs={12}>
                    <Rank key={'rank_' + rank.id} rankId={rank.id}/>
                  </Grid>
                )}
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12} md={3}>
            <Box>
              <Grid container spacing={3}>
                {recentsNews && recentsNews.map((news) =>
                  <Grid item xs={12}>
                    <NewsCard newsId={news.id}/>
                  </Grid>
                )}
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Fragment>
  )
}

const DayPaper = styled(Paper)(({theme}) => ({
  padding: "15px 15px",
  alignContent: "center",
  [theme.breakpoints.down("sm")]: {}
}));

const TabsStyled = styled(Tabs)(({theme}) => ({
  [theme.breakpoints.down("md")]: {},
}));

const TabDivider = styled(Tab)(({theme}) => ({
  minWidth: 0,
  padding: "0px 16px",
  [theme.breakpoints.down("md")]: {},
}));
