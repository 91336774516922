import CommonService from "./CommonService";

class UserService extends CommonService {
  async me(token) {
    return this.request({
      url: process.env.REACT_APP_API_URL + "/user/me",
      method: 'GET',
      token
    })
  }

  async list(token) {
    return this.request({
      url: process.env.REACT_APP_API_URL + "/user",
      method: 'GET',
      token
    })
  }

    async getMyTeams(token) {
        return this.request({
            url: process.env.REACT_APP_API_URL + "/user/teams",
            method: 'GET',
            token
        })
    }

  setActivated(token, checked, username) {
    return this.request({
      url: process.env.REACT_APP_API_URL + "/user/" + username + "/activated/" + checked,
      method: 'POST',
      token
    })
  }
}

export default new UserService();


