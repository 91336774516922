import React, {Fragment, useEffect, useState} from "react";
import {styled, Typography} from "@mui/material";
import {GAME_STATUS_FINISHED, GAME_STATUS_FORFEIT, GAME_STATUS_INPROGRESS} from "../../components/Global/Global";
import useGame from "../../contexts/game/useGame";

const GameScoreRoot = styled(Typography)(({theme}) => ({
  ["& p"]: {
    fontSize: "5rem"
  },
  [theme.breakpoints.down("sm")]: {
    ["& p"]: {
      fontSize: "3rem"
    },
  }
}))


export default function GameScore(props) {
  const {game, versusSymbol = "-"} = props;
  const {events, team1, team2, isInTeam} = useGame();
  const [state1, setState1] = useState("0")
  const [state2, setState2] = useState("0")

  useEffect(() => {
    setState1(game.teamParticipations.filter(t => t.position === 1)[0])
    setState2(game.teamParticipations.filter(t => t.position === 2)[0])
  })

  if (team1 === null || team1.players === null || team2 === null || team2.players === null) {
    return (<Fragment/>)
  }

  return (
    <GameScoreRoot className={"bbsa-game-score"} align={"center"}>
      <Typography className={"score1 " + state1.gameResult} sx={{display: 'inline-block'}}>
        {(game.progressStatus === GAME_STATUS_FINISHED || game.progressStatus === GAME_STATUS_FORFEIT) &&
          <Fragment>{state1.score}</Fragment>}
        {(game.progressStatus === GAME_STATUS_INPROGRESS) &&
          <Fragment>{events.filter(e => e.eventCode === "Touchdown" && isInTeam(team1, e.initiator)).length}</Fragment>}
      </Typography>
      <Typography className={"separator"} sx={{display: 'inline-block'}}>
        {versusSymbol}
      </Typography>
      <Typography className={"score2 " + state2.gameResult} sx={{display: 'inline-block'}}>
        {(game.progressStatus === GAME_STATUS_FINISHED || game.progressStatus === GAME_STATUS_FORFEIT) &&
          <Fragment>{state2.score}</Fragment>}
        {(game.progressStatus === GAME_STATUS_INPROGRESS) &&
          <Fragment>{events.filter(e => e.eventCode === "Touchdown" && isInTeam(team2, e.initiator)).length}</Fragment>}
      </Typography>
    </GameScoreRoot>
  )
}
