import React, {Fragment, useEffect, useState} from "react";
import useTeam from "../../contexts/team/useTeam";
import BBSAInputField from "../BbsaInputField/BBSAInputField";
import BBSASelectField from "../BbsaSelectField/BBSASelectField";
import RaceService from "../../../services/RaceService";
import {Button, DialogActions, DialogContent, DialogContentText, Typography} from "@mui/material";
import useSession from "../../contexts/session/useSession";
import teamsService from "../../../services/TeamsService";
import ActionButton from "../ValidationBeforeAction/ActionButton";
import useLoading from "../../contexts/loading/useLoading";
import BBSALoadingBox from "../BbsaLoadingBox/BBSALoadingBox";


export default function AddPlayerForm(props) {
    const {
        onAddSuccess = () => {
        },
        onAddError = () => {
        },
    } = props;

    const {team} = useTeam();
    const {currentSession} = useSession();
    const {showLoading, hideLoading} = useLoading();

    const [name, setName] = useState("");
    const [positionalId, setPositionalId] = useState(0);
    const [positional, setPositional] = useState(null);
    const [number, setNumber] = useState(0);
    const [positionals, setPositionals] = useState(null);

    useEffect(() => {
        if (positionals === null) {
            RaceService.getPositionals(team.race.id).then((positionals) => {
                setPositionals(positionals);
            })
        }
    }, [positionals])

    const handleChangedPositional = (id) => {
        if (id !== 0) {
            setPositional(positionals.filter(p => p.id === id)[0])
        } else {
            setPositional(null)
        }
        setPositionalId(id);
    }

    const handleAddPlayer = () => {
        let positional = positionals.filter(p => p.id === positionalId)[0];

        showLoading("Enrôlement du joueur " + positional.name);

        let data = {
            teamId: team.id,
            positionalId: positionalId,
            name: name,
            number: number
        }

        teamsService.buyPlayer(data, currentSession.accessToken).then(() => {
            onAddSuccess(positional, name, number);
            hideLoading()
        }).catch((e) => {
            onAddError(e);
            hideLoading()
        })
    }

    if (positionals === null) {
        return (
            <BBSALoadingBox/>
        )
    }

    return (
        <Fragment>
            <DialogContent dividers={true}>
                <DialogContentText
                    id="scroll-dialog-description"
                    tabIndex={-1}
                >
                    <BBSASelectField
                        label={"type"}
                        value={positionalId}
                        onChanged={handleChangedPositional}
                        items={positionals.map(p => {
                            return {
                                value: p.id,
                                label: <Typography>{p.price} K - {p.name}</Typography>
                            }
                        })}
                    />
                    <BBSAInputField
                        defaultValue={name}
                        label={"Nom"}
                        onChange={setName}
                    />

                    <BBSAInputField
                        type={"number"}
                        defaultValue={number}
                        label={"Numéro"}
                        onChange={setNumber}
                    />
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <ActionButton
                    fullWidth
                    disabled={positional ? false : true}
                    actionLabel={"Acheter"}
                    elementToBuy={positional ? positional.name : ""}
                    onDoAction={handleAddPlayer}
                    title={"Validation d'achat"}
                />
            </DialogActions>
        </Fragment>
    )
}
