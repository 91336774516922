import {useContext} from "react";
import {CurrentGameContext} from "./GameContext";

export default function useGame() {
  const {
    game,
    events,
    team1,
    team2,
    turns,
    eventCategories,
    setEvents,
    setConnectionLost
  } = useContext(CurrentGameContext)

  function getPlayer(id) {
    let player = null;
    team1.players.concat(team2.players).map(p => {
      if (p.id === id) {
        player = p;
      }
    })
    return player;
  }

  function getTeamFromPlayer(id) {
    let team = null;
    [team1, team2].map(t => {
      t.players.filter((p => p.id === id)).map(() => {
        team = t;
      })
    })
    return team;
  }

  function isInTeam(team, playerId) {
    if (team.players.filter(player => player.id === playerId).length === 0) {
      return false;
    }
    return true;
  }

  function getOpposedTeam(team) {
    if (team1.id === team.id) {
      return team2;
    }
    return team1;
  }

  function getTeam(teamId) {
    if (team1.id === teamId) {
      return team1;
    } else if (team2.id === teamId) {
      return team2;
    }
    return null;
  }


  function removeEvent(trx) {
    setEvents(events.filter(e => e.id !== trx.id))
  }

  function addEvent(trx) {
    events.push(trx)
    setEvents([...events])
  }

  function handleNoStreamingWithServer(state = false) {
    setConnectionLost(state)
  }

  return {
    game,
    events,
    eventCategories,
    getPlayer,
    getTeamFromPlayer,
    turns,
    isInTeam,
    team1,
    team2,
    setEvents,
    getOpposedTeam,
    getTeam,
    removeEvent,
    addEvent,
    handleNoStreamingWithServer
  };
}

export function calculateGlobalTurnNumber(nbrOfEvents) {
  let turn = 1;
  let fullTurnPlayed = Math.floor(nbrOfEvents / 2);
  turn += fullTurnPlayed;
  return turn;
}

export function calculateHalftimeTurnNumber(nbrOfTurn) {
  return nbrOfTurn - ((calculateHalftime(nbrOfTurn) - 1) * 8)
  let turn = 1;
  let fullTurnPlayed = Math.floor(nbrOfTurn / 2);
  turn += fullTurnPlayed;
  return turn;
}

export function calculateHalftime(nbrOfTurn) {
  let halftime = 1;
  let tmpTurn = nbrOfTurn;
  while (tmpTurn > 8) {
    halftime += 1;
    tmpTurn -= 8;
  }

  return halftime;
}

