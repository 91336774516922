import {useContext} from "react";
import {CurrentGameBoardContext} from "./GameBoardContext";
import EventsService from "../../../services/EventsService";
import useSession from "../session/useSession";

export default function useGameboard() {
  const {
    game,
    events,
    setEvents,
    turn,
    activeTeam,
    team1,
    team2,
    eventCategories,
    eventTypes,
    kickOffTeam,
    getTeamFromPlayer
  } = useContext(CurrentGameBoardContext);
  const {currentSession} = useSession();

  function nextTurn(success = () => {
  }, error = () => {
  }) {
    let data = {
      gameId: game.id,
      eventTypeId: 12,
      turn: turn.global,
      perpetratorPlayerId: activeTeam.players[0].id
    }
    sendEvent(data, success, error);
  }

  function sendEvent(event, success = () => {
  }, error = () => {
  }) {
    EventsService.add(game.id, event, currentSession.accessToken).then(() => {
      success(event);
    }).catch((e) => {
      error(e)
    })
  }

  function cancelLastEvent(onSuccess = () => {
  }, onError = () => {
  }) {
    let e = events.sort((a, b) => {
      return a.id - b.id
    })[events.length - 1];
    EventsService.delete(e.id, currentSession.accessToken).then(() => {
      onSuccess(e, getTeamFromPlayer(e.initiator));
    }).catch((error) => {
      onError();
    })
  }

  function getAvailablePlayers(players) {
    return players
      .filter(p => p.active)
      .filter(p => p.injuries.filter(i => (i.injuryTypeCode === 'MissNextGame' || i.injuryTypeCode === 'DEAD') && i.active).length === 0)
  }


  return {
    game,
    turn,
    activeTeam,
    team1,
    team2,
    nextTurn,
    sendEvent,
    eventCategories,
    eventTypes,
    kickOffTeam,
    cancelLastEvent,
    getAvailablePlayers
  };
}
