import React, {useEffect, useState} from "react";
import {useSnackbar} from "notistack";
import SeasonService from "../../services/SeasonService";
import DaysService from "../../services/DaysService";
import {Box, Container, IconButton, Modal} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DayAdd from "../components/DayAdd/DayAdd";
import DayUpdate from "../components/DayUpdate/DayUpdate";
import DayDelete from "../components/DayDelete/DayDelete";
import DaysTable from "../components/DaysTable/DaysTable";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '70%',
  maxWidth: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function ManageDaysPage(props) {
  const {enqueueSnackbar} = useSnackbar();
  const [days, setDays] = useState([]);
  const [openAddDay, setOpenAddDay] = useState(false);
  const [openUpdateDay, setOpenUpdateDay] = useState(false);
  const [openDeleteDay, setOpenDeleteDay] = useState(false);
  const [daysLoaded, setDaysLoaded] = useState(false);
  const [dayId, setDayId] = useState(null);

  useEffect(() => {
    if (!daysLoaded) {
      SeasonService.getCurrent().then((season) => {
        DaysService.getAllDaysOfSeason(season.id).then((days) => {
          setDaysLoaded(true);
          setDays(days)
        })
      })
    }
  })

  const handleOnDayAdded = (day) => {
    enqueueSnackbar("Journée [" + day.name + "] ajoutée !", {variant: "success"});
    handleCloseAddDay()
    setDaysLoaded(false);
  }

  const handleOnDayUpdated = (day) => {
    enqueueSnackbar("Journée [" + day.name + "] modifiée !", {variant: "success"});
    handleCloseUpdateDay()
    setDaysLoaded(false);
  }

  const handleOnDayDeleted = (day) => {
    enqueueSnackbar("Journée [" + day.name + "] effacée !", {variant: "success"});
    handleCloseDeleteDay()
    setDaysLoaded(false);
  }

  const handleOnAddDayClick = () => {
    setOpenAddDay(true);
  }

  const handleCloseAddDay = () => {
    setOpenAddDay(false);
  }

  const handleOnUpdateDayClick = (dayId) => {
    setDayId(dayId);
    setOpenUpdateDay(true);
  }

  const handleCloseUpdateDay = () => {
    setOpenUpdateDay(false);
  }

  const handleOnDeleteDayClick = (dayId) => {
    setDayId(dayId);
    setOpenDeleteDay(true);
  }

  const handleCloseDeleteDay = () => {
    setOpenDeleteDay(false);
  }

  return (
    <>
      <Container maxWidth={"lg"} className={'content-bb'}>
        <h1>Gestion des journées</h1>
        <DaysTable
          days={days}
          onAddDayClick={handleOnAddDayClick}
          onUpdateDayClick={handleOnUpdateDayClick}
          onDeleteDayClick={handleOnDeleteDayClick}
        />
      </Container>
      <Modal
        open={openAddDay}
        onClose={handleCloseAddDay}
      >
        <Box sx={style}>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{mr: 2, position: "absolute", top: 15, right: 0}}
            onClick={handleCloseAddDay}
          >
            <CloseIcon/>
          </IconButton>

          <DayAdd
            onDayAdded={handleOnDayAdded}
          />
        </Box>
      </Modal>
      <Modal
        open={openUpdateDay}
        onClose={handleCloseUpdateDay}
      >
        <Box sx={style}>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{mr: 2, position: "absolute", top: 15, right: 0}}
            onClick={handleCloseUpdateDay}
          >
            <CloseIcon/>
          </IconButton>

          <DayUpdate
            dayId={dayId}
            onDayUpdated={handleOnDayUpdated}
          />
        </Box>
      </Modal>
      <Modal
        open={openDeleteDay}
        onClose={handleCloseDeleteDay}
      >
        <Box sx={style}>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{mr: 2, position: "absolute", top: 15, right: 0}}
            onClick={handleCloseDeleteDay}
          >
            <CloseIcon/>
          </IconButton>

          <DayDelete
            dayId={dayId}
            onDayDeleted={handleOnDayDeleted}
          />
        </Box>
      </Modal>
    </>
  )
}
