import React, {useEffect, useState} from "react";
import {CKEditor} from '@ckeditor/ckeditor5-react';
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import {useSnackbar} from "notistack";
import {useParams} from "react-router-dom";
import {Box, Button, Container, FormControl, Grid, Paper, Stack, Typography} from "@mui/material";
import NewsService from "../../services/NewsService";
import SeasonService from "../../services/SeasonService";
import BBSAInputField from "../components/BbsaInputField/BBSAInputField";
import NewsPaper from "../components/NewsPaper/NewsPaper";


function ManageNewsPage(props) {
  const {enqueueSnackbar} = useSnackbar();
  let {id} = useParams();


  const [preview, setPreview] = useState("<span>Quoi de neuf!</span>");
  const [season, setSeason] = useState(null);
  const [seasonLoaded, setSeasonLoaded] = useState(false);
  const [title, setTitle] = useState("Une news");
  const [image, setImage] = useState(null);
  const [newsId, setNewsId] = useState(0);

  const handleOnChangeTitle = (e) => {
    setTitle(e)
  }
  const handleOnChangeImage = (e) => {
    if (e === "") {
      setImage(null)
    } else {
      setImage(e)
    }
  }

  useEffect(() => {
    if (id !== undefined) {
      setNewsId(id);
    } else {
      setNewsId(0);
    }
    if (!seasonLoaded) {
      SeasonService.getCurrent().then((season) => {
        setSeason(season);
        setSeasonLoaded(true)
      })
    }
  }, [seasonLoaded, id])

  useEffect(() => {
    if (id !== undefined && id !== 0) {
      NewsService.get(id).then((n) => {
        setTitle(n.title)
        setPreview(n.content)
        setImage(n.imageUrl)
      })
    }


  }, [newsId, id])


  const handleOnClickSave = () => {
    const data = {
      title: title,
      content: preview,
      imageUrl: image,
      season: {
        id: season.id
      }
    }
    NewsService.save(newsId, data).then((response) => {
      setNewsId(response.id);
      enqueueSnackbar("News enregistrée !", {variant: "success"});

    })
  }

  if (!seasonLoaded) {
    return (
      <h2>Chargement</h2>
    )
  }


  return (
    <React.Fragment>
      <Container maxWidth={"lg"} className={'content-bb'}>
        <Paper>
          <Box padding={2}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <BBSAInputField
                  defaultValue={title}
                  label={"Titre"}
                  onChange={handleOnChangeTitle}

                />
              </Grid>
              <Grid item xs={12}>
                <Stack
                  direction={'row'}
                  alignItems={'center'}
                >
                  <BBSAInputField
                    defaultValue={image}
                    label={"Image de vignette"}
                    onChange={handleOnChangeImage}
                  />
                  <img height={"55px"} src={image}/>
                </Stack>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <Typography variant={"div"} sx={{fontFamily: 'Nuffle'}} align={'left'}>Contenu</Typography>
                </FormControl>
                <CKEditor

                  editor={Editor}
                  data={preview}
                  onReady={editor => {
                  }}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setPreview(data)
                  }}

                  config={{
                    fullPage: true,
                    fontFamily: {
                      options: [
                        'default', 'Nuffle', 'Gutcruncher', 'New Bartons', 'OldLondon',
                        'Ubuntu, Arial, sans-serif',
                        'Ubuntu Mono, Courier New, Courier, monospace'
                      ]
                    },
                    toolbar: {
                      items: [
                        'alignment', '|',
                        'bold', 'italic', 'strikethrough', 'underline', '|',
                        'link', '|',
                        'bulletedList', 'numberedList', 'todoList',
                        '-', // break point
                        'fontfamily', 'fontsize', 'fontColor', 'fontBackgroundColor', '|',
                        'insertTable', 'insertImage', '|',
                        'undo', 'redo'
                      ]
                    }
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl>
                  <Button onClick={handleOnClickSave} size={"large"} variant={"contained"}>Enregistrer</Button>
                </FormControl>
              </Grid>
            </Grid>

          </Box>
        </Paper>
        <h2>Preview</h2>
        <NewsPaper content={preview} date={"01/01/2022"}/>
      </Container>
    </React.Fragment>

  )
}


export default ManageNewsPage;
