import React, {Fragment} from "react";
import {
  Box,
  Button,
  IconButton,
  Paper,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableRow,
  Typography
} from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

function DaysTable(props) {
  const {
    days
    , onAddDayClick = () => {
    }
    , onUpdateDayClick = (dayId) => {
    }
    , onDeleteDayClick = () => {
    }
  } = props;

  return (
    <React.Fragment>
      <DaysTableRoot className={'bbsa-daysTable-root'}>
        <DaysTableContent className={'bbsa-daysTable-content'}>
          <TableContainer>
            <Table aria-label="customized table">
              <TableBody>
                {days.map((day) => {
                  return (
                    <Fragment key={"daysTableRow_" + day.id}>
                      <StyledTableRow>
                        <StyledTableCell width={"1%"} align="left" sx={{position: 'relative'}}>
                          <Typography variant={"div"}>{day.eventDate}</Typography>
                        </StyledTableCell>
                        <StyledTableCell width={"20%"} align="left" sx={{position: 'relative'}}>
                          <Typography variant={"div"}>{day.name}</Typography>
                        </StyledTableCell>
                        <StyledTableCell width={"78%"} align="left" sx={{position: 'relative'}}>
                        </StyledTableCell>
                        <StyledTableCell width={"1%"} align="left" sx={{position: 'relative'}}>
                          <DayOptionIcons
                            dayId={day.id}
                            onUpdateDayClick={onUpdateDayClick}
                            onDeleteDayClick={onDeleteDayClick}
                          />
                        </StyledTableCell>
                      </StyledTableRow>
                    </Fragment>
                  )
                })}
              </TableBody>
            </Table>
            <DaysTableRowNew key={"daysTableRow_new"} className={"bbsa-daysTable-footer"}>
              <DaysTableRowNewContent className={'bbsa-daysTable-add-day'}>
                <Button onClick={onAddDayClick} fullWidth color={"success"} startIcon={<AddCircleOutlineIcon/>}>
                  Ajouter une journée
                </Button>
              </DaysTableRowNewContent>
            </DaysTableRowNew>
          </TableContainer>
        </DaysTableContent>
      </DaysTableRoot>
    </React.Fragment>
  )
}

const StyledTableCell = styled(TableCell)(({theme}) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
  fontFamily: "Nuffle"
}));

const StyledTableRow = styled(TableRow)(({theme}) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const DaysTableRoot = styled(Paper)(({theme}) => ({
  position: "relative",
  zIndex: 0,
  marginBottom: "20px",
  [theme.breakpoints.down("sm")]: {}
}));

const DaysTableContent = styled(Box)(({theme}) => ({
  [theme.breakpoints.down("sm")]: {}
}));

const DaysTableRowNew = styled(Box)(({theme}) => ({
  display: 'block',
  padding: "10px",
  [theme.breakpoints.down("sm")]: {}
}));

const DaysTableRowNewContent = styled(Box)(({theme}) => ({
  border: "#008000 dashed 2px",
  [theme.breakpoints.down("sm")]: {}
}));

const DayOptionIcons = (props) => {

  const {
    onUpdateDayClick = () => {
    }
    , onDeleteDayClick = () => {
    }
    , dayId
  } = props;

  const handleUpdateDayClick = () => {
    onUpdateDayClick(dayId);
  }

  const handleDeleteDayClick = () => {
    onDeleteDayClick(dayId);
  }

  return (
    <Stack
      direction={'row'}
    >
      <IconButton size={"small"} variant="outlined" color="primary" onClick={handleUpdateDayClick} aria-label="delete">
        <EditIcon/>
      </IconButton>
      <IconButton size={"small"} variant="outlined" color="primary" onClick={handleDeleteDayClick} aria-label="delete">
        <DeleteIcon/>
      </IconButton>
    </Stack>
  )
}

export default DaysTable;
