import React, {Fragment, useEffect, useState} from "react";
import SkillsService from "../../../services/SkillsService";
import {Button, IconButton, Stack, styled, Tooltip, Typography} from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';

const sxButton = {
  mb: "5px"
}

export default function PlayerLevelUpCompetence(props) {
  const {
    competenceType = null,
    dice,
    player,
    onSelected = () => {
    },
  } = props;

  const [skills, setSkills] = useState([]);
  const [competenceTypeId, setCompetenceTypeId] = useState(null)

  useEffect(() => {
    if (competenceType && competenceType.id !== competenceTypeId) {
      setCompetenceTypeId(competenceType.id);
      SkillsService.getByType(competenceType.id, dice.random).then((_skills) => {
        setSkills(_skills)
      })
    }
  }, [competenceType])

  if (skills === null) {
    return (
      <Fragment>
      </Fragment>
    )
  }

  return (
    <Fragment>
      {skills.map(s => {
          let disabled = false;
          if (player.positionalSkills.filter(ps => ps.skillId === s.id).length > 0) {
            disabled = true;
          } else if (player.ownedSkills.filter(ps => ps.skillId === s.id).length > 0) {
            disabled = true;
          }

          return (
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={2}
            >
              <Button disabled={disabled} fullWidth color={"secondary"} variant={"contained"} sx={sxButton}
                      onClick={() => onSelected(s)}>{s.name}</Button>
              <AmeliorationDetail skill={s}/>
            </Stack>

          )
        }
      )}
    </Fragment>
  )
}

const AmeliorationDetail = (props) => {
  const {skill} = props;
  const [openTT, setOpenTT] = useState(false);

  const handleChipClick = () => {
    setOpenTT(!openTT);
  }

  const handleTooltipClose = () => {
    setOpenTT(false);
  }

  return (
    <Tooltip
      PopperProps={{
        disablePortal: false,
      }}
      onClose={handleTooltipClose}
      open={openTT}
      disableFocusListener
      disableHoverListener
      disableTouchListener
      title={<TooltipContent>{skill.description}</TooltipContent>}
      arrow
    >

      <IconButton aria-label="detail" size="small" onClick={handleChipClick}>
        <SearchIcon fontSize="small"/>
      </IconButton>
    </Tooltip>

  )
}

const TooltipContent = styled(Typography)(({theme}) => ({
  fontSize: '1rem',
  [theme.breakpoints.down("sm")]: {
    fontSize: "0.6875rem",
  }
}))

