import {createContext, Fragment, useEffect, useState} from "react";
import TeamsService from "../../../services/TeamsService";
import UserService from "../../../services/UserService";
import useSession from "../session/useSession";
import {Typography} from "@mui/material";
import useLoading from "../loading/useLoading";


const defaultContextValue = {
  team: {
    id: 0
  },
  players: [],
  isMyTeam : false,
  setReload : ()=> {}
}

export const CurrentTeamContext = createContext(defaultContextValue)

export const TeamContext = (props) => {
  const {id} = props;

  const {myTeams} = useSession();
  const {showLoading, hideLoading} = useLoading();

  const [valueContext, setValueContext] = useState(defaultContextValue);
  const [reload, setReload] = useState(false);

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (valueContext.team.id !== id || reload) {
      setReload(false)
      setLoading(true);
      showLoading("Chargement de l'équipe")
      TeamsService.get(id).then((team) => {
        setValueContext(
          {
            team: team,
            players: team.players
              .sort((a, b) => {
                return a.number - b.number
              })
              .slice()
            ,
            isMyTeam: isMyTeam(team),
            setReload : setReload
          }
        )
        hideLoading();
        setLoading(false)
      })
    }
  }, [id, reload])

  useEffect(()=>{
    setValueContext({
      ...valueContext,
      isMyTeam: isMyTeam(valueContext.team)
    })

  }, [myTeams])



  const isMyTeam = (team) => {
    if(team.id !== defaultContextValue.team.id){
      if(myTeams.filter(t => t.id === team.id).length > 0){
        return true;
      }
    }
    return false;
  }

  if(valueContext.team.id===defaultContextValue.team.id || loading){
    return (
        <Fragment/>
    )
  }

  return (
    <CurrentTeamContext.Provider value={valueContext}>
      {props.children}
    </CurrentTeamContext.Provider>
  )
}



