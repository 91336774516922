import {Paper} from "@mui/material";

export default function GameCardContainer(props){

    return (
        <Paper>
            {props.children}
        </Paper>
    )
}
