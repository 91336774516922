import React, {Fragment} from "react";
import {
  AppBar,
  Box,
  Container,
  Drawer,
  Grid,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Toolbar,
  Typography
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import {Link} from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ArticleIcon from '@mui/icons-material/Article';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LogoutIcon from '@mui/icons-material/Logout';
import SettingsIcon from '@mui/icons-material/Settings';
import {styled} from "@mui/material/styles";

import useSession from "../../contexts/session/useSession";
import useSeason from "../../contexts/season/useSeason";
import {useTheme} from "../../contexts/theme/useTheme";

export default function NavBar(props) {
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const {toggleTheme, theme} = useTheme();

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setOpenDrawer(open);
  };

  return (
    <Fragment>
      <NavBarRoot className={"bbsa-navbar-root"} drawerOpened={openDrawer}>
        <NavBarFilter/>
        <Container maxWidth={"lg"}>

          <Toolbar>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{mr: 0, display: {xs: 'block', lg: 'none'}}}
              onClick={toggleDrawer(true)}
            >
              <MenuIcon/>
            </IconButton>
            <NavBarContent>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={2}
              >
                <NavBarTitle/>
                <Menu/>
                <IconButton sx={{ ml: 1 }} onClick={toggleTheme} color="inherit">
                  {theme.palette.mode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
                </IconButton>
              </Stack>
            </NavBarContent>
          </Toolbar>
        </Container>
      </NavBarRoot>
      <BBSADrawer open={openDrawer} onClose={toggleDrawer(false)}/>
    </Fragment>
  )
}

function NavBarTitle() {
  const {season} = useSeason();

  return (
    <NavBarLogoContent>
      <Link to={"/"}>
        <NavBarLogo src={"/img/emporiumbloodbowl-logo.png"} className={"bbsa-navbar-logo"}/>
      </Link>
      <SeasonTitle>{season.name}</SeasonTitle>
    </NavBarLogoContent>
  )
}

function BBSADrawer(props) {
  const {onClose, open = false} = props;
  return (
    <DrawerRoot
      anchor='left'
      open={open}
      onClose={onClose}
      className={"bbsa-navbar-drawer-root"}
    >
      <DrawerHeader>
        <IconButton
          size="large"
          edge="start"
          color="inherit"
          aria-label="menu"
          sx={{mr: 0}}
          onClick={onClose}
        >
          <CloseIcon/>
        </IconButton>
        <NavBarTitle/>
      </DrawerHeader>
      <BBSADrawerContent
        role="presentation"
        onClick={onClose}
        onKeyDown={onClose}
        className={"bbsa-navbar-drawer-content"}
      >
        <Grid container spacing={0} style={{padding: 10, width: "100%"}}>
          <Grid item xs={12} textAlign={"center"}>
            <Menu direction={"column"}/>
          </Grid>
        </Grid>
      </BBSADrawerContent>
    </DrawerRoot>
  )
}

function Menu({direction = 'row'}) {
  const {currentSession, logout} = useSession();

  const items = [
    {label: "Calendrier", url: "calendar", icon: <CalendarMonthIcon/>},
    {label: "Actualités", url: "news", icon: <ArticleIcon/>},
    {label: "Classement", url: "ranks", icon: <EmojiEventsIcon/>},
    {label: "Connexion", url: "login", icon: <LockOpenIcon/>, showOnConnected: false},
    {label: "Mon compte", url: "account", icon: <AccountCircleIcon/>, showOnConnected: true},
    {label: "Gestion", url: "gestion", icon: <SettingsIcon/>, showOnConnected: true, admin: true},
    {
      label: "Déconnexion", url: "#", icon: <LogoutIcon/>, showOnConnected: true, callback: () => {
        logout()
      }
    },
  ]

  if (direction === 'row') {
    return (
      <Fragment>
        <NavBarMenuRoot
          direction={direction}
          spacing={2}
          justifyContent="left"
          className={"bbsa-navbar-menu-root"}
        >
          {items.filter(item => showItemMenu(item, currentSession)).map(item =>
            <Typography align={'left'} component="div">
              <Link
                to={item.url}
                onClick={() => {
                  if (item.callback) {
                    item.callback();
                  }
                }}
              >{item.label} </Link>
            </Typography>
          )}
        </NavBarMenuRoot>
      </Fragment>
    )
  } else {
    return (
      <Fragment>
        {items.filter(item => showItemMenu(item, currentSession)).map(item =>
          <ListItem key={item.label} disablePadding>
            <Link
              to={item.url}
              onClick={() => {
                if (item.callback) {
                  item.callback();
                }
              }}
            >
              <ListItemButton>
                <ListItemIcon sx={{color: "inherit"}}>
                  {item.icon}
                </ListItemIcon>
                <ListItemText primary={item.label}/>
              </ListItemButton>
            </Link>
          </ListItem>
        )}
      </Fragment>
    )
  }
}

const DrawerHeader = styled('div')(({theme}) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const DrawerRoot = styled(Drawer)(({theme}) => ({
  ".MuiDrawer-paper": {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.primary.contrastText,
  }
}));

const NavBarRoot = styled(AppBar)(({theme, drawerOpened}) => ({
  color: theme.palette.secondary.contrastText,
  background: (theme.components.bbsa.layout.header.backgroundImage ? theme.components.bbsa.layout.header.backgroundImage : (theme.palette.secondary.gradient ? theme.palette.secondary.gradient : theme.palette.secondary.main)),
  backgroundSize: "cover",
  backgroundRepeat: "none",
  backgroundPosition: "center",
  position: "fixed",
  [theme.breakpoints.down("sm")]: {}
}));

const NavBarFilter = styled(Box)(({theme}) => ({
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  background: (theme.components.bbsa.layout.header.backgroundImage ? (theme.palette.primary.gradient ? theme.palette.primary.gradient : theme.palette.primary.main) : ""),
  opacity: (theme.components.bbsa.layout.header.backgroundImage ? "70%" : "0%"),
  [theme.breakpoints.down("sm")]: {}
}));

const NavBarContent = styled(Box)(({theme}) => ({
  width: "100%",
  [theme.breakpoints.down("sm")]: {}
}));

const NavBarMenuRoot = styled(Stack)(({theme}) => ({
  mr: 20,
  display: 'flex',
  [theme.breakpoints.down("lg")]: {
    display: 'none'
  }
}));

const NavBarLogoContent = styled(Stack)(({theme}) => ({
  paddingTop: "16px",
  paddingBottom: "16px",
  display: 'block',
  [theme.breakpoints.down("sm")]: {}
}));

const NavBarLogo = styled('img')(({theme}) => ({
  width: '290px',
  [theme.breakpoints.down("sm")]: {
    width: '200px',
  }
}));

const SeasonTitle = styled(Typography)(({theme}) => ({
  fontFamily: (theme.components.bbsa.typography.secondaryFontFamily ? theme.components.bbsa.typography.secondaryFontFamily : theme.typography.font),
  fontSize: "0.8rem"
}));

const BBSADrawerContent = styled(Box)(({theme}) => ({
  width: ((window.innerWidth) * 0.80),
  height: window.innerHeight,
  [theme.breakpoints.down("sm")]: {}
}));


function showItemMenu(item, currentSession) {
  return (
    item.showOnConnected === undefined
    || (item.showOnConnected === currentSession.connected && (item.admin === false || item.admin === undefined))
    || (item.showOnConnected === currentSession.connected && (item.admin === currentSession.isAdmin))
  )
}
