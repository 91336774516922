import React, {Fragment, useEffect, useState} from "react";
import PositionalsService from "../../../services/PositionalsService";
import SkillsService from "../../../services/SkillsService";
import {Button, Typography} from "@mui/material";


const sxButton = {
  mb: "5px"
}

export default function PlayerLevelUpCompetenceType(props){
  const {
    competenceType = null,
    positionalId = 0,
    onSelected = ()=>{},
  } = props;

  const [positional, setPositional] = useState(null);
  const [skillTypes, setSkillTypes] = useState(null);

  useEffect(()=>{
    if(positional===null || (positional && positional.id !== positionalId)){
      PositionalsService.get(positionalId).then((positional)=>{
        setPositional(positional)
      })
    }
  }, [positionalId])

  useEffect(()=>{

    if(positional!==null && competenceType !==null){
       let skillTypes = [];
      switch (competenceType){
        case "P" :
          skillTypes = positional.primarySkillTypes;
          break;
        case "S" :
          skillTypes = positional.secondarySkillTypes;
          break;
      }
      setSkillTypes(skillTypes)
    }
  }, [positional])

  if(positional===null || skillTypes===null){
    return (
      <Fragment>
      </Fragment>
    )
  }

  return (
    <Fragment>
      {skillTypes.map(s =>
        <Button
          fullWidth
          color={"secondary"}
          variant={"contained"}
          sx={sxButton}
          onClick={() => onSelected(s)}
        >
          {s.name}
        </Button>
      )}
    </Fragment>
  )
}
