import React, {Fragment, useEffect, useState} from "react";
import useTeam from "../../contexts/team/useTeam";
import BBSAInputField from "../BbsaInputField/BBSAInputField";
import BBSASelectField from "../BbsaSelectField/BBSASelectField";
import RaceService from "../../../services/RaceService";
import {Button, DialogActions, DialogContent, DialogContentText, Typography} from "@mui/material";
import useSession from "../../contexts/session/useSession";
import teamsService from "../../../services/TeamsService";
import ActionButton from "../ValidationBeforeAction/ActionButton";
import useLoading from "../../contexts/loading/useLoading";
import BBSALoadingBox from "../BbsaLoadingBox/BBSALoadingBox";


export default function AddStaffForm(props) {
    const {
        onAddSuccess = () => {
        },
        onAddError = () => {
        },
    } = props;

    const {team} = useTeam();
    const {currentSession} = useSession();
    const {showLoading, hideLoading} = useLoading();


    const [staffId, setStaffId] = useState(0);
    const [staff, setStaff] = useState(null);
    const [staffs, setStaffs] = useState(null);

    useEffect(() => {
        if (staffs === null) {
            RaceService.getStaffRace(team.race.id).then((staffs) => {
                setStaffs(staffs);
            })
        }
    }, [staffs])

    const handleChangedStaff = (id) => {
        if (id !== 0) {
            setStaff(staffs.filter(s => s.id === id)[0])
        } else {
            setStaff(null)
        }
        setStaffId(id);
    }

    const handleAddStaff = () => {
        let staff = staffs.filter(p => p.id === staffId)[0];

        showLoading("Achat d'un staff de type " + staff.name + " en cours");
        let data = {
            teamId: team.id,
            raceStaffId: staffId
        }

        teamsService.buyStaff(data, currentSession.accessToken).then(() => {
            hideLoading();
            onAddSuccess(staff.name);
        }).catch((e) => {
            hideLoading();
            onAddError(e);
        })
    }

    if (staffs === null) {
        return (
            <BBSALoadingBox/>
        )
    }


    return (
        <Fragment>
            <DialogContent dividers={true}>
                <DialogContentText
                    id="scroll-dialog-description"
                    tabIndex={-1}
                >
                    <BBSASelectField
                        label={"type"}
                        value={staffId}
                        onChanged={handleChangedStaff}
                        items={staffs.map(p => {
                            return {
                                value: p.id,
                                label: <Typography>{p.priceInSeason} K - {p.name}</Typography>
                            }
                        })}
                    />
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <ActionButton
                    fullWidth
                    disabled={staff ? false : true}
                    actionLabel={"Acheter"}
                    elementToBuy={staff ? staff.name : ""}
                    onDoAction={handleAddStaff}
                    title={"Validation d'achat"}
                />
            </DialogActions>

        </Fragment>
    )
}
