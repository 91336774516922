import {Dialog, DialogContent, DialogContentText, DialogTitle, IconButton, Typography} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React, {useEffect} from "react";
import PlayerLevelUpForm from "./PlayerLevelUpForm";


const defaultPlayerStruct = {
    positionalName : "",
    name : ""
}


function PlayerLevelUpDialog(props){
    const {
        open = false,
        onClose = () => {},
        onBuyAmelioration = ()=>{},
        player = defaultPlayerStruct,
        team,
    } = props;


    let playerInstance = player;

    if(playerInstance===null){
        playerInstance = defaultPlayerStruct;
    }

    return (
        <Dialog
            open={open}
            onClose={onClose}
            scroll={'paper'}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
        >
            <DialogTitle id="scroll-dialog-title" align={"right"}>
                <IconButton
                    size="large"
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    onClick={onClose}
                >
                    <CloseIcon/>
                </IconButton>
                <Typography>Améliorer {playerInstance.positionalName}</Typography>
            </DialogTitle>
            <DialogContent dividers={true}>
                <DialogContentText
                    id="scroll-dialog-description"
                    tabIndex={-1}
                >
                    <PlayerLevelUpForm
                        player={playerInstance}
                        team={team}
                        onBuyAmelioration={onBuyAmelioration}
                        />
                </DialogContentText>
            </DialogContent>
        </Dialog>
    )
}

export default PlayerLevelUpDialog;
