import React, {useState} from 'react'
import {
  Button,
  Container,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Paper,
  Stack, TableContainer,
  TextField, Typography
} from "@mui/material";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import {useSnackbar} from "notistack";
import useSession from "../../contexts/session/useSession";
import {Link} from "react-router-dom";
import {styled} from "@mui/material/styles";

function FormLogin(props) {
  const {login} = useSession();
  const {enqueueSnackbar} = useSnackbar();


  const [values, setValues] = useState({
    username: null,
    password: null,
    showPassword: false
  })

  const handlePasswordChange = (event) => {
    let password = event.target.value;
    setValues({...values, password});
  };

  const handleUsernameChange = (event) => {
    let username = event.target.value;
    setValues({...values, username});
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleSubmit = (event) => {
    event.preventDefault()
    login(values.username, values.password, (error => {
      enqueueSnackbar("Erreur d'authentification ! erreur " + error.status + ", " + error.error, {variant: "error"});
    }));
  }

  return (
    <Container maxWidth={"sm"}>
      <Paper>
        <Stack p={3}>
          <FormControlStyled variant="filled">
            <TextField id="username"
                       label="Utilisateur"
                       variant="outlined"
                       name={"username"}
                       placeholder={"username"}
                       onChange={handleUsernameChange}
                       value={values.username}
            />
          </FormControlStyled>
          <FormControlStyled variant="filled">
            <InputLabel htmlFor="filled-adornment-password">Password</InputLabel>
            <OutlinedInput
              id="filled-adornment-password"
              name={"password"}
              placeholder={"password"}
              type={values.showPassword ? 'text' : 'password'}
              value={values.password}
              onChange={handlePasswordChange}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {values.showPassword ? <VisibilityOff/> : <Visibility/>}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControlStyled>
          <ButtonStyled  variant="contained" type={'submit'} onClick={handleSubmit}>Connexion</ButtonStyled>
          <TypographyNewAccount component={"div"} align={"center"} sx={{pt : 3}}>
            pas encore de compte ? <TypographyNewAccountLink component={'span'}><Link to={"/register"}>Enregistrez-vous maintenant</Link></TypographyNewAccountLink>
          </TypographyNewAccount>
        </Stack>
      </Paper>
    </Container>
  )
}

const ButtonStyled = styled(Button)(({theme}) => ({
  display : 'block',
  background: (theme.palette.secondary.gradient ? theme.palette.secondary.gradient : theme.palette.secondary.main),
  [theme.breakpoints.down("sm")]: {}
}));

const FormControlStyled = styled(FormControl)(({theme}) => ({
  paddingBottom : 20,
  [theme.breakpoints.down("sm")]: {}
}));

const TypographyNewAccount = styled(Typography)(({theme}) => ({
  fontSize : "0.8rem",
  [theme.breakpoints.down("sm")]: {}
}));
const TypographyNewAccountLink = styled(TypographyNewAccount)(({theme}) => ({
  color : theme.palette.secondary.main,
  [theme.breakpoints.down("sm")]: {}
}));


export default FormLogin


