import React, {Fragment, useEffect, useState} from "react";
import {Button, Container, Hidden, Paper, Stack, Typography} from "@mui/material";
import UsersList from "../components/UsersList/UsersList";
import TeamsService from "../../services/TeamsService";
import useSession from "../contexts/session/useSession";
import useLoading from "../contexts/loading/useLoading";
import {useSnackbar} from "notistack";

export default function ManageApprobationsPage(props) {
    const {enqueueSnackbar} = useSnackbar();
    const {currentSession} = useSession();
    const [approbations, setApprobations] = useState(null);

    const handleValidated = (approbation) =>{
        enqueueSnackbar("Approbation[" + approbation.code + "] traitée !", {variant: "success"});
        setApprobations(null);
    }

    const handleError = (approbation, error) =>{
        enqueueSnackbar("Erreur lors du traitement de l'approbation[" + approbation.code + "] !" + error.message, {variant: "error"});
    }

    useEffect(() => {
        if (approbations === null) {
            TeamsService.getWaitingImprovementsToApprove(currentSession.accessToken).then((approbs) => {
                setApprobations(approbs)
            })
        }
    }, [approbations])




    if (approbations === null) {
        return (
            <Fragment>
            </Fragment>
        )
    }

    return (
        <Container maxWidth={"lg"}>
            {approbations.map((a, i) => {
                return (
                    <WaitingImprovement
                        key={i}
                        data={a}
                        onValidated={handleValidated}
                        onError={handleError}
                    />
                );
            })}
        </Container>
    )
}

const WaitingImprovement = (props) => {
    const {currentSession} = useSession();
    const {hideLoading, showLoading} = useLoading();
    const {data,
        onValidated=()=>{},
        onError=()=>{}
    } = props;

    const handleRefuse = ()=>{
        showLoading("validation du refus de la demande");
        TeamsService.validateWaitingImprovement(data.code, false, currentSession.accessToken).then(()=>{
            onValidated(data);
            hideLoading();
        }).catch((error)=>{
            onError(data, error)
            hideLoading();
        })
    }

    const handleAccept = ()=>{
        showLoading("validation de l'acceptation de la demande");
        TeamsService.validateWaitingImprovement(data.code, true, currentSession.accessToken).then(()=>{
            onValidated(data);
            hideLoading();
        }).catch((error)=>{
            onError(data, error)
            hideLoading();
        })
    }

    return (
        <Paper>
            <Stack
                direction={"row"}
                spacing={2}
            >
                <Stack
                >
                    <Typography>{data.team.name} - #{data.player.number} #{data.player.positionalName}</Typography>
                    <Typography>{data.code}</Typography>
                </Stack>
                <Stack
                >
                    <Typography>{data.improvementType === "SKILL" ? "Competence" : "Caracteristique"}</Typography>
                    <Typography>{data.improvementType === "SKILL" ? data.skill.name : data.characteristic.name}</Typography>
                </Stack>
                <Button
                    color={"secondary"}
                    variant={"contained"}
                    onClick={handleAccept}
                >
                    Approuver
                </Button>
                <Button
                    color={"primary"}
                    variant={"outlined"}
                    onClick={handleRefuse}
                >
                    Refuser
                </Button>
            </Stack>
        </Paper>
    )
}
