import React, {useState} from "react";
import {Badge, ClickAwayListener, Stack, styled, Tooltip, Typography} from "@mui/material";

function PlayerStatus(props) {
    const {BP, RPM, DEAD, BPNumber} = props;

    return (
        <PlayerStatusRoot
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={0}
        >
            {BP &&
                <Badge
                    badgeContent={BPNumber}
                    color="primary"
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                    }}
                    invisible={BPNumber<2}
                >
                    <PlayerStatusItem color={"blueviolet"} text={"J"} tooltipText={
                        <span>
                          <div><b>Blessure persistante</b></div>
                          <div>Appliquez un modificateur de +1 à tous les futurs jets effectués contre ce joueur sur le plateau d'élimination pour chaque blessure persistante qu'il a reçu</div>
                        </span>
                    }/>
                </Badge>
            }
            {RPM && <PlayerStatusItem color={"orange"} text={"G"} tooltipText={"Rate le prochain match"}/>}
            {DEAD && <PlayerStatusItem color={"red"} text={"H"} tooltipText={"Mort"}/>}
        </PlayerStatusRoot>
    )
}


const PlayerStatusItem = (props) => {
    const {text, tooltipText, color} = props;

    const [openTT, setOpenTT] = useState(false);


    const handleChipClick = () => {
        setOpenTT(true);
    }

    const handleTooltipClose = () => {
        setOpenTT(false);
    }


    return (
        <ClickAwayListener onClickAway={handleTooltipClose}>
            <Tooltip
                PopperProps={{
                    disablePortal: true,
                }}
                onClose={handleTooltipClose}
                open={openTT}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                title={<TooltipContent>{tooltipText}</TooltipContent>}
                arrow
            >
                <PlayerStatusItemContent variant={'span'} className={"bbsa-player-status-item-content"}>
                    <PlayerStatusItemShip style={{color: color}} className={"chip"}
                                          onClick={handleChipClick}>{text}</PlayerStatusItemShip>
                    <PlayerStatusItemBackground
                        className={"bbsa-player-status-item-background"}>P</PlayerStatusItemBackground>
                </PlayerStatusItemContent>
            </Tooltip>
        </ClickAwayListener>
    )

}

const TooltipContent = styled(Typography)(({theme}) => ({
    fontSize: '1rem',
    [theme.breakpoints.down("sm")]: {
        fontSize: "0.6875rem",
    }
}))


const PlayerStatusRoot = styled(Stack)(({theme}) => ({
    [theme.breakpoints.down("sm")]: {}
}))

const PlayerStatusItemContent = styled(Typography)(({theme}) => ({
    fontSize: '3em',
    fontFamily: "BBDice",
    lineHeight: '0.8',
    cursor: 'pointer',
    position: 'relative',
    paddingRight: "3px",
    [theme.breakpoints.down("sm")]: {}
}))

const PlayerStatusItemShip = styled('span')(({theme}) => ({
    position: 'relative',
    zIndex: 1,
    [theme.breakpoints.down("sm")]: {}
}))

const PlayerStatusItemBackground = styled('div')(({theme}) => ({
    top: '1px',
    left: '1px',
    fontSize: '0.9em',
    position: 'absolute',
    color: 'white',
    [theme.breakpoints.down("sm")]: {}
}))

export default PlayerStatus;
