import {styled, Typography} from "@mui/material";

export const GameEventsTimeLineItemContent = styled(Typography)(({align, theme}) => ({
  margin: 0,
  position: "absolute",
  top: "50%",
  "-ms-transform": "translateY(-50%)",
  transform: "translateY(-50%)",
  right: align === 'right' ? '16px' : '',
  left: align === 'left' ? '16px' : '',
  [theme.breakpoints.down("sm")]: {}
}))

