import {Fragment, useEffect, useState} from "react";
import {NewsService} from "../../services";
import {Box, Card, CardMedia, Typography} from "@mui/material";
import {styled} from "@mui/material/styles";

export default function NewsCard(props){
  const {newsId} = props;
  const [news, setNews] = useState(null);

  useEffect(()=>{
    if(news===null){
      NewsService.get(newsId).then((news)=>{
        setNews(news);
      })
    }
  },[news])
  return (
    <NewsRoot>
      {news &&
        <Fragment>
          <CardMedia
            sx={{ height: 350 }}
            image={news.imageUrl}
            title={news.title}
          />
          <NewsContent py={3} px={2} >
            <NewsTitle>{news.title}</NewsTitle>
            <NewsSubtitle>{news.subtitle}</NewsSubtitle>
            <NewsDate>{news.date}</NewsDate>
          </NewsContent>
        </Fragment>
      }
    </NewsRoot>
  )
}

const NewsRoot = styled(Card)(({theme}) => ({
  boxShadow: 'none',
  position: 'relative',
  minWidth: 200,
  minHeight: 360,
  '&:after': {
    content: '""',
    display: 'block',
    position: 'absolute',
    width: '100%',
    height: '100%',
    bottom: 0,
    zIndex: 1,
    background: 'linear-gradient(to top, ' + theme.palette.secondary.dark + ' 0%, ' + theme.palette.secondary.light + '40 50%,' + theme.palette.secondary.light + '40 100%)',
  },
  [theme.breakpoints.down("sm")]: {}
}));

const NewsTypography = styled(Typography)(({theme}) => ({
  color : theme.palette.secondary.contrastText,
  [theme.breakpoints.down("sm")]: {}
}));

const NewsTitle = styled(NewsTypography)(({theme}) => ({
  fontFamily: (theme.components.bbsa.typography.secondaryFontFamily ? theme.components.bbsa.typography.secondaryFontFamily : theme.typography.font),
  fontSize: "1.5rem",
  [theme.breakpoints.down("sm")]: {}
}));

const NewsSubtitle = styled(NewsTypography)(({theme}) => ({
  fontSize: "0.8rem",
  [theme.breakpoints.down("sm")]: {}
}));

const NewsDate = styled(NewsTypography)(({theme}) => ({
  fontSize: "0.8rem",
  [theme.breakpoints.down("sm")]: {}
}));

const NewsContent = styled(Box)(({theme}) => ({
  position: 'absolute',
  zIndex: 2,
  bottom: 0,
  width: '100%',
  [theme.breakpoints.down("sm")]: {}
}));


