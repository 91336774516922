import React, {useEffect, useState} from "react";
import {Box, Button, Grid, Stack,} from "@mui/material";
import useSession from "../../contexts/session/useSession";
import GamesService from "../../../services/GamesService";


function GameForfeit(props) {
  const {currentSession} = useSession();

  const {
    gameId, onGameForfeit = (r) => {
    }
  } = props;
  const [gameLoaded, setGameLoaded] = useState(false);
  const [game, setGame] = useState(null);

  useEffect(() => {
    if (!gameLoaded) {
      GamesService.get(gameId).then((game) => {
        setGame(game);
        setGameLoaded(true)
      })
    }
  }, [gameLoaded, gameId])


  if (!gameLoaded) {
    return (
      <Box>
        <div>Chargement</div>
      </Box>
    )
  }

  const handleForfeitGameClick = (tp) => {
    let data = {
      id: gameId,
      gameEndParticipations: [
        {
          id: game.teamParticipations.filter(tp => tp.position === 1)[0].id,
          score: 0,
          lpConditionsIds: []
        },
        {
          id: game.teamParticipations.filter(tp => tp.position === 2)[0].id,
          score: 0,
          lpConditionsIds: []
        }
      ],
      forfeitTeamId: tp.id
    }

    GamesService.setEndGame(gameId, data, currentSession.accessToken).then((response) => {
      onGameForfeit(response, tp.team.name);
    })
  }

  return (
    <Box>
      <Stack spacing={2}>
        <Box textAlign={"center"}>
          <h2>L'équipe qui déclare forfait</h2>
        </Box>
        <Box textAlign={"center"}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Button fullWidth variant={"contained"} onClick={() => {
                handleForfeitGameClick(game.teamParticipations.filter(tp => tp.position === 1)[0])
              }}>{game.teamParticipations.filter(tp => tp.position === 1)[0].team.name}</Button>
            </Grid>
            <Grid item xs={12}>
              <Button fullWidth variant={"contained"} onClick={() => {
                handleForfeitGameClick(game.teamParticipations.filter(tp => tp.position === 1)[0])
              }}>{game.teamParticipations.filter(tp => tp.position === 2)[0].team.name}</Button>
            </Grid>
          </Grid>
        </Box>
      </Stack>
    </Box>
  )
}

export default GameForfeit;
