import React, {Fragment, useEffect, useState} from "react";
import useGame from "../../contexts/game/useGame";
import TimelineItem from "@mui/lab/TimelineItem";
import {TimelineConnector, TimelineContent, TimelineDot, TimelineOppositeContent, TimelineSeparator} from "@mui/lab";
import SportsIcon from "@mui/icons-material/Sports";
import {GameEventsTimeLineItemContent} from "./GameEventsTimeLineStyles";


export default function GameEventTimeLineItemKickOff(props) {
  const {events, getTeamFromPlayer} = useGame();
  const [eventInfo, setEventInfo] = useState(null);

  useEffect(() => {
    if (eventInfo === null) {
      events.filter(e => e.eventCode === 'KickOff').map(e => {
        setEventInfo({
          event: e,
          team: getTeamFromPlayer(e.initiator)
        });
      })
    }
  }, [events])

  return (
    <Fragment>
      {eventInfo &&
        <TimelineItem>
          <TimelineOppositeContent
            className={"bbsa-game-events-timeline-item-root"}
            sx={{py: '12px', px: 2, position: "relative"}
            }>
            <GameEventsTimeLineItemContent align={"right"} component={"div"}
                                           className={"bbsa-game-events-timeline-item-content"}
            >
              {eventInfo.event.name}
            </GameEventsTimeLineItemContent>
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineConnector/>
            <TimelineDot>
              <SportsIcon/>
            </TimelineDot>
            <TimelineConnector/>
          </TimelineSeparator>
          <TimelineContent
            className={"bbsa-game-events-timeline-item-root"}
            sx={{py: '12px', px: 2, position: "relative"}}
          >
            <GameEventsTimeLineItemContent align={"left"} component={"div"}
                                           className={"bbsa-game-events-timeline-item-content"}>
              {eventInfo.team.name}
            </GameEventsTimeLineItemContent>
          </TimelineContent>
        </TimelineItem>
      }
    </Fragment>
  )
}
