import {Backdrop, Stack, styled} from "@mui/material";
import BBSARotatingBlason from "../BbsaRotatingBlason/BbsaRotatingBlason";


export default function BBSABackdrop(props) {
    const {show} = props;
    return (
        <Backdrop
            sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 20000}}
            open={show}
        >
            <Stack
                direction="column"
                justifyContent="center"
                alignItems="center"
                spacing={2}
            >
                {props.children}
                <BBSARotatingBlason/>
            </Stack>
        </Backdrop>

    )
}
