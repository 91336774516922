import React, {Fragment} from "react";

import {Outlet} from "react-router-dom";

import {Box} from "@mui/material";
import {styled} from "@mui/material/styles";

import NavBar from "./NavBar";

export default function Layout(props) {
    return (
        <Fragment>
            <NavBar/>
            <MainContentBackGround className={"bbsa-main-content-bg"}/>
            <MainContent className={"bbsa-main-content"}>
                <Outlet/>
            </MainContent>
       </Fragment>
    )
}

export const MainContentBackGround = styled(Box)(({theme}) => ({
    width: "100%",
    position: 'fixed',
    padding: 0,
    margin: 0,
    top: '60px',
    left: 0,
    height: '100%',
    zIndex: -1,
    backgroundImage: theme.components.bbsa.layout.content.background.image ? theme.components.bbsa.layout.content.background.image : "",
    backgroundPosition: "center",
    backgroundSize: "cover",
    background: theme.palette.background.default,
    [theme.breakpoints.down("sm")]: {}
}));

export const MainContent = styled(Box)(({theme}) => ({
    marginTop: "100px",
    marginBottom: '50px',
    [theme.breakpoints.down("sm")]: {
        marginTop: "100px",
        paddingBottom: '0px'
    }
}));
