import Slider from "react-slick";
import {Box, Container, Typography} from "@mui/material";
import React, {Fragment, useEffect, useRef, useState} from "react";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import DaysService from "../../../services/DaysService";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {styled} from "@mui/material/styles";
import useSeason from "../../contexts/season/useSeason";

var settings = {
  dots: false,
  infinite: false,
  centerMode: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  variableWidth: true,
  nextArrow: <NextArrow/>,
  prevArrow: <PrevArrow/>
};

function DaysSlider(props) {
  const slider = useRef(null);
  const {
    onDaySelected = (day) => {
    }
  } = props;
  const [days, setDays] = useState(null);
  const [activeDay, setActiveDay] = useState(0);
  const {season} = useSeason();

  useEffect(() => {
    if (days === null) {
      DaysService.getAllDaysOfSeason(season.id).then((days) => {
        setDays(days);
        let df = days.filter(dd => dd.progressStatus === 'NOT_STARTED');
        if (df.length > 0) {
          handleClickItem(df[0]);
        }
      })
    }
  }, [days])

  const handleClickItem = async (day) => {
    onDaySelected(day);
    setActiveDay(day);
  }

  useEffect(() => {
    if (activeDay) {
      slider.current.slickGoTo(activeDay.position - 1)
    }
  }, [activeDay])

  return (
    <Fragment>
      {days !== null &&
        <DaysSliderRoot maxWidth={'false'} className={"bbsa-days-slider-root"}>
          <Slider {...settings} ref={slider}>
            {days.map((day) =>
              <Box key={"DaysSlider_day_" + day.id} onClick={() => handleClickItem(day)}>
                <DaysSliderItem active={activeDay.id === day.id ? true : false}
                                className={"bbsa-days-slider-item"}>{day.name}</DaysSliderItem>
              </Box>
            )}
          </Slider>
        </DaysSliderRoot>
      }
    </Fragment>
  )
}

function NextArrow(props) {
  const {className, style, onClick} = props;
  return (
    <LeftArrow className={className + " bbsa-days-slider-button"} style={{...style, display: "block"}}
               onClick={onClick}/>
  );
}

function PrevArrow(props) {
  const {className, style, onClick} = props;
  return (
    <RightArrow className={className + " bbsa-days-slider-button"} style={{...style, display: "block"}}
                onClick={onClick}/>
  );
}

const LeftArrow = styled(ArrowForwardIosIcon)(({theme}) => ({
    color: theme.palette.text.primary,
    display: "block",
    "&:hover": {
      color: theme.palette.action.hover
    },
    [theme.breakpoints.down("sm")]: {}
  }
));

const RightArrow = styled(ArrowBackIosIcon)(({theme}) => ({
    color: theme.palette.text.primary,
    display: "block",
    "&:hover": {
      color: theme.palette.action.hover
    },
  }
));
const DaysSliderRoot = styled(Container)(({theme}) => ({
    color: theme.palette.text.primary,
    [theme.breakpoints.down("sm")]: {}
  }
));

const DaysSliderItem = styled(Typography)(({theme, active}) => ({
  fontFamily: theme.typography.fontFamily,
  borderRadius: theme.shape.borderRadius,
  paddingLeft: "10px",
  paddingRight: "10px",
  cursor: "pointer",
  color: active ? theme.palette.primary.contrastText : "",
  background: active ? (theme.palette.primary.gradient ? theme.palette.primary.gradient : theme.palette.primary.main) : "",
  [theme.breakpoints.down("sm")]: {}
}));

export default DaysSlider;
