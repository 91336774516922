import React, {useState} from "react";
import {Chip, ClickAwayListener, styled, Tooltip, Typography} from "@mui/material";

function SkillChip(props) {
  const {skill, color = "default", sx} = props;
  const [openTT, setOpenTT] = useState(false);

  const handleChipClick = () => {
    setOpenTT(true);
  }

  const handleTooltipClose = () => {
    setOpenTT(false);
  }

  const getLabel = (skill) => {
    if (skill.value === 0 || skill.value === undefined || skill.value === null) {
      return skill.skillName;
    } else {
      return skill.skillName + "(" + skill.value + ")";
    }
  }


  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <Tooltip
        PopperProps={{
          disablePortal: true,
        }}
        onClose={handleTooltipClose}
        open={openTT}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        title={<TooltipContent>{skill.description}</TooltipContent>}
        arrow
      >
        <ChipRoot
          size={'small'}
          onClick={handleChipClick}
          color={color}
          sx={{backgroundColor: skill.skillTypeCode === 'Z' ? "#dad200" : ""}}
          className={"bbsa-playerskills-ship"}
          label={getLabel(skill)}/>
      </Tooltip>
    </ClickAwayListener>
  )
}

const TooltipContent = styled(Typography)(({theme}) => ({
  fontSize: '1rem',
  [theme.breakpoints.down("sm")]: {
    fontSize: "0.6875rem",
  }
}))

const ChipRoot = styled(Chip)(({theme}) => ({
  marginRight: '10px',
  marginTop: '10px',
  [theme.breakpoints.down("sm")]: {}
}))


export default SkillChip;
