import {styled} from "@mui/material/styles";
import {Box, Button} from "@mui/material";

export const EventModalContentStyles = styled(Box)(({theme}) => ({
  maxHeight: '400px',
  overflow: 'scroll',
  [theme.breakpoints.down("sm")]: {},

}));

export const EventButton = styled(Button)(({theme}) => ({
  marginTop: "5px",
  [theme.breakpoints.down("sm")]: {}
}));

