import React, {Fragment, useEffect, useState} from "react";
import {Navigate, useParams} from "react-router-dom";
import {GameContext} from "../contexts/game/GameContext";
import {Box, Button, Container, Grid, IconButton, Modal, styled, Typography} from "@mui/material";
import GameEventsTimeline from "../components/GameEventsTimeline/GameEventsTimeline";
import {GameBoardContext} from "../contexts/gameboard/GameBoardContext";
import useGameboard from "../contexts/gameboard/useGameboard";
import GameCurrentTurn from "../components/GameCurrentTurn/GameCurrentTurn";
import GameScore from "../components/GameScore/GameScore";
import GameChrono from "../components/GameChrono/GameChrono";
import EventAddButton from "../components/EventSelection/EventAddButton";
import {useSnackbar} from "notistack";
import CloseIcon from "@mui/icons-material/Close";
import {GameStreamContext} from "../contexts/gamestream/GameStreamContext";
import EventTypeButton from "../components/EventSelection/EventTypeButton";
import ReactNoSleep from "react-no-sleep";
import {useExitPage} from "../contexts/routes/useExitPage";
import useGameStream from "../contexts/gamestream/useGameStream";
import GameHalftimeDialog from "../components/GameHalftimeDialog/GameHalftimeDialog";

const BoardGameStyled = styled("div")(({theme}) => ({
  [".bbsa-game-score p"]: {
    fontSize: "3rem!important",
    fontFamily: theme.typography.fontFamily
  },
  [theme.breakpoints.down("sm")]: {
    [".bbsa-game-score p"]: {
      fontSize: "3rem!important"
    },
  }
}))

const defaultTime = 4*60

export default function BoardGamePage(props) {
  const {id} = useParams();

  return (
    <ReactNoSleep>
      {({isOn, enable, disable}) => (
        <GameContext id={id}>
          <GameBoardContext>
            <GameStreamContext>
              <BoardGame {...props} noSleep={{isOn, enable, disable}}/>
            </GameStreamContext>
          </GameBoardContext>
        </GameContext>
      )}
    </ReactNoSleep>
  )
}


function BoardGame(props) {
  const {game, sendEvent, turn, eventCategories, kickOffTeam, eventTypes, cancelLastEvent, activeTeam} = useGameboard();
  const {connectionLost} = useGameStream();

  const {enqueueSnackbar} = useSnackbar();
  const [openExistConfirm, setOpenExistConfirm] = useState(false);
  const [eventTypeKickOff, setEventTypeKickOff] = useState(null);
  const [exit, setExit] = useState(false);
  const [freezeChrono, setFreezeChrono] = useState(false);
  const [chronoRun, setChronoRun] = useState(false);
  const {noSleep} = props;
  const [addingEvent, setAddingEvent] = useState(false);
  const [currentHalftime, setCurrentHalftime] = useState(0);
  const [showHalftimeDialog, setShowHalftimeDialog] = useState(false);
  const [currentTime, setCurrentTime] = useState(defaultTime)

  useEffect(() => {
    if (eventTypes !== null && eventTypeKickOff === null) {
      let e = eventTypes.filter(e => e.code === 'KickOff')[0]
      setEventTypeKickOff(e);
    }
  }, [eventTypes])

  useEffect(()=> {
    if ((currentHalftime !== turn.halftime) && turn.halftime > 1){
      if (currentHalftime < turn.halftime) {
        setShowHalftimeDialog(true);
      }
      setCurrentHalftime(turn.halftime);
    }
  },[turn])

  useEffect(()=>{
    setCurrentTime(defaultTime);
  }, [activeTeam])

  const handleCloseGameHalftimeDialog = () => {
    setShowHalftimeDialog(false);
  }

  useEffect(() => {
    noSleep.enable();
  }, [])

  useExitPage(() => {
    noSleep.disable();
  })

  const handleChronoOnNextTurn = () => {
    setAddingEvent(true);
  }

  const handleChronoOnNextTurnSuccess = () => {
    setCurrentTime(defaultTime)
    setAddingEvent(false);
  }

  const handleChronoOnNextTurnError = () => {
    setAddingEvent(false);
  }

  const handleEventSelected = (selection) => {
    setAddingEvent(true);
    let data = {
      gameId: game.id,
      eventTypeId: selection.event.id,
      turn: turn.global,
      perpetratorPlayerId: selection.player.id,
      victimPlayerId: (selection.victim ? selection.victim.id : null),
      injuryTypeIds: (selection.injuries ? selection.injuries.map(i => {
        return i.id;
      }) : null)
    }


    sendEvent(
      data,
      () => {
        enqueueSnackbar("Evènement ajouté!", {variant: "success"});

        setAddingEvent(false)
      },
      (error) => {
        enqueueSnackbar("Erreur lors de l'ajout de l'évenement : " + error.message, {variant: "error"});
        setAddingEvent(false)
      }
    )
  }

  const handleCancelEvent = () => {
    cancelLastEvent(
      (e, t) => {
        enqueueSnackbar("L'évènement " + e.name + " de l'equipe " + t.name + " annulé avec succès!", {variant: "success"});
      },
      (error) => {
        enqueueSnackbar("Erreur lors de l'annulation de l'évenement !" + error.message, {variant: "error"});
      }
    );
  }

  const handleExit = () => {
    setOpenExistConfirm(true)
  }

  if (exit) {
    noSleep.disable();
    return (<Navigate to={"/"}/>)
  }


  const handleOnConfirmExit = () => {
    setOpenExistConfirm(false)
    setExit(true);
  };

  const handleEventSelectionOpen = () => {
    if (chronoRun) {
      setFreezeChrono(true)
    }
  };

  const handleEventSelectionClose = () => {
    if (chronoRun) {
      setFreezeChrono(false)
    }
  };

  const handleChronoStart = () => {
    setChronoRun(true);
  };

  const handleChronoPause = () => {
    setChronoRun(false);
  };

  const handleTick = (t) => {
    setCurrentTime(t);
  }

  const handleOnCloseModal = () => {
    setOpenExistConfirm(false);
  };

  const handleKickOff = (event, team, player, injuries, victim) => {
    let category = eventCategories.filter(ec => ec.id === event.eventCategoryId)[0];
    handleEventSelected({category, event, team, player, victim, injuries});
  }


  return (
    <BoardGameStyled>
      {game &&
        <Fragment>
          <Box
            sx={{
              position: "absolute",
              top: 0,
              right: 0,
              zIndex: 1,
              width: "100%"
            }}
          >
            <Container maxWidth={"lg"} sx={{pt: "5px"}}>
              <Typography align={"right"}>
                <Button color={"error"} variant={"contained"} onClick={handleExit}>Quitter</Button>
              </Typography>

            </Container>
          </Box>
          {kickOffTeam.id &&
            <Fragment>
              <Container maxWidth={"lg"}>
                <GameChrono time={currentTime} onStart={handleChronoStart} onPause={handleChronoPause}
                            freezeChrono={freezeChrono} disabled={connectionLost || addingEvent}
                            onNextTurn={handleChronoOnNextTurn} onTick={handleTick} onNextTurnSuccess={handleChronoOnNextTurnSuccess}
                            onNextTurnError={handleChronoOnNextTurnError}/>
                <EventAddButton game={game} onSelectionClose={handleEventSelectionClose}
                                onSelectionOpen={handleEventSelectionOpen} onEventSelected={handleEventSelected}
                                eventCategories={eventCategories} disabled={connectionLost || addingEvent}/>
              </Container>
              <Container maxWidth={"lg"}>
                <Button onClick={handleCancelEvent} disabled={connectionLost || addingEvent}>Annuler le dernier
                  évenement</Button>
              </Container>
              <GameScore game={game}/>
              <GameCurrentTurn/>
              <GameEventsTimeline revert/>
            </Fragment>
          }
          {!kickOffTeam.id && eventTypeKickOff && game &&
            <Box sx={{marginTop: "75px"}}>
              <EventTypeButton onClick={handleKickOff} disabled={connectionLost || addingEvent} game={game}
                               type={eventTypeKickOff} victim={null}
                               victimTeam={null}/>
            </Box>
          }
        </Fragment>
      }
      <ModalExistConfirm open={openExistConfirm} onConfirm={handleOnConfirmExit} onClose={handleOnCloseModal}/>
      <GameHalftimeDialog open={showHalftimeDialog} onClose={handleCloseGameHalftimeDialog}/>
    </BoardGameStyled>
  )
}

const styleModal = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '70%',
  maxWidth: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};


function ModalExistConfirm(props) {
  const {
    open, onClose = () => {
    }, onConfirm
  } = props;

  return (
    <Modal
      open={open}
      onClose={onClose}
    >
      <Box sx={styleModal} className={"bbsa-gameboard-exit"}>
        <IconButton
          size="large"
          edge="start"
          color="inherit"
          aria-label="menu"
          sx={{mr: 2, position: "absolute", top: 15, right: 0}}
          onClick={onClose}
        >
          <CloseIcon/>
        </IconButton>
        <Box className={"bbsa-gameboard-exit-content"}>
          <Grid container>
            <Grid item xs={12}>
              <Typography align={"center"}>Etes-vous sur de vouloir quitter</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography align={"center"}>
                <Button onClick={onConfirm} variant={"contained"} color={"success"}>OUI</Button>
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography align={"center"}>
                <Button onClick={onClose} variant={"contained"} color={"error"}>NON</Button>
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Modal>
  )
}
